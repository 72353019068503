import React from 'react';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import colors from 'src/integration/styles/colors';

interface ComponentPros extends Component {
  price: string;
  isDiscountAvailable?: boolean;
  discountPrice: string;
}

const Root = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
  `}
`;

const PriceElement = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily};
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.28px;
    padding: 0 7px;
  `}
`;

const StyledParagraph = styled(Paragraph)`
  ${() => css`
    display: inline;
    text-align: right;
  `}
`;

const OldPrice = styled(Paragraph)`
  ${() => css`
    grid-area: oldPrice;
    padding: 0;
    text-decoration: line-through;
  `}
`;

const DiscountPrice = styled(PriceElement)`
  ${() => css`
    padding: 0;
    grid-area: price;
    color: ${colors.error};
  `}
`;

const TotalPrice = ({ price, className, isDiscountAvailable, discountPrice }: ComponentPros) => {
  const { t } = useTranslation();

  if (isDiscountAvailable) {
    return (
      <Root className={className}>
        <OldPrice>{discountPrice}</OldPrice>
        <DiscountPrice>{price}</DiscountPrice>

        <StyledParagraph>{t('summary_form.price.taxes_included')}</StyledParagraph>
      </Root>
    );
  }

  return (
    <Root className={className}>
      <PriceElement>{price}</PriceElement>
      <StyledParagraph>{t('summary_form.price.taxes_included')}</StyledParagraph>
    </Root>
  );
};

export default TotalPrice;
