import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store, StoreContext } from 'src/integration/store/store';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import 'src/toolkit/i18n/i18n';
import { APP_CONTAINER_NAME } from 'src/domain/Settings/variables/variables';

const root = ReactDOM.createRoot(document.getElementById(APP_CONTAINER_NAME));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <link rel="canonical" href="https://www.tacobell.com/" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Nunito:wght@300;400;500;600&family=Pacifico&family=Titillium+Web:wght@300;400;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <StoreContext.Provider value={store}>
        <App />
      </StoreContext.Provider>
    </HelmetProvider>
  </React.StrictMode>,
);
