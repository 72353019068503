import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import ElementId from 'src/integration/config/ElementId';
import { useTranslation } from 'react-i18next';
import useNavigationTabs from 'src/toolkit/hooks/useNavigationTabs';

type ComponentProps = Component;

const Root = styled.div`
  ${() => css`
    display: flex;
    grid-gap: 32px;
  `}
`;

const Tab = styled.span<{ selected?: boolean }>`
  ${({ theme, selected }) => css`
    font-family: ${theme.fonts.fontFamily};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 12px 0;
    cursor: pointer;

    &:hover {
      color: ${theme.colors.primary};
      border-bottom: 3px solid ${theme.colors.primary};
    }

    ${selected &&
    css`
      border-bottom: 3px solid ${colors.neutral10};
    `}
  `}
`;

const NavigationTabs = ({ className }: ComponentProps) => {
  const { t } = useTranslation();
  const { activeTab, handleTabClick } = useNavigationTabs();

  return (
    <Root className={className}>
      <Tab selected={ElementId.overview === activeTab} onClick={() => handleTabClick(ElementId.overview)}>
        {t('navigation.overview')}
      </Tab>
      <Tab selected={ElementId.itinerary === activeTab} onClick={() => handleTabClick(ElementId.itinerary)}>
        {t('navigation.itinerary')}
      </Tab>
      <Tab selected={ElementId.includes === activeTab} onClick={() => handleTabClick(ElementId.includes)}>
        {t('navigation.includes')}
      </Tab>
    </Root>
  );
};

export default NavigationTabs;
