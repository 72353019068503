import { ReactComponent as calendar } from 'src/integration/assets/icons/ic_calendar.svg';
import { ReactComponent as chat } from 'src/integration/assets/icons/ic_chat.svg';
import { ReactComponent as grading } from 'src/integration/assets/icons/ic_grading.svg';
import { ReactComponent as marker } from 'src/integration/assets/icons/ic_marker.svg';
import { ReactComponent as star } from 'src/integration/assets/icons/ic_star.svg';
import { ReactComponent as statistic } from 'src/integration/assets/icons/ic_statistic.svg';
import { ReactComponent as users } from 'src/integration/assets/icons/ic_users.svg';
import { ReactComponent as user } from 'src/integration/assets/icons/ic_user.svg';
import { ReactComponent as briefcase } from 'src/integration/assets/icons/ic_briefcase.svg';
import { ReactComponent as arrowBack } from 'src/integration/assets/icons/ic_arrrow-back.svg';
import { ReactComponent as arrowDown } from 'src/integration/assets/icons/ic_arrow-down.svg';
import { ReactComponent as arrowRight } from 'src/integration/assets/icons/ic_arrow-right.svg';
import { ReactComponent as check } from 'src/integration/assets/icons/ic_check.svg';
import { ReactComponent as checkRounded } from 'src/integration/assets/icons/ic_check-rounded.svg';
import { ReactComponent as info } from 'src/integration/assets/icons/ic_info.svg';
import { ReactComponent as flight } from 'src/integration/assets/icons/ic_flight.svg';
import { ReactComponent as insurance } from 'src/integration/assets/icons/ic_insurance.svg';
import { ReactComponent as phone } from 'src/integration/assets/icons/ic_phone.svg';
import { ReactComponent as mail } from 'src/integration/assets/icons/ic_mail.svg';
import { ReactComponent as errorIcon } from 'src/integration/assets/icons/ic_error_icon.svg';
import { ReactComponent as successIcon } from 'src/integration/assets/icons/ic_success_icon.svg';
import { ReactComponent as errorLarge } from 'src/integration/assets/images/im_error-large.svg';
import { ReactComponent as errorSmall } from 'src/integration/assets/images/im_error-small.svg';
import { ReactComponent as confirmationDesktop } from 'src/integration/assets/images/im_confirmation-desktop.svg';
import { ReactComponent as confirmationMobile } from 'src/integration/assets/images/im_confirmation-mobile.svg';
import { ReactComponent as plus } from 'src/integration/assets/icons/ic_plus.svg';
import { ReactComponent as minus } from 'src/integration/assets/icons/ic_minus.svg';
import { ReactComponent as infoNegative } from 'src/integration/assets/icons/ic_info_negative.svg';
import { ReactComponent as successNegative } from 'src/integration/assets/icons/ic_success_negative.svg';
import { ReactComponent as errorNegative } from 'src/integration/assets/icons/ic_error_negative.svg';
import { ReactComponent as loader } from 'src/integration/assets/icons/ic_loader.svg';
import { ReactComponent as visa } from 'src/integration/assets/icons/ic_payment-method-visa.svg';
import { ReactComponent as mastercard } from 'src/integration/assets/icons/ic_payment-method-mastercard.svg';
import { ReactComponent as clock } from 'src/integration/assets/icons/ic_clock.svg';

export const iconCalendar = calendar;

export const iconChat = chat;

export const iconGrading = grading;

export const iconMarker = marker;

export const iconStar = star;

export const iconStatistic = statistic;

export const iconUsers = users;

export const iconUser = user;

export const iconBriefcase = briefcase;

export const iconArrowBack = arrowBack;

export const iconArrowDown = arrowDown;

export const iconArrowRight = arrowRight;

export const iconCheck = check;

export const iconCheckRounded = checkRounded;

export const iconInfo = info;

export const iconError = errorIcon;

export const iconSuccess = successIcon;

export const iconInfoNegative = infoNegative;

export const iconErrorNegative = errorNegative;

export const iconSuccessNegative = successNegative;

export const iconPlus = plus;

export const iconMinus = minus;

export const iconFlight = flight;

export const iconInsurance = insurance;

export const iconPhone = phone;

export const iconMail = mail;

export const iconLoader = loader;

export const iconVisa = visa;

export const iconMastercard = mastercard;

export const imageErrorLarge = errorLarge;

export const imageErrorSmall = errorSmall;

export const imageConfirmationDesktop = confirmationDesktop;

export const imageConfirmationMobile = confirmationMobile;

export const iconClock = clock;
