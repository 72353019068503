import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import useUrlBuilder from 'src/toolkit/utils/useUrlBuilder';
import useHttpService from 'src/toolkit/hooks/useHttpService';
import useThemeProvider from 'src/toolkit/hooks/useThemeProvider';

interface RequestOptions extends SimpleObject {
  retry?: number;
  enabled?: boolean;
}

interface ReactQueryProps {
  endpoint: string;
  queryKey: string;
  body?: SimpleObject;
  queryPathId?: SimpleObject;
  queryParams?: SimpleObject;
  opts: RequestOptions;

  method?: 'POST' | 'GET';
}

const useReactQuery = <O, E, I>({
  endpoint,
  queryKey,
  body,
  queryParams,
  queryPathId,
  method = 'GET',
  opts,
}: ReactQueryProps) => {
  const { resolveUrl } = useUrlBuilder();
  const { formatBodyData } = useHttpService();
  const { themeVariable } = useThemeProvider();

  const fetchConfig = {
    method,
    headers: {
      'X-AUTH-TOKEN': themeVariable.apiKey,
      'Content-Type': 'application/json',
    },
    body: formatBodyData(body),
    options: opts,
  };

  const queryOptions: UseQueryOptions<O, E, I> = {
    queryKey: [queryKey],
    queryFn: async () => {
      const response = await fetch(resolveUrl(endpoint, queryParams, queryPathId), fetchConfig);

      if (response.ok) {
        return await response.json();
      }

      if (response.status === 422) {
        throw (await response.json()) as E;
      }

      throw new Error('API request failed');
    },
    ...opts,
  };

  const queryResult = useQuery<O, E, I>(queryOptions);

  return {
    ...queryResult,
  };
};

export default useReactQuery;
