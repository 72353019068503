import React, { ReactNode, useMemo } from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import {
  iconError,
  iconErrorNegative,
  iconInfo,
  iconInfoNegative,
  iconSuccess,
  iconSuccessNegative,
} from 'src/integration/assets/AssetsProvider';
import Icon from 'src/presentation/components/ui/Icon/Icon';

type NoteType = 'info' | 'error' | 'success' | 'infoDark' | 'errorDark' | 'successDark';

interface ComponentProps extends Component {
  title?: string;
  children: ReactNode;
  isIconVisible?: boolean;
  type?: NoteType;
}

const Root = styled.div<{ $type: NoteType }>`
  ${({ $type }) => css`
    display: flex;
    padding: 16px;
    align-items: flex-start;
    grid-gap: 8px;
    align-self: stretch;
    border-radius: 10px;
    background: ${colors.infoContainer};

    ${$type === 'infoDark' &&
    css`
      background: ${colors.infoContainerNeg};
      color: ${colors.white};
    `};

    ${$type === 'success' &&
    css`
      background: ${colors.successContainer};
    `};

    ${$type === 'successDark' &&
    css`
      background: ${colors.success};
      color: ${colors.white};
    `};

    ${$type === 'error' &&
    css`
      background: ${colors.errorContainer};
    `};

    ${$type === 'errorDark' &&
    css`
      background: ${colors.error};
      color: ${colors.white};
    `};
  `}
`;

const StyledParagraph = styled(Paragraph)<{ $type?: NoteType }>`
  ${({ $type }) => css`
    line-height: 24px;

    ${($type === 'infoDark' || $type === 'successDark' || $type === 'errorDark') &&
    css`
      color: ${colors.white};
    `};
  `}
`;

const Content = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
  `}
`;

const Details = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  `}
`;

const Note = ({ id, title, children, isIconVisible, type = 'info', className }: ComponentProps) => {
  const renderIcon = useMemo(() => {
    if (type === 'error') return iconError;
    if (type === 'errorDark') return iconErrorNegative;
    if (type === 'success') return iconSuccess;
    if (type === 'successDark') return iconSuccessNegative;
    if (type === 'infoDark') return iconInfoNegative;

    return iconInfo;
  }, [type]);

  return (
    <Root id={id} $type={type} className={className}>
      {isIconVisible && <Icon icon={renderIcon} size={24} />}
      <Content>
        {title && (
          <StyledParagraph large $type={type} semiBold>
            {title}
          </StyledParagraph>
        )}
        <Details>{children}</Details>
      </Content>
    </Root>
  );
};

export default Note;
