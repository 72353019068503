import ThemeNames from 'src/domain/Settings/enum/ThemeNames';
import ThemeLinks from 'src/domain/Settings/model/ThemeLinks';
import { resolveLinkByEnv, resolveTagByEnv } from 'src/integration/themes/themeHelpers';

const defaultTheme: ThemeLinks = {
  privacyPolicy: 'whitelabel.explorer.link.privacy_policy',
  termsConditionsIntermediary: 'whitelabel.explorer.link.terms_and_conditions_intermediary',
  termsConditionsTo: 'whitelabel.explorer.link.terms_and_conditions_to',
  bookFlight: 'whitelabel.explorer.link.book_flight',
  insurance: 'whitelabel.explorer.link.insurance',
  email: 'whitelabel.explorer.link.email',
  phone: 'whitelabel.explorer.link.phone',
  domainUrl: resolveLinkByEnv('whitelabel.explorer.stage.inquiry_domain', 'whitelabel.explorer.prod.inquiry_domain'),
  loggerTagId: '',
};

const staTravelThemeLinks: ThemeLinks = {
  privacyPolicy: 'whitelabel.statravel.link.privacy_policy',
  termsConditionsIntermediary: 'whitelabel.statravel.link.terms_and_conditions_intermediary',
  termsConditionsTo: 'whitelabel.statravel.link.terms_and_conditions_to',
  bookFlight: 'whitelabel.statravel.link.book_flight',
  insurance: 'whitelabel.statravel.link.insurance',
  email: 'whitelabel.statravel.link.email',
  phone: 'whitelabel.statravel.link.phone',
  domainUrl: resolveLinkByEnv('whitelabel.statravel.stage.inquiry_domain', 'whitelabel.statravel.prod.inquiry_domain'),
  loggerTagId: resolveTagByEnv('whitelabel.statravel.stage.tag_id', 'whitelabel.statravel.prod.tag_id'),
};

const explorerThemeLinks: ThemeLinks = {
  privacyPolicy: 'whitelabel.explorer.link.privacy_policy',
  termsConditionsIntermediary: 'whitelabel.explorer.link.terms_and_conditions_intermediary',
  termsConditionsTo: 'whitelabel.explorer.link.terms_and_conditions_to',
  bookFlight: 'whitelabel.explorer.link.book_flight',
  insurance: 'whitelabel.explorer.link.insurance',
  email: 'whitelabel.explorer.link.email',
  phone: 'whitelabel.explorer.link.phone',
  domainUrl: resolveLinkByEnv('whitelabel.explorer.stage.inquiry_domain', 'whitelabel.explorer.prod.inquiry_domain'),
  loggerTagId: resolveTagByEnv('whitelabel.explorer.stage.tag_id', 'whitelabel.explorer.prod.tag_id'),
};

export const themeLinksMapping: Record<ThemeNames, ThemeLinks> = {
  [ThemeNames.themeExplorer]: explorerThemeLinks,
  [ThemeNames.themeStaTravel]: staTravelThemeLinks,
  [ThemeNames.defaultTheme]: defaultTheme,
};
