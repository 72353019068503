import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import { iconLoader } from 'src/integration/assets/AssetsProvider';
import breakpoints from 'src/integration/styles/breakpoints';

const Root = styled.div`
  ${() => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(48, 48, 56, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  `}
`;

const Content = styled.div`
  ${() => css`
    width: 100%;
    background: ${colors.white};
    border-radius: 10px;
    padding: 30px 10px;
    text-align: center;

    ${breakpoints.desktop`
      width: auto;
      padding: 62px;
    `}
  `}
`;

const StyledParagraph = styled(Paragraph)`
  ${() => css`
    text-align: center;

    ${breakpoints.desktop`
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.15px;
    `}
  `}
`;

const StyledIcon = styled(Icon)`
  ${() => css`
    padding-top: 24px;

    ${breakpoints.desktop`
       padding-top: 32px;
    `}
  `}
`;

const LoadingElement = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <Content>
        <StyledParagraph large>{t('payments.info.processing_your_payment')}</StyledParagraph>
        <StyledParagraph large>{t('payments.info.please_do_not_close_browser')}</StyledParagraph>
        <StyledIcon icon={iconLoader} size={80} />
      </Content>
    </Root>
  );
};

export default LoadingElement;
