enum Events {
  AvailabilityValidationError = 'bt_avail_err_select',
  AvailabilityNotFoundError = 'msg_no_results',
  SummaryAvailabilityButtonClick = 'bt_check',
  AvailabilityChooseButtonClick = 'bt_choose',
  FrontendFormValidateError = 'msg_form_details_fe',
  BackendFormValidateError = 'msg_form_details_be',
  ConfirmationPageBooking = 'booking',
}

export default Events;
