import useExtendableContent from 'src/toolkit/hooks/useExtendableContent';
import { useEffect } from 'react';
import useViewport from 'src/toolkit/utils/useViewport';

const useTravellerInformationPreview = () => {
  const { isOpen, setIsOpen, onClick } = useExtendableContent();
  const { isDesktop } = useViewport();

  const openContentOnPageLoad = () => {
    setIsOpen(isDesktop);
  };

  useEffect(() => {
    openContentOnPageLoad();
  }, []);

  return { isOpen, onClick };
};

export default useTravellerInformationPreview;
