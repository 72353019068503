import React from 'react';
import styled, { css } from 'styled-components';
import Skeleton from 'src/presentation/components/LoadingSkeletons/elements/Skeleton';

interface ComponentProps extends Component {
  width?: number;
  large?: boolean;
  marginBottom?: number;
  alignRight?: boolean;
}

const StyledSkeleton = styled(Skeleton)`
  ${() => css`
    border-radius: 26px;
  `}
`;

const SkeletonButton = ({ width, large, marginBottom, alignRight }: ComponentProps) => {
  return <StyledSkeleton width={width} height={large ? 52 : 46} marginBottom={marginBottom} alignRight={alignRight} />;
};

export default SkeletonButton;
