import React from 'react';
import styled, { css } from 'styled-components';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import { iconUsers } from 'src/integration/assets/AssetsProvider';
import colors from 'src/integration/styles/colors';
import { useTranslation } from 'react-i18next';
import breakpoints from 'src/integration/styles/breakpoints';

interface ComponentProps extends Component {
  count: number;
  errorColor?: boolean;
}

const StyledIcon = styled(Icon)`
  ${() => css`
    ${breakpoints.mobile`
       width: 14px;
       height: 14px;
    `}
  `}
`;

const Root = styled.div<{ $errorColor?: boolean }>`
  ${({ $errorColor }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 6px;
    color: ${$errorColor ? colors.error : colors.neutral10};

    ${breakpoints.desktop`
       flex-direction: row;
       grid-gap: 8px;
    `}
  `}
`;

const PlaceCount = ({ count, errorColor, className }: ComponentProps) => {
  const { t } = useTranslation();

  return (
    <Root className={className} $errorColor={errorColor}>
      <StyledIcon size={16} icon={iconUsers} />
      <Paragraph semiBold color={errorColor ? colors.error : colors.neutral30}>
        {count} {t('availability_form.place')}
      </Paragraph>
    </Root>
  );
};

export default PlaceCount;
