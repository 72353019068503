import Note from 'src/presentation/components/ui/Note/Note';
import React from 'react';
import styled from 'styled-components';
import useErrorMessagesMapper from 'src/toolkit/hooks/useErrorMessagesMapper';

interface ComponentProps extends Component {
  errorList?: SimpleObject[];
  title: string;
}

const List = styled.ul``;

const ListItem = styled.li``;

const ErrorListNote = ({ title, errorList, id }: ComponentProps) => {
  const {propertyPathMapper} = useErrorMessagesMapper();

  return (
    <Note id={id} title={title} isIconVisible type={'errorDark'}>
      <List>
        {errorList?.map((item: SimpleObject, key: number) => {
          return (
            <ListItem key={key}>
              {propertyPathMapper(item?.propertyPath)}: {item?.message}
            </ListItem>
          );
        })}
      </List>
    </Note>
  );
};

export default ErrorListNote;
