import React from 'react';
import NavigatorItem from 'src/presentation/components/WizzardNavigator/elements/NavigatorItem';
import styled, { css } from 'styled-components';
import ArrowBack from 'src/presentation/components/WizzardNavigator/elements/ArrowBack';
import StepMap from 'src/domain/Wizard/enum/stepMap';
import { useStore } from 'src/integration/store/store';
import { observer } from 'mobx-react-lite';
import useWizardHelper from 'src/toolkit/hooks/useWizardHelper';
import WizardSteps from 'src/domain/Wizard/model/WizardSteps';
import { useTranslation } from 'react-i18next';
import breakpoints from 'src/integration/styles/breakpoints';

interface ComponentProps {
  steps: WizardSteps[];
}

const Root = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    grid-gap: 24px;
  `}
`;

const ArrowContainer = styled.div<{ $isVisible?: boolean }>`
  ${({ $isVisible }) => css`
    cursor: pointer;

    ${!$isVisible &&
    css`
      display: none;
    `}

    ${breakpoints.mobile`
     display: none;
    `}
  `}
`;

const WizardNavigator = observer(({ steps }: ComponentProps) => {
  const { wizardStore } = useStore();
  const { getCurrentStepId, isPreviousStepAvailable } = useWizardHelper();
  const { t } = useTranslation();

  const handleClick = (id: StepMap) => {
    wizardStore.setStep(id);
  };

  const handlePreviousActionClick = () => {
    const currentStep = getCurrentStepId();

    wizardStore.setStep(currentStep?.previous || 0);
  };

  return (
    <Root>
      <ArrowContainer onClick={handlePreviousActionClick} $isVisible={isPreviousStepAvailable()}>
        <ArrowBack />
      </ArrowContainer>

      {steps.map((step, index) => (
        <NavigatorItem
          key={`${index}-step`}
          number={index + 1}
          text={t(step.title)}
          disabled={index > (getCurrentStepId()?.id ?? -1)}
          approved={index < (getCurrentStepId()?.id ?? -1)}
          onClick={() => handleClick(step.id)}
        />
      ))}
    </Root>
  );
});

export default WizardNavigator;
