import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import WhiteBox from 'src/presentation/components/ui/WhiteBox/WhiteBox';
import TravelDetailsForm from 'src/domain/Wizard/model/TravelDetailsForm';
import TravellerInfo from 'src/presentation/components/CheckoutForm/elements/TravellerInfo';
import useViewport from 'src/toolkit/utils/useViewport';
import ConditionalContentNote from 'src/presentation/components/CheckoutForm/elements/ConditionalContentNote';

interface ComponentProps {
  register: UseFormRegister<TravelDetailsForm>;
  errors: FieldErrors<TravelDetailsForm>;
  travellerCount: number;
}

const TravellersInformationBlock = ({ travellerCount, register, errors }: ComponentProps) => {
  const travellerCountArray = Array.from({ length: travellerCount }, (_, index) => index);
  const { isMobile } = useViewport();

  return (
    <>
      {travellerCountArray.map((item) => (
        <WhiteBox key={`traveller-info-${item}`} hPadding={isMobile ? 0 : 24} vPadding={isMobile ? 0 : 16}>
          <TravellerInfo
            dataTestId={`traveller-info`}
            index={item}
            register={register}
            errors={errors}
            note={item === 0 && <ConditionalContentNote />}
          />
        </WhiteBox>
      ))}
    </>
  );
};

export default TravellersInformationBlock;
