import useReactQuery from 'src/integration/api/useReactQuery';
import apiConfig from 'src/integration/config/apiConfig';
import { InitialCheckoutRequest, InitialCheckoutResponse } from 'src/domain/Schema/model/apiTypes';
import useQueryParams from 'src/toolkit/hooks/useQueryParams';
import useQueryKey from 'src/toolkit/utils/useQueryKey';
import { useStore } from 'src/integration/store/store';
import ErrorType from 'src/domain/Schema/model/ErrorType';
import useWizardSessionStorage from 'src/toolkit/hooks/useWizardSessionStorage';
import { useMemo } from 'react';
import useAppVariablesProvider from 'src/toolkit/hooks/useAppVariablesProvider';

const useFetchCheckoutInit = () => {
  const { departureId, travelers } = useQueryParams();
  const { packageId } = useAppVariablesProvider();
  const { paymentStore } = useStore();
  const { sectCartId } = paymentStore;
  const { cardIdFromStorage, setCardIdToStorage } = useWizardSessionStorage();
  const cardId = useMemo(() => true, [cardIdFromStorage]);

  const response = useReactQuery<InitialCheckoutRequest, ErrorType, InitialCheckoutResponse>({
    endpoint: apiConfig.api.endpoints.checkoutInit,
    queryKey: useQueryKey('checkoutInit', [departureId, packageId, travelers, cardIdFromStorage]),
    body: {
      cartId: cardIdFromStorage || null,
      productId: departureId,
      packageId: packageId,
      amountOfTravellers: parseInt(travelers),
    },
    method: 'POST',
    opts: {
      enabled: !!cardId,
      refetchOnWindowFocus: false,
      onSuccess: (data: InitialCheckoutResponse) => {
        sectCartId(data.cartId);
        setCardIdToStorage(data.cartId || null);
      },
    },
  });

  return {
    ...response,
  };
};

export default useFetchCheckoutInit;
