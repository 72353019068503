import useEnvData from 'src/toolkit/utils/useEnvData';
const { isTestingEnv, isProductionEnv, isStagingEnv } = useEnvData();

export const resolveLinkByEnv = (devLink: string, prodLink: string) => {
  if (isTestingEnv) {
    return devLink;
  }
  return prodLink;
};

export const resolveTagByEnv = (stageTagId: string, prodTagId: string) => {
  if (isStagingEnv) {
    return stageTagId;
  }
  if (isProductionEnv) {
    return prodTagId;
  }
  return '';
};
