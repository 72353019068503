import useReactQuery from 'src/integration/api/useReactQuery';
import apiConfig from 'src/integration/config/apiConfig';
import { PaymentInitRequest, PaymentInitResponse } from 'src/domain/Schema/model/apiTypes';
import useQueryKey from 'src/toolkit/utils/useQueryKey';
import { useStore } from 'src/integration/store/store';
import { useState } from 'react';
import ErrorType from 'src/domain/Schema/model/ErrorType';
import useQueryParams from 'src/toolkit/hooks/useQueryParams';
import useUrlBuilder from 'src/toolkit/utils/useUrlBuilder';
import PaymentStatus from 'src/domain/Settings/enum/PaymentStatus';
import routerPaths from 'src/integration/config/routerPaths';
import QueryParams from 'src/domain/Settings/model/QueryParams';
import StepName from 'src/domain/Wizard/enum/StepName';
import useAppVariablesProvider from 'src/toolkit/hooks/useAppVariablesProvider';
import useSuccessPaymentEventLogger from 'src/toolkit/hooks/useSuccessPaymentEventLogger';

const useFetchPaymentInit = () => {
  const [enabled, setEnabled] = useState(false);
  const { departureId, travelers } = useQueryParams();
  const { packageId } = useAppVariablesProvider();
  const { paymentStore } = useStore();
  const { cartId, selectedPaymentMethod, setTransactionId } = paymentStore;
  const { buildUrlParams, buildUrlWithCurrentDomain } = useUrlBuilder();
  const { removeSuccessPaymentEvent } = useSuccessPaymentEventLogger;

  const response = useReactQuery<PaymentInitRequest, ErrorType, PaymentInitResponse>({
    endpoint: apiConfig.api.endpoints.paymentInit,
    queryKey: useQueryKey('paymentInit', [cartId, selectedPaymentMethod, departureId, travelers, packageId]),
    body: {
      cartId: cartId,
      paymentOption: selectedPaymentMethod,
      successUrl: buildUrlWithCurrentDomain(`${routerPaths.confirmation(cartId || undefined)}`),
      cancelUrl: buildUrlWithCurrentDomain(
        `${routerPaths.checkout(departureId)}?${buildUrlParams({
          [QueryParams.travelers]: travelers,
          [QueryParams.packageId]: packageId,
          [QueryParams.payment]: PaymentStatus.failed,
          [QueryParams.step]: StepName.payment,
        })}`,
      ),

      errorUrl: buildUrlWithCurrentDomain(
        `${routerPaths.checkout(departureId)}?${buildUrlParams({
          [QueryParams.travelers]: travelers,
          [QueryParams.packageId]: packageId,
          [QueryParams.payment]: PaymentStatus.failed,
          [QueryParams.step]: StepName.payment,
        })}`,
      ),
    },
    method: 'POST',
    opts: {
      enabled: enabled,
      refetchOnWindowFocus: false,
      onSuccess: (data: PaymentInitResponse) => {
        setTransactionId(data.transactionId);
        setEnabled(false);
      },
      onError: () => {
        setEnabled(false);
        removeSuccessPaymentEvent();
      },
    },
  });

  const makePayment = () => {
    setEnabled(true);
  };

  return {
    ...response,
    makePayment,
  };
};

export default useFetchPaymentInit;
