import apiConfig from 'src/integration/config/apiConfig';

const useUrlBuilder = () => {
  const buildPathWithQueryParams = (path: string, queryParams?: SimpleObject, queryPath?: SimpleObject) => {
    let pathWithQuery = path;

    if (queryPath) {
      Object.keys(queryPath).forEach((key) => {
        pathWithQuery = pathWithQuery.replace(`:${key}`, queryPath[key]);
      });
    }

    if (queryParams) {
      const queryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join('&');

      pathWithQuery += `?${queryString}`;
    }

    return pathWithQuery;
  };

  const resolveUrl = (endpoint: string, queryParams?: SimpleObject, queryPath?: SimpleObject): string => {
    const baseUrl = apiConfig.api.baseUrl;

    if (!baseUrl) {
      throw new Error('Base URL is not defined.');
    }

    const normalizedBaseUrl = baseUrl.replace(/\/$/, '');
    const normalizedPath = endpoint.replace(/^\//, '');
    const pathWithQuery = buildPathWithQueryParams(normalizedPath, queryParams, queryPath);

    return `${normalizedBaseUrl}/${pathWithQuery}`;
  };

  const removeLeadingSlash = (str: string) => {
    if (str.charAt(0) === '/') {
      return str.substring(1);
    }

    return str;
  };

  const removeTrailingSlash = (str: string) => {
    if (str.charAt(str.length - 1) === '/') {
      return str.slice(0, -1);
    }

    return str;
  };

  const getCurrentUrl = () => {
    const currentOrigin = window.location.origin;
    const currentPathname = window.location.pathname;

    return `${removeTrailingSlash(currentOrigin)}/${removeTrailingSlash(removeLeadingSlash(currentPathname))}`;
  };

  const buildUrlWithCurrentDomain = (path: string) => {
    return `${getCurrentUrl()}#/${removeLeadingSlash(path)}`;
  };

  const buildUrlParams = (params: SimpleObject): string => {
    const paramString = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    return paramString;
  };

  return {
    buildUrlParams,
    buildUrlWithCurrentDomain,
    resolveUrl,
    getCurrentUrl,
  };
};

export default useUrlBuilder;
