import { useForm } from 'react-hook-form';
import AvailabilityForm from 'src/domain/Package/model/AvailabilityForm';
import { yupResolver } from '@hookform/resolvers/yup';
import useConstraints from 'src/toolkit/hooks/useConstraints';
import { useEffect } from 'react';
import { useStore } from 'src/integration/store/store';
import SelectFieldType from 'src/domain/Styles/model/SelectFieldType';
import useDateTimeFormatter from 'src/toolkit/utils/useDateTimeFormatter';
import useEventLogger from 'src/toolkit/hooks/useEventLogger';

const useAvailabilityForm = (onClick: () => void) => {
  const { availabilityFormSchema } = useConstraints();
  const { generateMonthList } = useDateTimeFormatter();
  const { packageStore } = useStore();
  const { availabilityForm } = packageStore;
  const { dateFrom, adults } = availabilityForm;
  const {
    reset,
    handleSubmit,
    formState: { errors, isSubmitted },
    setValue,
  } = useForm<Partial<AvailabilityForm>>({ resolver: yupResolver(availabilityFormSchema) });
  const { logAvailabilityValidationError } = useEventLogger();
  const monthList: SelectFieldType[] = generateMonthList();
  const MAX_TRAVELERS = 16;

  useEffect(() => {
    reset(availabilityForm);
  }, [availabilityForm, reset]);

  useEffect(() => {
    setValue('adults', adults, { shouldValidate: isSubmitted });
  }, [adults]);

  useEffect(() => {
    setValue('dateFrom', dateFrom, { shouldValidate: isSubmitted });
  }, [dateFrom]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      logAvailabilityValidationError();
    }
  }, [errors, isSubmitted]);

  const handleFormSubmission = handleSubmit(() => {
    onClick();
  });

  const calculateSharedMaximum = (value: number) => {
    return Math.max(MAX_TRAVELERS - value, 0);
  };

  return {
    errors,
    handleFormSubmission,
    monthList,
    calculateSharedMaximum,
  };
};

export default useAvailabilityForm;
