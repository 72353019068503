import { createSearchParams, useNavigate } from 'react-router-dom';
import routerPaths from 'src/integration/config/routerPaths';
import { useStore } from 'src/integration/store/store';
import QueryParams from 'src/domain/Settings/model/QueryParams';
import { DeparturesQueryParams } from 'src/domain/Schema/model/apiTypes';
import useDateTimeFormatter from 'src/toolkit/utils/useDateTimeFormatter';
import useAppVariablesProvider from 'src/toolkit/hooks/useAppVariablesProvider';
import useEventLogger from 'src/toolkit/hooks/useEventLogger';

const usePackageAvailability = () => {
  const navigate = useNavigate();
  const { convertDateToSelectField } = useDateTimeFormatter();
  const { packageId } = useAppVariablesProvider();
  const { packageStore } = useStore();
  const { amountOfTravellers } = packageStore;
  const { logAvailabilityChooseButtonClick } = useEventLogger();

  const mappedDeparturesQueryParams = (
    amountOfTravellers: number,
    dateFrom: Nullable<Date>,
    dateTo: Nullable<Date>,
  ): DeparturesQueryParams => {
    return {
      amountOfTravellers: amountOfTravellers,
      dateFrom: convertDateToSelectField(dateFrom).id,
      dateTo: convertDateToSelectField(dateTo).id,
    };
  };

  const onSelectPackage = (id?: string) => {
    navigate({
      pathname: routerPaths.checkout(id),
      search: createSearchParams({
        [QueryParams.travelers]: `${amountOfTravellers}`,
        [QueryParams.packageId]: packageId,
      }).toString(),
    });

    logAvailabilityChooseButtonClick();
  };

  const getColorByPlaceCount = (count: number) => {
    return count < 4;
  };

  return {
    mappedDeparturesQueryParams,
    onSelectPackage,
    getColorByPlaceCount,
  };
};

export default usePackageAvailability;
