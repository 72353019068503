import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';

const STATUS = {
  HOVERED: 'hovered',
  NORMAL: 'normal',
};

interface LinkProps {
  href?: string;
  children: React.ReactNode;
  blank?: boolean;
}

const Root = styled.a`
  ${() => css`
    color: ${colors.infoBase};
    text-decoration: underline;
    text-underline-offset: 3px;
    line-height: 24px;
  `}
`;

const Link: React.FC<LinkProps> = ({ href, children, blank }) => {
  const [status, setStatus] = useState<string>(STATUS.NORMAL);

  const onMouseEnter = () => {
    setStatus(STATUS.HOVERED);
  };

  const onMouseLeave = () => {
    setStatus(STATUS.NORMAL);
  };

  return (
    <Root
      className={status}
      href={href || '#'}
      target={blank ? '_blank' : '_self'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Root>
  );
};

export default Link;
