import React from 'react';
import Button from 'src/presentation/components/ui/Button/Button';
import { useStore } from 'src/integration/store/store';
import styled, { css } from 'styled-components';
import ContactDetailsPreview from 'src/presentation/components/CheckoutForm/ContactDetailsPreview';
import TravelInformationPreview from 'src/presentation/components/CheckoutForm/TravellerInformationPreview';
import breakpoints from 'src/integration/styles/breakpoints';
import PaymentBlock from 'src/presentation/components/CheckoutForm/PaymentBlock';
import usePaymentTransition from 'src/presentation/wizard/transitions/usePaymentTransition';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-datatrans-light-box';
import GeneralWizardErrorsSection from 'src/presentation/components/widgets/GeneralWizardErrorsSection';
import LoadingElement from 'src/presentation/components/ui/LoadingElement/LoadingElement';
import { observer } from 'mobx-react-lite';

const Root = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
  `}
`;

const StyledButton = styled(Button)`
  ${() => css`
    ${breakpoints.mobile`
      margin: 0 16px;  
    `}
  `}
`;

const PreviewAndPaymentStep = () => {
  const { handlePayment, isFetching, onCancelled, onError, showLightbox, isProdPaymentsEnabled, transactionId } = usePaymentTransition();
  const { wizardStore } = useStore();
  const { t } = useTranslation();
  const travelDetails = wizardStore.travelDetails;

  return (
    <Root>
      {isFetching && <LoadingElement />}
      <GeneralWizardErrorsSection />

      <ContactDetailsPreview travelDetails={travelDetails} />
      <TravelInformationPreview travellers={travelDetails.travellers} />
      <PaymentBlock />

      {showLightbox && transactionId && (
        <Lightbox
          transactionId={transactionId}
          onCancelled={onCancelled}
          onError={onError}
          production={isProdPaymentsEnabled()}
        />
      )}

      <StyledButton styleType={'primary'} onClick={handlePayment} disabled={isFetching} isLoading={isFetching} small>
        {t('form.button.pay')}
      </StyledButton>
    </Root>
  );
};

export default observer(PreviewAndPaymentStep);
