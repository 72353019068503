import React from 'react';
import SkeletonWhiteBox from 'src/presentation/components/LoadingSkeletons/elements/SkeletonWhiteBox';
import styled, { css } from 'styled-components';
import useViewport from 'src/toolkit/utils/useViewport';
import SkeletonButton from 'src/presentation/components/LoadingSkeletons/elements/SkeletonButton';
import SkeletonText from 'src/presentation/components/LoadingSkeletons/elements/SkeletonText';

const OneRowBlock = styled.div`
  ${() => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 24px;
  `}
`;

const SkeletonBasketPDP = () => {
  const { isMobile } = useViewport();

  return (
    <SkeletonWhiteBox vPadding={24} hPaddingDesktop={24} showBorder>
      <SkeletonText width={281} height={42} marginBottom={isMobile ? 38 : 46} />

      <SkeletonText marginBottom={isMobile ? 8 : 12} />
      <SkeletonText marginBottom={isMobile ? 8 : 12} />
      <SkeletonText height={24} marginBottom={isMobile ? 8 : 12} />
      <SkeletonText marginBottom={12} />
      <SkeletonText height={24} marginBottom={isMobile ? 8 : 12} />
      <SkeletonText height={24} marginBottom={isMobile ? 8 : 12} />
      <SkeletonText height={24} marginBottom={49} />

      <SkeletonText width={226} height={24} marginBottom={6} alignRight />
      <SkeletonText width={154} height={24} marginBottom={24} alignRight />

      <OneRowBlock>
        <SkeletonButton width={isMobile ? 135 : undefined} large />
        <SkeletonButton large />
      </OneRowBlock>
    </SkeletonWhiteBox>
  );
};

export default SkeletonBasketPDP;
