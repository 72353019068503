import Events from 'src/domain/EventLogger/enum/Events';

const useEventLogger = () => {
  const dataLayer = window.dataLayer;

  const logEvent = (event: string, options?: object) => {
    if (!dataLayer) return;

    dataLayer.push({ event: event, ...options });
  };

  const logAvailabilityValidationError = () => {
    logEvent(Events.AvailabilityValidationError);
  };

  const logAvailabilityNotFoundError = () => {
    logEvent(Events.AvailabilityNotFoundError);
  };

  const logSummaryAvailabilityButtonClick = () => {
    logEvent(Events.SummaryAvailabilityButtonClick);
  };

  const logBackendFormValidateError = () => {
    logEvent(Events.BackendFormValidateError);
  };

  const logFrontendFormValidateError = () => {
    logEvent(Events.FrontendFormValidateError);
  };

  const logConfirmationPageBooking = () => {
    logEvent(Events.ConfirmationPageBooking);
  };

  const logAvailabilityChooseButtonClick = () => {
    logEvent(Events.AvailabilityChooseButtonClick);
  };

  return {
    logEvent,
    logAvailabilityValidationError,
    logAvailabilityNotFoundError,
    logSummaryAvailabilityButtonClick,
    logBackendFormValidateError,
    logFrontendFormValidateError,
    logConfirmationPageBooking,
    logAvailabilityChooseButtonClick,
  };
};

export default useEventLogger;
