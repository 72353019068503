import { useMemo } from 'react';
import QueryParams from 'src/domain/Settings/model/QueryParams';
import { useParams } from 'react-router-dom';
import StepName from 'src/domain/Wizard/enum/StepName';

interface URLSearchParams {
  removeUrlParam: (paramName: string) => void;
  updateUrlParam: (paramName: string, paramValue: string) => void;
  travelers: string;
  step: string;
  packageId: Nullable<string>;
  departureId: string | undefined;
  cartId: string | undefined;
  payment: Nullable<string>;
}

const useQueryParams = (): URLSearchParams => {
  const { hash } = window.location;
  const query = hash.substring(hash.indexOf('?'));
  const { id, cartId } = useParams();

  const setSearchParams = (newParams: SimpleObject) => {
    const newQueryString = new URLSearchParams(newParams).toString();

    window.location.hash = hash.replace(query, `?${newQueryString}`);
  };

  const queryParams = useMemo(() => new URLSearchParams(query), [hash]);

  const removeUrlParam = (paramName: string) => {
    if (!queryParams.get(paramName)) return;

    queryParams.delete(paramName);
    setSearchParams(queryParams);
  };

  const updateUrlParam = (paramName: string, paramValue: string) => {
    queryParams.set(paramName, paramValue);
    setSearchParams(queryParams);
  };

  return {
    removeUrlParam,
    updateUrlParam,
    packageId: queryParams.get(QueryParams.packageId),
    travelers: queryParams.get(QueryParams.travelers) || '1',
    payment: queryParams.get(QueryParams.payment),
    departureId: id,
    cartId: cartId,
    step: queryParams.get(QueryParams.step) || StepName.travelerDetails,
  };
};

export default useQueryParams;
