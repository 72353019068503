import React from 'react';
import styled from 'styled-components';
import Button from 'src/presentation/components/ui/Button/Button';
import useLanguageHook from 'src/toolkit/utils/useLanguageHook';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import Languages from 'src/domain/Settings/enum/Languages';

const Root = styled.div`
  padding: 8px;
  display: flex;
  justify-content: flex-end;
  grid-gap: 8px;
`;

const LanguageSwitcher = () => {
  const { changeLanguageHandler } = useLanguageHook();

  return (
    <Root>
      <Paragraph>Test button: Change language:</Paragraph>
      <Button styleType={'link'} onClick={() => changeLanguageHandler(Languages.de)}>
        DE
      </Button>
      <Button styleType={'link'} onClick={() => changeLanguageHandler(Languages.en)}>
        EN
      </Button>
    </Root>
  );
};

export default LanguageSwitcher;
