import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import ErrorText from 'src/presentation/components/FormFields/ErrorText';
import Label from 'src/presentation/components/FormFields/Label';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';

interface ComponentProps<T extends FieldValues> {
  label: string;
  name: Path<T>;
  placeholder?: string;
  register: UseFormRegister<T>;
  required?: boolean;
  errors?: string;
}

const Root = styled.div`
  ${() => css`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin-top: 8px;
  `}
`;

const TextAreaField = styled.textarea<{ $error: boolean }>`
  ${({ theme, $error }) => css`
    box-sizing: border-box;
    border-radius: 10px;
    border: 2px solid ${$error ? colors.error : colors.neutral80};
    padding: 14px 22px 12px 26px;
    width: 100%;
    resize: none;

    font-family: ${theme.fonts.fontFamily};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `}
`;

const TextArea = <T extends FieldValues>({
  name,
  register,
  label,
  placeholder,
  errors,
  required,
}: ComponentProps<T>) => {
  return (
    <Root>
      <Label optional={!required}>{label}</Label>
      <TextAreaField id={name} $error={!!errors} placeholder={placeholder} {...register(name)} />
      {errors && <ErrorText error={errors} />}
    </Root>
  );
};

export default TextArea;
