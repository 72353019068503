import React, { ReactNode } from 'react';
import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import { iconArrowRight } from 'src/integration/assets/AssetsProvider';
import breakpoints from 'src/integration/styles/breakpoints';

interface ComponentProps extends Component {
  icon: FunctionComponent<SimpleObject>;
  large?: boolean;
  light?: boolean;
  link?: string;
  children: ReactNode;
}

const Root = styled.a`
  ${() => css`
    all: unset;
  `}
`;

const Wrapper = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border: 1px solid ${colors.neutral90};
    border-radius: 10px;
    background: ${colors.neutral100};
    padding: 16px 24px;
    cursor: pointer;

    ${breakpoints.desktop`
      padding: 24px;        
    `}
  `}
`;

const AlignLeft = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 16px;
  `}
`;

const TextBlock = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `}
`;

const StyledIcon = styled(Icon)<{ $light?: boolean }>`
  ${({ $light }) => css`
    display: flex;
    align-items: center;
    gap: 8px;

    ${$light &&
    css`
      color: ${colors.neutral30};
    `}
  `}
`;

const LinkBox = ({ icon, large, light, link = '#', children }: ComponentProps) => {
  return (
    <Root href={link} target="_blank">
      <Wrapper>
        <AlignLeft>
          {icon && <StyledIcon icon={icon} size={large ? 32 : 24} $light={light} />}
          <TextBlock>{children}</TextBlock>
        </AlignLeft>
        <StyledIcon icon={iconArrowRight} size={large ? 32 : 24} $light={light} />
      </Wrapper>
    </Root>
  );
};

export default LinkBox;
