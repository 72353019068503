import useReactQuery from 'src/integration/api/useReactQuery';
import apiConfig from 'src/integration/config/apiConfig';
import { Nationality } from 'src/domain/Schema/model/apiTypes';
import ErrorType from 'src/domain/Schema/model/ErrorType';

const useFetchNationalities = () => {
  return useReactQuery<null, ErrorType, Nationality[]>({
    endpoint: apiConfig.api.endpoints.nationalities,
    queryKey: 'nationalities',
    method: 'GET',
    opts: {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });
};

export default useFetchNationalities;
