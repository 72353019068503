import React, { FunctionComponent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import useExtendableContent from 'src/toolkit/hooks/useExtendableContent';
import IconArrowDown from 'src/presentation/components/ui/Icon/IconArrowDown';

interface ComponentProps extends Component {
  icon: FunctionComponent;
  text: ReactNode;
  children?: ReactNode;
}

const ICON_WIDTH = 24;

const Root = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `}
`;

const StyledIcon = styled(Icon)`
  ${() => css`
    margin-right: 8px;
  `}
`;

const Title = styled.div<{ $isExtendable?: boolean }>`
  ${({ $isExtendable }) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;

    ${$isExtendable &&
    css`
      cursor: pointer;
    `}
  `}
`;

const Text = styled.div`
  ${() => css`
    display: flex;
    max-width: calc(100% - ${ICON_WIDTH}px);
    width: 100%;
  `}
`;

const ExtendableContent = styled(Paragraph)`
  ${() => css`
    margin-top: 4px;
  `}
`;

const StyledParagraph = styled(Paragraph)<{$isExtendable?: boolean}>`
  ${({$isExtendable}) => css`
    ${$isExtendable &&
      css`
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-height: 21px;
          line-height: 20px;
          width: 100%;
    `}
  `}
`;

const Details = ({ icon, text, className, children }: ComponentProps) => {
  const { isOpen, onClick } = useExtendableContent();

  return (
    <Root className={className}>
      <Title onClick={onClick} $isExtendable={!!children}>
        <Text>
          <StyledIcon icon={icon} />
          <StyledParagraph $isExtendable={!!children} large>{text}</StyledParagraph>
        </Text>
        {children && <IconArrowDown isOpen={isOpen} size={ICON_WIDTH} />}
      </Title>
      {isOpen && children && (
        <ExtendableContent large italic>
          {children}
        </ExtendableContent>
      )}
    </Root>
  );
};

export default Details;
