import React from 'react';
import styled, { css } from 'styled-components';
import Skeleton from 'src/presentation/components/LoadingSkeletons/elements/Skeleton';

interface ComponentProps extends Component {
  width?: number;
  height?: number;
  marginBottom?: number;
  alignRight?: boolean;
}

const StyledSkeleton = styled(Skeleton)`
  ${() => css`
    border-radius: 10px;
  `}
`;

const SkeletonText = ({ width, height, marginBottom, alignRight }: ComponentProps) => {
  return <StyledSkeleton width={width} height={height} marginBottom={marginBottom} alignRight={alignRight} />;
};

export default SkeletonText;
