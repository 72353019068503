import { useMemo, useState } from 'react';
import { useStore } from 'src/integration/store/store';
import { PaymentOption } from 'src/domain/Schema/model/apiTypes';
import PaymentMethod from 'src/domain/Payments/enum/PaymentMethod';

const usePaymentsHook = () => {
  const { paymentStore } = useStore();
  const { transactionId, setSelectedPaymentMethod, selectedPaymentMethod, paymentOptions } = paymentStore;
  const [showLightbox, setShowLightbox] = useState(false);

  const onCancelled = () => setShowLightbox(false);

  const onError = () => setShowLightbox(false);

  const getPaymentOption = (paymentOptions: PaymentOption[], id: PaymentMethod) => {
    return paymentOptions.find((item) => item.id === id);
  };

  const handlePaymentMethod = (id: PaymentMethod) => {
    setSelectedPaymentMethod(id);
  };

  const fullPayment: PaymentOption | undefined = useMemo(() => getPaymentOption(paymentOptions, PaymentMethod.full), [paymentOptions]);
  const depositPayment: PaymentOption | undefined = useMemo(() => getPaymentOption(paymentOptions, PaymentMethod.deposit), [paymentOptions]);

  return {
    transactionId,
    showLightbox,
    setShowLightbox,
    onCancelled,
    onError,
    selectedPaymentMethod,
    depositPayment,
    fullPayment,
    handlePaymentMethod,
  };
};

export default usePaymentsHook;
