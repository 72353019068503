import React from 'react';
import styled, { css } from 'styled-components';
import breakpoints from 'src/integration/styles/breakpoints';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import colors from 'src/integration/styles/colors';

interface ComponentProps {
  price?: string;
  discount?: string;
}

const Root = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 6px;

    ${breakpoints.desktop`
       gap: 8px;
    `}
  `}
`;

const StyledParagraph = styled(Paragraph)`
  ${() => css`
    text-decoration: line-through;
  `}
`;

const Discount = styled.div`
  ${() => css`
    background-color: ${colors.error};
    color: ${colors.white};
    padding: 1px 6px;
    border-radius: 26px;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;

    ${breakpoints.desktop`
        padding: 4px 8px;
        font-size: 12px;
    `}
  `}
`;

const DiscountPrice = ({ price, discount }: ComponentProps) => {
  return (
    <Root>
      <StyledParagraph>{price}</StyledParagraph>
      <Discount>{discount}</Discount>
    </Root>
  );
};

export default DiscountPrice;
