import React from 'react';
import Wizard from 'src/presentation/wizard/Wizard';
import styled, { css } from 'styled-components';
import Layout from 'src/presentation/layouts/Layout';
import Basket from 'src/presentation/components/Basket/Basket';
import wizardSteps from 'src/integration/config/wizardSteps';
import WizardNavigator from 'src/presentation/components/WizzardNavigator/WizardNavigator';
import breakpoints from 'src/integration/styles/breakpoints';
import ErrorPage from 'src/presentation/pages/ErrorPage';
import BasketCheckoutFooter from 'src/presentation/components/Basket/BasketCheckoutFooter';
import LoadingSkeletonCheckout from 'src/presentation/components/LoadingSkeletons/LoadingSkeletonCheckout';
import { observer } from 'mobx-react-lite';
import useCheckoutPageHook from 'src/toolkit/hooks/useCheckoutPageHook';
import routerPaths from 'src/integration/config/routerPaths';
import { useTranslation } from 'react-i18next';

const Root = styled.div``;

const NavigatorLayout = styled(Layout)`
  ${() => css`
    padding: 24px 16px;

    //TODO: Update/remove once there is a design for overflowing steps
    ${breakpoints.mobile`
      overflow-x: auto;
    `}

    ${breakpoints.desktop`
      padding: 24px 0;        
    `}
  `}
`;

const StyledLayout = styled(Layout)`
  ${() => css`
    padding-bottom: 24px;

    ${breakpoints.desktop`
      padding-bottom: 42px;   
    `}
  `}
`;

const CheckoutPage = () => {
  const { isLoading, isError, dataCheckoutDetails, dataPackageDetails } = useCheckoutPageHook();
  const { t } = useTranslation();

  if (isLoading) {
    return <LoadingSkeletonCheckout />;
  }

  if (isError) return <ErrorPage buttonText={t('error.page.button.pdp')} buttonLink={routerPaths.home()} />;

  return (
    <Root>
      <NavigatorLayout type={'row'}>
        <WizardNavigator steps={wizardSteps} />
      </NavigatorLayout>
      <StyledLayout
        type={'column'}
        sidebar={
          <Basket
            details={dataPackageDetails}
            discountPercentage={dataCheckoutDetails?.totalsPricing.discountPercentage}
            footer={
              <BasketCheckoutFooter
                price={dataCheckoutDetails?.totalsPricing}
                priceDetailsDocumentUrl={dataPackageDetails?.pdfDocumentUrl}
              />
            }
            startDate={dataCheckoutDetails?.startDate}
            endDate={dataCheckoutDetails?.endDate}
            displayThingsToKnowLink
          />
        }
      >
        <Wizard />
      </StyledLayout>
    </Root>
  );
};

export default observer(CheckoutPage);
