import ThemeNames from 'src/domain/Settings/enum/ThemeNames';
import { themeStyleMapping } from 'src/integration/themes/themeStyle';
import { themeLinksMapping } from 'src/integration/themes/themeLinks';
import useAppVariablesProvider from 'src/toolkit/hooks/useAppVariablesProvider';
import { themeVariablesMapping } from 'src/integration/themes/themeVariables';

const useThemeProvider = () => {
  const { themeName } = useAppVariablesProvider();

  const getThemeName = (name: ThemeNames) => {
    return themeStyleMapping[name];
  };

  const getActiveThemeName = () => {
    if (themeName === ThemeNames.themeExplorer || themeName === ThemeNames.themeStaTravel) {
      return themeName;
    }

    return ThemeNames.defaultTheme;
  };

  const getActiveTheme = () => {
    return getThemeName(getActiveThemeName());
  };

  const activeThemeName = getActiveThemeName();
  const themeLink = themeLinksMapping[activeThemeName];
  const themeVariable = themeVariablesMapping[activeThemeName];

  return { getActiveTheme, themeLink, themeVariable };
};

export default useThemeProvider;
