import i18n from 'src/toolkit/i18n/i18n';

const useLanguageHook = () => {
  const changeLanguageHandler = (language: string) => {
    i18n.changeLanguage(language);
  };

  return {
    changeLanguageHandler,
  };
};

export default useLanguageHook;
