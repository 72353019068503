import React from 'react';
import WhiteBox from 'src/presentation/components/ui/WhiteBox/WhiteBox';
import styled, { css } from 'styled-components';
import AvailabilityForm from 'src/presentation/components/AvailabilityComponent/elements/AvailabilityForm';
import Note from 'src/presentation/components/ui/Note/Note';
import useFetchDepartures from 'src/integration/api/useFetchDepartures';
import PackageAvailabilityList from 'src/presentation/components/AvailabilityComponent/elements/PackageAvailabilityList';
import useViewport from 'src/toolkit/utils/useViewport';
import { useTranslation } from 'react-i18next';
import ButtonLink from 'src/presentation/components/ui/ButtonLink/ButtonLink';
import useInquiryLinkCreator from 'src/toolkit/hooks/useInquiryLinkCreator';
import breakpoints from 'src/integration/styles/breakpoints';

type ComponentProps = Component;

const Root = styled.div`
  ${({ theme }) => css`
    min-height: calc(100vh - ${theme.footer.mobileHeight}px - 24px);

    ${breakpoints.desktop`
      min-height: calc(70vh - ${theme.footer.desktopHeight}px - 42px);
    `}
  `}
`;

const Column = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `}
`;

const AvailabilityComponent = ({ id }: ComponentProps) => {
  const { data, isFetching, isError, fetchData } = useFetchDepartures();
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const { inquiryLink } = useInquiryLinkCreator();

  return (
    <Root>
      <WhiteBox
        id={id}
        title={t('section.availability.title')}
        hPaddingDesktop={24}
        headerLevel={isMobile ? 'h6' : 'h3'}
      >
        <Column>
          <Note>
            <ButtonLink large href={inquiryLink}>
              {t('availability_form.link.make_private_tour')}
            </ButtonLink>
          </Note>

          <AvailabilityForm onClick={() => fetchData()} isLoading={isFetching} />

          <PackageAvailabilityList isLoading={isFetching} packagesList={data} isError={isError} />
        </Column>
      </WhiteBox>
    </Root>
  );
};

export default AvailabilityComponent;
