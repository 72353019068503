import React from 'react';
import WhiteBox from 'src/presentation/components/ui/WhiteBox/WhiteBox';
import { useTranslation } from 'react-i18next';
import PaymentMethodButton from 'src/presentation/components/CheckoutForm/elements/PaymentMethodButton';
import styled, { css } from 'styled-components';
import Note from 'src/presentation/components/ui/Note/Note';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import PaymentMethod from 'src/domain/Payments/enum/PaymentMethod';
import breakpoints from 'src/integration/styles/breakpoints';
import usePaymentsHook from 'src/toolkit/hooks/usePaymentsHook';
import useViewport from 'src/toolkit/utils/useViewport';

const PaymentArea = styled.div`
  ${() => css`
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    grid-gap: 16px;

    ${breakpoints.desktop`
     flex-direction: row;
      grid-gap: 32px;
    `}
  `}
`;

const PaymentBlock = ({ className }: Component) => {
  const { t } = useTranslation();
  const { fullPayment, depositPayment, selectedPaymentMethod, handlePaymentMethod } = usePaymentsHook();
  const { isMobile } = useViewport();

  return (
    <WhiteBox
      title={t('form.payment.title')}
      hPaddingDesktop={24}
      className={className}
      headerLevel={isMobile ? 'h5' : 'h3'}
    >
      <Note title={t('form.payment.note.title')} isIconVisible>
        <Paragraph>{t('form.payment.note.page_is_encrypted')} </Paragraph>
      </Note>
      <PaymentArea>
        {depositPayment && (
          <PaymentMethodButton
            id={PaymentMethod.deposit}
            title={t('form.payment.method.button.down_payment')}
            buttonGroupName="payment"
            amount={depositPayment.amount}
            onChange={handlePaymentMethod}
            selected={selectedPaymentMethod === PaymentMethod.deposit}
          />
        )}
        {fullPayment && (
          <PaymentMethodButton
            id={PaymentMethod.full}
            title={t('form.payment.method.button.total_payment')}
            buttonGroupName="payment"
            amount={fullPayment.amount}
            onChange={handlePaymentMethod}
            selected={selectedPaymentMethod === PaymentMethod.full}
          />
        )}
      </PaymentArea>
    </WhiteBox>
  );
};

export default PaymentBlock;
