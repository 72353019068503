import React from 'react';
import Header from 'src/presentation/components/ui/Header/Header';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import TravellerFormValues from 'src/domain/Wizard/model/TravellerFormValues';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import useViewport from 'src/toolkit/utils/useViewport';
import useWizardHelper from 'src/toolkit/hooks/useWizardHelper';

interface ComponentProps extends Component {
  traveller: TravellerFormValues;
  index: number;
}

const Root = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 16px;

    border-radius: 10px;
    border: 1px solid ${colors.neutral90};
    padding: 16px 24px;
  `};
`;

const Block = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  `}
`;

const TravellerPreview = ({ traveller, index, className }: ComponentProps) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const { getNationalitiesNameFromList } = useWizardHelper();

  const genderTranslations = {
    female: t('form_field.gender.option.female'),
    male: t('form_field.gender.option.male'),
    unknown: t('form_field.gender.option.other'),
  };

  return (
    <Root className={className}>
      <Header level={isMobile ? 'h5' : 'h4'}>
        {t('form.traveler_info.title')} {index + 1}
      </Header>

      <Block>
        <Header level={'h7'}>
          {traveller.firstName} {traveller.middleName} {traveller.lastName}
        </Header>
        <Paragraph large>
          {t('form_field.gender.label')}: {genderTranslations[traveller.gender]}
        </Paragraph>
        <Paragraph large>
          {t('form_field.date_of_birth.label')}: {traveller.dateOfBirth}
        </Paragraph>
        <Paragraph large>
          {t('form_field.nationality.label')}: {getNationalitiesNameFromList(traveller.nationality)}
        </Paragraph>
        <Paragraph large>
          {t('form_field.passport_number.label')}: {traveller.passportNumber}
        </Paragraph>
        <Paragraph large>
          {t('form_field.expiration_date.label')}: {traveller.passportExpirationDate}
        </Paragraph>
        {traveller.medicalConditionsDetails && (
          <Paragraph large>
            {t('form_field.preexisting_medical_conditions.title')}: {traveller.medicalConditionsDetails}
          </Paragraph>
        )}
      </Block>
    </Root>
  );
};

export default TravellerPreview;
