import React from 'react';
import WhiteBox from 'src/presentation/components/ui/WhiteBox/WhiteBox';
import styled, { css } from 'styled-components';
import TravellerFormValues from 'src/domain/Wizard/model/TravellerFormValues';
import TravellerPreview from 'src/presentation/components/CheckoutForm/elements/TravellerPreview';
import { useTranslation } from 'react-i18next';
import useViewport from 'src/toolkit/utils/useViewport';
import useTravellerInformationPreview from 'src/toolkit/hooks/useTravellerInformationPreview';
import Header from 'src/presentation/components/ui/Header/Header';
import IconArrowDown from 'src/presentation/components/ui/Icon/IconArrowDown';

interface ComponentProps extends Component {
  travellers: TravellerFormValues[];
}

const StyledTravellerPreview = styled(TravellerPreview)`
  ${() => css`
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  `}
`;

const MainContent = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 16px;
    cursor: pointer;
  `}
`;

const ExpandableContent = styled.div<{ $isOpen: boolean }>`
  ${({ $isOpen }) => css`
    max-height: 0;
    overflow: hidden;

    ${$isOpen &&
    css`
      max-height: unset;
      padding-top: 10px;
    `}
  `}
`;

const TravelInformationPreview = ({ travellers, className }: ComponentProps) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const { isOpen, onClick } = useTravellerInformationPreview();

  return (
    <WhiteBox hPaddingDesktop={24} className={className}>
      <MainContent onClick={onClick}>
        <Header level={isMobile ? 'h5' : 'h3'}>{t('form.review.traveler_information.title')}</Header>
        <IconArrowDown size={36} isOpen={isOpen} />
      </MainContent>

      <ExpandableContent $isOpen={isOpen}>
        {travellers.map((item, index) => (
          <StyledTravellerPreview key={`traveller-${index}`} traveller={item} index={index} />
        ))}
      </ExpandableContent>
    </WhiteBox>
  );
};

export default TravelInformationPreview;
