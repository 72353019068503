import { useStore } from 'src/integration/store/store';
import StepMap from 'src/domain/Wizard/enum/stepMap';
import useWizardHelper from 'src/toolkit/hooks/useWizardHelper';

const useGenericTransition = () => {
  const { wizardStore } = useStore();
  const { getCurrentStepId } = useWizardHelper();

  const openStep = (step: StepMap) => {
    wizardStore.setStep(step);
  };

  function handleGenericTransition() {
    const currentStep = getCurrentStepId();

    if (!currentStep) {
      return;
    }

    if (!currentStep.next) {
      return;
    }

    openStep(currentStep.next);
  }

  return {
    handleGenericTransition,
    openStep,
  };
};

export default useGenericTransition;
