import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import ErrorText from 'src/presentation/components/FormFields/ErrorText';
import colors from 'src/integration/styles/colors';
import { renderToStaticMarkup } from 'react-dom/server';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import { iconCheckRounded } from 'src/integration/assets/AssetsProvider';

interface ComponentProps<T extends FieldValues> {
  name: Path<T>;
  register?: UseFormRegister<T>;
  required?: boolean;
  errors?: string;
  disabled?: boolean;
  checked?: boolean;
  children: ReactNode;
}

const ICON_CHECK_URL = `data:image/svg+xml;utf8,${encodeURIComponent(
  renderToStaticMarkup(<Icon icon={iconCheckRounded} />),
)}`;

const Root = styled.div``;

const Content = styled.div`
  ${() => css`
    display: flex;
    align-items: flex-start;
    gap: 8px;
  `}
`;

const Required = styled.span`
  ${() => css`
    color: ${colors.error};
  `}
`;

const CheckboxInput = styled.input<{ $error?: boolean; disabled?: boolean }>`
  ${({ theme, $error, disabled }) => css`
    flex-shrink: 0;
    -webkit-appearance: none;
    appearance: none;
    margin: 2px 0 0;
    width: 20px;
    height: 20px;
    border: 2px solid ${$error ? colors.error : colors.neutral50};
    border-radius: 3px;
    display: grid;
    place-content: center;

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 3px;
      transform: scale(0);
      background: ${theme.colors.primary} url(${ICON_CHECK_URL}) center/contain no-repeat;
      background-size: 10px 8px;
    }

    &:checked::before {
      transform: scale(1);
    }

    ${disabled &&
    css`
      border: 2px solid ${colors.neutral80};

      &:before {
        background: ${colors.neutral80} url(${ICON_CHECK_URL}) center/contain no-repeat;
        background-size: 10px 8px;
      }
    `}
  `}
`;

const Checkbox = <T extends FieldValues>({
  register,
  name,
  errors,
  disabled,
  checked,
  required,
  children,
}: ComponentProps<T>) => {
  return (
    <Root>
      {required && <Required>*</Required>}
      <Content>
        <CheckboxInput
          id={name}
          {...(register ? register(name) : null)}
          type="checkbox"
          $error={!!errors}
          disabled={disabled}
          checked={checked}
        />
        {children}
      </Content>
      {errors && <ErrorText error={errors} />}
    </Root>
  );
};

export default Checkbox;
