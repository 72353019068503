import React from 'react';
import styled, { css } from 'styled-components';
import useExtendableContent from 'src/toolkit/hooks/useExtendableContent';
import { useTranslation } from 'react-i18next';
import IconArrowDown from 'src/presentation/components/ui/Icon/IconArrowDown';
import Header from 'src/presentation/components/ui/Header/Header';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import colors from 'src/integration/styles/colors';

const Root = styled.div`
  ${() => css``}
`;

const MainContent = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
    grid-gap: 16px;
    cursor: pointer;
  `}
`;

const List = styled.ul`
  ${() => css`
    list-style-type: inherit;
    margin-left: 10px;
    padding-left: 20px;
  `}
`;

const ListItem = styled.li`
  ${() => css``}
`;

const ExpandableContent = styled.div<{ $isOpen: boolean }>`
  ${({ $isOpen }) => css`
    max-height: 0;
    overflow: hidden;

    ${$isOpen &&
    css`
      max-height: 500px;
      padding-top: 2px;
    `}
  `}
`;

const Optional = styled.span`
  ${() => css`
    color: ${colors.neutral50};
  `}
`;

const PreexistingMedicalConditions = () => {
  const { isOpen, onClick } = useExtendableContent();
  const { t } = useTranslation();

  return (
    <Root>
      <MainContent onClick={onClick}>
        <Header level={'h7'}>
          {t('form_field.preexisting_medical_conditions.title')}
          <Optional>&nbsp;{t('form_field.general.optional.text')}</Optional>
        </Header>
        <IconArrowDown size={24} isOpen={isOpen} />
      </MainContent>

      <ExpandableContent $isOpen={isOpen}>
        <Paragraph large>{t('form_field.preexisting_medical_conditions.description.top')}</Paragraph>
        <List>
          <ListItem>
            <Paragraph large>{t('form_field.preexisting_medical_conditions.description.item_1')}</Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph large>{t('form_field.preexisting_medical_conditions.description.item_2')}</Paragraph>
          </ListItem>
          <ListItem>
            <Paragraph large>{t('form_field.preexisting_medical_conditions.description.item_3')}</Paragraph>
          </ListItem>
        </List>
        <Paragraph large>{t('form_field.preexisting_medical_conditions.description.bottom')}</Paragraph>
      </ExpandableContent>
    </Root>
  );
};

export default PreexistingMedicalConditions;
