import React from 'react';
import { createHashRouter } from 'react-router-dom';
import routerPaths from 'src/integration/config/routerPaths';
import PackageDetailsPage from 'src/presentation/pages/PackageDetailsPage';
import CheckoutPage from 'src/presentation/pages/CheckoutPage';
import ErrorPage from 'src/presentation/pages/ErrorPage';
import ConfirmationPage from 'src/presentation/pages/ConfirmationPage';

const router = createHashRouter([
  {
    path: routerPaths.home(),
    element: <PackageDetailsPage />,
  },
  {
    path: routerPaths.checkout(':id'),
    element: <CheckoutPage />,
  },
  {
    path: routerPaths.error(),
    element: <ErrorPage />,
  },
  {
    path: routerPaths.confirmation(':cartId'),
    element: <ConfirmationPage />,
  },
]);

export default router;
