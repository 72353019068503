const apiConfig = {
  api: {
    baseUrl: process.env.REACT_APP_API_URL,
    endpoints: {
      packages: '/api/v1/trip-packages/:id',
      departures: '/api/v1/trip-packages/:id/departures',
      checkoutInit: '/api/v1/checkout/init',
      paymentInit: '/api/v1/payment/init',
      checkoutDetails: '/api/v1/checkout/:cartId/details',
      checkoutSummary: '/api/v1/checkout/:cartId/summary',
      customersValidate: '/api/v1/checkout/customers/validate',
      countries: '/api/v1/countries',
      nationalities: '/api/v1/nationalities',
    },
  },
  cypress: {
    endpoints: {
      images: '*.jpg',
      packages: '/api/v1/trip-packages/*',
      departures: '/api/v1/trip-packages/*/departures?*',
      checkoutInit: '/api/v1/checkout/init',
      paymentInit: '/api/v1/payment/init',
      checkoutDetails: '/api/v1/checkout/*/details',
      customersValidate: '/api/v1/checkout/customers/validate',
      countries: '/api/v1/countries',
      nationalities: '/api/v1/nationalities',
    },
  },
};

export default apiConfig;
