import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';

const Root = styled.div`
  ${() => css`
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
  `}

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 3px solid ${colors.neutral50};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${colors.neutral50} transparent transparent transparent;
  }
  
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
  
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;
const LoadingSpinner = ({ className }: Component) => (
  <Root className={className}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </Root>
);

export default LoadingSpinner;
