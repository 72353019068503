import React from 'react';
import WhiteBox from 'src/presentation/components/ui/WhiteBox/WhiteBox';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import TravelDetailsForm from 'src/domain/Wizard/model/TravelDetailsForm';
import Header from 'src/presentation/components/ui/Header/Header';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import useViewport from 'src/toolkit/utils/useViewport';
import useTravellerInformationPreview from 'src/toolkit/hooks/useTravellerInformationPreview';
import IconArrowDown from 'src/presentation/components/ui/Icon/IconArrowDown';
import useWizardHelper from 'src/toolkit/hooks/useWizardHelper';

interface ComponentProps {
  travelDetails: TravelDetailsForm;
}

const Block = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 8px;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  `}
`;

const MainContent = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 16px;
    cursor: pointer;
  `}
`;

const ExpandableContent = styled.div<{ $isOpen: boolean }>`
  ${({ $isOpen }) => css`
    max-height: 0;
    overflow: hidden;

    ${$isOpen &&
    css`
      max-height: unset;
      padding-top: 10px;
    `}
  `}
`;

const ContactDetailsPreview = ({ travelDetails }: ComponentProps) => {
  const { t } = useTranslation();
  const { getCountryNameFromList } = useWizardHelper();
  const { isMobile } = useViewport();
  const { isOpen, onClick } = useTravellerInformationPreview();

  return (
    <WhiteBox hPaddingDesktop={24} headerLevel={isMobile ? 'h5' : 'h3'}>
      <MainContent onClick={onClick}>
        <Header level={isMobile ? 'h5' : 'h3'}>{t('form.contact_details.title')} </Header>
        <IconArrowDown size={36} isOpen={isOpen} />
      </MainContent>

      <ExpandableContent $isOpen={isOpen}>
        <Block>
          <Paragraph large> {travelDetails.email}</Paragraph>
          <Paragraph large>
            {t('form_field.phone_number.title')}: {travelDetails.phoneNumber}
          </Paragraph>
        </Block>

        <Block>
          <Header level={'h7'}>{t('form_field.address.title')}</Header>
          <Paragraph large>
            {t('form_field.address.label')}: {travelDetails.address}
          </Paragraph>
          <Paragraph large>
            {t('form_field.zip_code.label')}: {travelDetails.zipCode}
          </Paragraph>
          <Paragraph large>
            {t('form_field.city.label')}: {travelDetails.city}
          </Paragraph>
          <Paragraph large>
            {t('form_field.country.label')}: {getCountryNameFromList(travelDetails.country)}
          </Paragraph>
        </Block>
      </ExpandableContent>
    </WhiteBox>
  );
};

export default ContactDetailsPreview;
