import React from 'react';
import styled, { css } from 'styled-components';

interface ComponentProps {
  imageUrl?: string;
  enableBoxShadow?: boolean;
}

const Root = styled.a<{ $enableBoxShadow?: boolean }>`
  ${({ $enableBoxShadow }) => css`
    display: block;

    ${$enableBoxShadow &&
    css`
      box-shadow: 0 3px 20px 0 rgba(9, 10, 10, 0.12);
    `}

    &:focus > *,
    &:hover > * {
      outline: none;
    }
  `}
`;

const Image = styled.img`
  ${() => css`
    width: 100%;
    object-fit: cover;

    &:hover {
      outline: none;
    }
  `}
`;

const Map = ({ imageUrl, enableBoxShadow }: ComponentProps) => {
  return (
    <Root target="_blank" href={imageUrl} $enableBoxShadow={enableBoxShadow}>
      <Image src={imageUrl} />
    </Root>
  );
};

export default Map;
