import React from 'react';
import Layout from 'src/presentation/layouts/Layout';
import useViewport from 'src/toolkit/utils/useViewport';
import SkeletonText from 'src/presentation/components/LoadingSkeletons/elements/SkeletonText';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import Skeleton from 'src/presentation/components/LoadingSkeletons/elements/Skeleton';
import breakpoints from 'src/integration/styles/breakpoints';

const Root = styled.div`
  ${() => css`
    min-height: 100vh;
    background: ${colors.neutral96};
  `}
`;

const OneRowBlock = styled.div<{ $small?: boolean }>`
  ${({ $small }) => css`
    width: 100%;
    display: flex;
    gap: 24px;

    ${$small &&
    css`
      gap: 12px;
    `}
  `}
`;

const Wrapper = styled.div`
  ${() => css`
    padding: 0 16px;
  `}
`;

const Column = styled.div`
  ${() => css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `}
`;

const StyledLayout = styled(Layout)`
  ${() => css`
    padding: 0 0 24px;

    ${breakpoints.desktop`
      padding: 32px 0; 
    `}
  `}
`;

const LoadingSkeletonConfirmation = () => {
  const { isMobile, isDesktop } = useViewport();

  return (
    <Root>
      {isMobile && (
        <StyledLayout type={'row'}>
          <Skeleton height={164} marginBottom={24} />
          <Wrapper>
            <Column>
              <SkeletonText height={40} width={40} marginBottom={16} />
              <SkeletonText height={32} width={191} marginBottom={16} />
              <SkeletonText height={24} width={259} marginBottom={24} />
              <SkeletonText height={32} width={222} marginBottom={24} />

              <OneRowBlock $small>
                <SkeletonText height={24} width={154} marginBottom={6} />
                <SkeletonText height={24} width={154} marginBottom={6} />
              </OneRowBlock>

              <OneRowBlock $small>
                <SkeletonText height={24} width={154} marginBottom={24} />
                <SkeletonText height={24} width={154} marginBottom={24} />
              </OneRowBlock>

              <SkeletonText height={96} marginBottom={48} />
              <SkeletonText height={56} marginBottom={16} />
              <SkeletonText height={80} />
            </Column>
          </Wrapper>
        </StyledLayout>
      )}

      {isDesktop && (
        <StyledLayout type={'row'}>
          <OneRowBlock>
            <Column>
              <SkeletonText height={370} />
            </Column>

            <Column>
              <SkeletonText height={40} width={40} marginBottom={16} />
              <SkeletonText height={32} width={259} marginBottom={16} />
              <SkeletonText height={24} width={399} marginBottom={24} />
              <SkeletonText height={32} width={259} marginBottom={24} />

              <OneRowBlock $small>
                <SkeletonText height={24} width={154} marginBottom={6} />
                <SkeletonText height={24} width={154} marginBottom={6} />
              </OneRowBlock>

              <OneRowBlock $small>
                <SkeletonText height={24} width={154} marginBottom={24} />
                <SkeletonText height={24} width={154} marginBottom={24} />
              </OneRowBlock>

              <SkeletonText height={96} marginBottom={32} />
            </Column>
          </OneRowBlock>

          <OneRowBlock>
            <Column>
              <SkeletonText height={100} marginBottom={16} />
              <SkeletonText height={100} />
            </Column>

            <Column>
              <SkeletonText height={18} width={233} marginBottom={8} />
              <SkeletonText height={18} width={279} marginBottom={33} />
              <SkeletonText height={64} marginBottom={12} />
              <SkeletonText height={64} />
            </Column>
          </OneRowBlock>
        </StyledLayout>
      )}
    </Root>
  );
};

export default LoadingSkeletonConfirmation;
