import React from 'react';
import Header from 'src/presentation/components/ui/Header/Header';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import LinkBox from 'src/presentation/components/ui/LinkBox/LinkBox';
import { iconFlight, iconInsurance, iconMail, iconPhone } from 'src/integration/assets/AssetsProvider';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import breakpoints from 'src/integration/styles/breakpoints';
import useViewport from 'src/toolkit/utils/useViewport';
import Separator from 'src/presentation/components/ui/Separator/Separator';
import useThemeProvider from 'src/toolkit/hooks/useThemeProvider';

const Root = styled.div`
  ${() => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${breakpoints.desktop`
      flex-direction: row;
      gap: 24px;        
    `}
  `}
`;

const Wrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${breakpoints.desktop`
      width: 100%;
    `}
  `}
`;

const ServicesWrapper = styled(Wrapper)`
  ${() => css`
    ${breakpoints.mobile`
      padding: 16px;       
    `}
  `}
`;

const ContactsWrapper = styled(Wrapper)`
  ${() => css`
    ${breakpoints.mobile`
      padding: 16px 16px 0;        
    `}
  `}
`;

const LinksWrapper = styled(Wrapper)`
  ${() => css`
    ${breakpoints.desktop`
      gap: 12px;        
    `}
  `}
`;

const ConfirmationLinks = () => {
  const { t } = useTranslation();
  const { themeLink } = useThemeProvider();
  const { isMobile, isDesktop } = useViewport();

  return (
    <Root>
      <ServicesWrapper>
        <LinkBox link={t(themeLink.bookFlight)} icon={iconFlight} large={isDesktop}>
          <Header level={isMobile ? 'h7' : 'h5'}>{t('confirmation.link.book_flight_to.title')}</Header>
        </LinkBox>
        <LinkBox link={t(themeLink.insurance)} icon={iconInsurance} large={isDesktop}>
          <Header level={isMobile ? 'h7' : 'h5'}>{t('confirmation.link.need_insurance.title')}</Header>
          <Paragraph large>{t('confirmation.link.need_insurance.subtitle')}</Paragraph>
        </LinkBox>
      </ServicesWrapper>

      <ContactsWrapper>
        <Separator />
        <Wrapper>
          <Header level={'h6'}>{t('confirmation.got_question_about_this_tour.title')}</Header>
          <Paragraph large>{t('confirmation.got_question_about_this_tour.subtitle')}</Paragraph>
        </Wrapper>
        <LinksWrapper>
          <LinkBox link={`tel:${t(themeLink.phone)}`} icon={iconPhone} light>
            <Paragraph large semiBold={isDesktop} color={isDesktop ? 'neutral30' : 'neutral10'}>
              {t(themeLink.phone)}
            </Paragraph>
          </LinkBox>
          <LinkBox link={`mailto:${t(themeLink.email)}`} icon={iconMail} light>
            <Paragraph large semiBold={isDesktop} color={isDesktop ? 'neutral30' : 'neutral10'}>
              {t(themeLink.email)}
            </Paragraph>
          </LinkBox>
        </LinksWrapper>
      </ContactsWrapper>
    </Root>
  );
};

export default ConfirmationLinks;
