import React from 'react';
import styled, { css } from 'styled-components';
import Header from 'src/presentation/components/ui/Header/Header';
import { imageErrorLarge, imageErrorSmall } from 'src/integration/assets/AssetsProvider';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import { useTranslation } from 'react-i18next';
import useViewport from 'src/toolkit/utils/useViewport';
import breakpoints from 'src/integration/styles/breakpoints';
import routerPaths from 'src/integration/config/routerPaths';
import { useNavigate } from 'react-router-dom';
import Button from 'src/presentation/components/ui/Button/Button';
import ButtonLink from 'src/presentation/components/ui/ButtonLink/ButtonLink';

interface ComponentProps {
  buttonText?: string;
  buttonLink?: string;
}

const Root = styled.div`
  ${() => css`
    width: 100%;
    height: 492px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 32px;

    ${breakpoints.desktop`
      width: 552px;
      height: 614px;
    `}
  `}
`;

const StyledIcon = styled(Icon)`
  ${() => css`
    width: 258px;
    height: auto;

    ${breakpoints.desktop`
      width: 339px;
    `}
  `}
`;

const SharedButtonStyles = css`
  min-width: 213px;
  display: flex;
  justify-content: center;

  ${breakpoints.desktop`
    min-width: 272px;
  `}
`;

const StyledButton = styled(Button)`
  ${SharedButtonStyles}
`;

const StyledLinkButton = styled(ButtonLink)`
  ${SharedButtonStyles}
`;

const Text = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;

    ${breakpoints.desktop`
      grid-gap: 24px;  
    `}
  `}
`;

const ErrorPage = ({ buttonText, buttonLink }: ComponentProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useViewport();

  const renderButton = () => {
    if (buttonLink) {
      return (
        <StyledButton styleType={'primary'} onClick={() => navigate(buttonLink)}>
          {buttonText ?? t('error.page.button.homepage')}
        </StyledButton>
      );
    }
    return (
      <StyledLinkButton styleType={'primary'} href={routerPaths.home()} target={''}>
        {buttonText ?? t('error.page.button.homepage')}
      </StyledLinkButton>
    );
  };

  return (
    <Root data-cy="error-page">
      <StyledIcon icon={isMobile ? imageErrorSmall : imageErrorLarge} />
      <Text>
        <Header level={isMobile ? 'h6' : 'h3'}>{t('error.page.something_went_wrong')}</Header>
        <Header level={isMobile ? 'h7' : 'h6'}>{t('error.page.please_continue')}</Header>
      </Text>

      {renderButton()}
    </Root>
  );
};

export default ErrorPage;
