import { useStore } from 'src/integration/store/store';
import wizardSteps from 'src/integration/config/wizardSteps';
import TravelDetailsForm from 'src/domain/Wizard/model/TravelDetailsForm';
import useDateTimeFormatter from 'src/toolkit/utils/useDateTimeFormatter';
import { CustomerData } from 'src/domain/Schema/model/apiTypes';
import { useCallback } from 'react';
import useFetchNationalities from 'src/integration/api/useFetchNationalities';
import useFetchCountries from 'src/integration/api/useFetchCountries';

const useWizardHelper = () => {
  const { wizardStore } = useStore();
  const { data: nationalitiesList } = useFetchNationalities();
  const { data: countriesList } = useFetchCountries();
  const { formatDateISOFromDefault } = useDateTimeFormatter();
  const currentStepId = wizardStore.step;

  const getCurrentStepId = () => {
    return wizardSteps.find((s) => s.id === currentStepId);
  };

  const getStepId = (step: string) => {
    return wizardSteps.find((item) => item.stepName === step);
  };

  const setNullIfEmpty = (item?: string) => {
    return !item?.length ? null : item;
  };

  const isPreviousStepAvailable = useCallback(() => {
    return getCurrentStepId()?.previous != null;
  }, [getCurrentStepId]);

  const getNationalitiesNameFromList = useCallback((id: string) => {
    return nationalitiesList?.find(item => {
      return id === item.id
    } )?.name || '-'
  }, [nationalitiesList]);

  const getCountryNameFromList = useCallback((id: string) => {
    return countriesList?.find(item => {
      return id === item.id
    } )?.name || '-'
  }, [countriesList]);

  const convertToTravellerDetails = (data: SimpleObject): TravelDetailsForm => {
    const travellers = data.travellers.map((item: SimpleObject) => {
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        middleName: item.middleName,
        title: item.title,
        dateOfBirth: item.dateOfBirth,
        nationality: item.nationality,
        gender: item.gender,
        passportNumber: item.passportNumber,
        passportExpirationDate: item.passportExpirationDate,
        medicalConditionsDetails: item.medicalConditionsDetails,
      };
    });

    return {
      email: data.email,
      phoneNumber: data.phoneNumber,
      address: data.address,
      zipCode: data.zipCode,
      city: data.city,
      country: data.country,
      travellers: travellers,
      terms: data.terms,
    };
  };

  const mappedTravellerDetails = (cartId: string, travelDetailsData: TravelDetailsForm): CustomerData => {
    const customers = travelDetailsData.travellers.map((item) => {
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        middleName: setNullIfEmpty(item.middleName),
        title: item.title,
        dateOfBirth: formatDateISOFromDefault(item.dateOfBirth),
        nationality: item.nationality,
        gender: item.gender,
        passportNumber: item.passportNumber,
        passportExpirationDate: formatDateISOFromDefault(item.passportExpirationDate),
        medicalConditions: setNullIfEmpty(item.medicalConditionsDetails),
      };
    });

    return {
      cartId: cartId,
      contactDetails: {
        email: travelDetailsData.email,
        phoneNumber: travelDetailsData.phoneNumber,
        address: {
          street: travelDetailsData.address,
          zipCode: travelDetailsData.zipCode,
          city: travelDetailsData.city,
          country: travelDetailsData.country,
        },
      },
      customers: customers,
      terms: {
        termsAndConditionsAgreed: travelDetailsData.terms.termsAndConditionsAgreed,
        termsAndConditionsIntermediaryAgreed: travelDetailsData.terms.termsAndConditionsIntermediaryAgreed,
      },
    };
  };

  return {
    getStepId,
    getCurrentStepId,
    isPreviousStepAvailable,
    mappedTravellerDetails,
    convertToTravellerDetails,
    getNationalitiesNameFromList,
    getCountryNameFromList
  };
};

export default useWizardHelper;
