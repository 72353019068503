import useReactQuery from 'src/integration/api/useReactQuery';
import apiConfig from 'src/integration/config/apiConfig';
import { CheckoutSummary } from 'src/domain/Schema/model/apiTypes';
import useQueryKey from 'src/toolkit/utils/useQueryKey';
import ErrorType from 'src/domain/Schema/model/ErrorType';
import useQueryParams from 'src/toolkit/hooks/useQueryParams';

const useFetchCheckoutSummary = () => {
  const { cartId } = useQueryParams();

  const response = useReactQuery<null, ErrorType, CheckoutSummary>({
    endpoint: apiConfig.api.endpoints.checkoutSummary,
    queryKey: useQueryKey('checkoutSummary', [cartId]),
    method: 'GET',
    queryPathId: {
      cartId: cartId,
    },
    opts: {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  });

  return {
    ...response,
  };
};

export default useFetchCheckoutSummary;
