import { useEffect } from 'react';
import useSuccessPaymentEventLogger from 'src/toolkit/hooks/useSuccessPaymentEventLogger';
import useQueryParams from 'src/toolkit/hooks/useQueryParams';

const useConfirmationPageHook = () => {
  const { sendSuccessPaymentEvent } = useSuccessPaymentEventLogger;
  const { cartId } = useQueryParams();

  useEffect(() => {
    sendSuccessPaymentEvent(cartId);
  }, []);
};

export default useConfirmationPageHook;
