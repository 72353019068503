import React from 'react';
import styled, { css } from 'styled-components';
import useViewport from 'src/toolkit/utils/useViewport';
import Includes from 'src/presentation/components/Includes/Includes';
import useExtendableContent from 'src/toolkit/hooks/useExtendableContent';
import Button from 'src/presentation/components/ui/Button/Button';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  tripIncludes: string[];
}

const Root = styled.div``;

const IncludesWrapper = styled.div<{ $showFade?: boolean }>`
  ${({ $showFade }) => css`
    position: relative;

    ${$showFade &&
    css`
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 36px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 85%);
      }
    `}
  `}
`;

const StyledButton = styled(Button)`
  ${() => css`
    width: 100%;
    margin-top: 8px;
  `}
`;

const CustomIncludes = ({ tripIncludes }: ComponentProps) => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const { isOpen: isShowMoreSelected, onClick: handleShowMore } = useExtendableContent();
  const MAX_ITEM_IN_LIST = 5;
  const isShowMoreVisible = !isShowMoreSelected && tripIncludes.length > MAX_ITEM_IN_LIST;

  return (
    <Root>
      <IncludesWrapper $showFade={isShowMoreVisible}>
        <Includes tripIncludes={tripIncludes} limit={isShowMoreVisible ? MAX_ITEM_IN_LIST : undefined} />
      </IncludesWrapper>

      {isShowMoreVisible && (
        <StyledButton small={isMobile} styleType={'secondary'} onClick={handleShowMore}>
          {t('button.show_more')}
        </StyledButton>
      )}
    </Root>
  );
};

export default CustomIncludes;
