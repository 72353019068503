import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import Header from 'src/presentation/components/ui/Header/Header';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';

interface ComponentProps {
  title?: string;
  children?: ReactNode;
}

const Root = styled.div`
  ${() => css`
    position: relative;
    padding: 16px;
    align-items: flex-start;
    align-self: stretch;

    border-radius: 10px;
    border: 1px solid ${colors.neutral90};
    background: var(--neutral-100, #fff);
  `}
`;

const StyledParagraph = styled(Paragraph)`
  ${() => css`
    margin-top: 8px;
  `}
`;

const DetailsWhiteBox = ({ title, children }: ComponentProps) => {
  return (
    <Root>
      <Header level={'h6'}>{title}</Header>
      {children && <StyledParagraph>{children}</StyledParagraph>}
    </Root>
  );
};

export default DetailsWhiteBox;
