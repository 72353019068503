import styled, { css } from 'styled-components';
import React, { ReactNode } from 'react';
import Header from 'src/presentation/components/ui/Header/Header';
import breakpoints from 'src/integration/styles/breakpoints';
import colors from 'src/integration/styles/colors';

interface ComponentProps extends Component {
  children: ReactNode;
  headerNote?: ReactNode;
  title?: string;
  dark?: boolean;
  vPadding?: number;
  vPaddingDesktop?: number;
  hPadding?: number;
  hPaddingDesktop?: number;
  bHeaderMargin?: number;
  headerLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7';
}

const calculateDimensions = (padding: number | undefined) => (padding !== undefined ? `${padding}px` : '16px');

const Root = styled.div``;

const MainComponent = styled.div<{
  $dark: boolean;
  $vPadding?: number;
  $vPaddingDesktop?: number;
  $hPadding?: number;
  $hPaddingDesktop?: number;
  $headerNote?: ReactNode;
}>`
  ${({ theme, $dark, $vPadding, $vPaddingDesktop, $hPadding, $hPaddingDesktop, $headerNote }) => css`
    border-radius: 10px;
    background: ${colors.white};
    padding: ${calculateDimensions($vPadding)} ${calculateDimensions($hPadding)};
    box-sizing: border-box;

    ${$headerNote &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}

    ${$dark &&
    css`
      background: ${theme.colors.secondaryContainer};
    `}

    ${breakpoints.desktop`
      padding: ${calculateDimensions($vPaddingDesktop || $vPadding)} ${calculateDimensions(
        $hPaddingDesktop || $hPadding,
      )};
    `}
  `}
`;

const StyledHeader = styled(Header)<{ $bHeaderMargin?: number }>`
  ${({ $bHeaderMargin }) => css`
    margin-bottom: ${calculateDimensions($bHeaderMargin || 16)};
  `}
`;

const HeaderNote = styled.div`
  ${() => css`
    padding: 8px 24px;
    background: ${colors.error};
    color: ${colors.white};

    ${breakpoints.desktop`
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    `}
  `}
`;

const WhiteBox = ({
  title,
  headerNote,
  children,
  dark = false,
  vPadding,
  vPaddingDesktop,
  hPadding,
  hPaddingDesktop,
  bHeaderMargin,
  headerLevel,
  className,
  id,
}: ComponentProps) => {
  return (
    <Root>
      {!!headerNote && <HeaderNote>{headerNote}</HeaderNote>}
      <MainComponent
        id={id}
        className={className}
        $dark={dark}
        $vPadding={vPadding}
        $vPaddingDesktop={vPaddingDesktop}
        $hPadding={hPadding}
        $hPaddingDesktop={hPaddingDesktop}
        $headerNote={!!headerNote}
      >
        {title && (
          <StyledHeader level={headerLevel || 'h3'} $bHeaderMargin={bHeaderMargin}>
            {title}
          </StyledHeader>
        )}
        {children}
      </MainComponent>
    </Root>
  );
};

export default WhiteBox;
