import Separator from 'src/presentation/components/ui/Separator/Separator';
import React from 'react';
import styled, { css } from 'styled-components';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import useViewport from 'src/toolkit/utils/useViewport';

interface ComponentProps {
  tripIncludes?: string[];
  limit?: number;
}

const Root = styled.div``;

const List = styled.div``;

const StyledParagraph = styled(Paragraph)`
  ${() => css`
    padding: 8px 0;
  `}
`;

const ListOfIncludes = styled.ul`
  ${() => css`
      list-style: inherit;
      list-style-type: disc;
      padding-left: 20px;

      &[class] {
          list-style-type: disc;
      }
  `}
`;

const ListItem = styled.li``;

const Includes = ({ tripIncludes, limit }: ComponentProps) => {
  const { isMobile, isDesktop } = useViewport();
  const MAX_COUNT = limit ? limit : tripIncludes?.length;

  const displayedItems = tripIncludes?.slice(0, MAX_COUNT); // Create a copy of the array

  return (
    <Root>
      {displayedItems?.map((includedItem, index) => (
        <List key={`included-${limit}-${index}`}>
          <ListOfIncludes>
            <ListItem>{isMobile && <StyledParagraph>{includedItem}</StyledParagraph>}
              {isDesktop && <StyledParagraph large>{includedItem}</StyledParagraph>}
            </ListItem>
          </ListOfIncludes>
          <Separator />
        </List>
      ))}
    </Root>
  );
};

export default Includes;
