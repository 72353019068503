import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import SectionName from 'src/presentation/components/Itinerary/elements/SectionName';
import DetailsWhiteBox from 'src/presentation/components/Itinerary/elements/DetailsWhiteBox';
import { Transport } from 'src/domain/Schema/model/apiTypes';
import useTextBuilders from 'src/toolkit/utils/useTextBuilders';

interface ComponentProps {
  transports?: Transport[];
}

const Root = styled.div`
  ${({ theme }) => css`
    color: ${colors.neutral50};
    font-family: ${theme.fonts.fontFamily};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  `}
`;

const StyledSectionName = styled(SectionName)`
  ${() => css`
    margin-bottom: 8px;
  `}
`;

const Content = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
  `}
`;

const TransportSection = ({ transports }: ComponentProps) => {
  const { buildDirectionText } = useTextBuilders();

  return (
    <Root>
      <StyledSectionName>Transport</StyledSectionName>
      <Content>
        {transports?.map((item, index) => (
          <DetailsWhiteBox key={`activities-${index}`} title={item.typeName}>
            {buildDirectionText(item.startLocationText, item.endLocationText)}
          </DetailsWhiteBox>
        ))}
      </Content>
    </Root>
  );
};

export default TransportSection;
