import { useEffect, useState } from 'react';
import { deviceSize } from 'src/integration/styles/breakpoints';

const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const isMobile = width <= deviceSize.mobile;
  const isDesktop = width >= deviceSize.desktop;

  return { isMobile, isDesktop };
};

export default useViewport;
