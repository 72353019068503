import { useEffect } from 'react';
import useScrollToElement from 'src/toolkit/utils/useScrollToElement';
import useTravellerDetailsTransition from 'src/presentation/wizard/transitions/useTravellerDetailsTransition';
import usePaymentTransition from 'src/presentation/wizard/transitions/usePaymentTransition';
import useQueryParams from 'src/toolkit/hooks/useQueryParams';
import PaymentStatus from 'src/domain/Settings/enum/PaymentStatus';
import useEventLogger from 'src/toolkit/hooks/useEventLogger';

const useGeneralWizardErrorHook = () => {
  const { validationErrors, isValidationError, isError, isLoading, isFetching } = useTravellerDetailsTransition();
  const { isPaymentInitError } = usePaymentTransition();
  const { payment } = useQueryParams();
  const { scrollToElement } = useScrollToElement();
  const ERROR_NOTE_ID = 'errorNote';
  const isPaymentFailedError = payment === PaymentStatus.failed;
  const { logBackendFormValidateError } = useEventLogger();

  useEffect(() => {
    if (!(isError || isPaymentInitError || isValidationError || isPaymentFailedError)) return;

    scrollToElement(ERROR_NOTE_ID);
  }, [payment, isLoading, isFetching]);

  useEffect(() => {
    if (!isValidationError) return;

    logBackendFormValidateError();
  }, [isValidationError]);

  return {
    isPaymentInitError,
    isPaymentFailedError,
    isGeneralError: isError && !isValidationError,
    isValidationError,
    errorId: ERROR_NOTE_ID,
    validationErrorList: validationErrors,
  };
};

export default useGeneralWizardErrorHook;
