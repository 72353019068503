const useScrollToElement = () => {
  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  return {
    scrollToElement,
    scrollToTop,
  };
};

export default useScrollToElement;
