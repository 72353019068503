import React from 'react';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import useThemeProvider from 'src/toolkit/hooks/useThemeProvider';
import { Trans, useTranslation } from 'react-i18next';
import Link from 'src/presentation/components/ui/Link/Link';
import Checkbox from 'src/presentation/components/FormFields/Checkbox';
import useViewport from 'src/toolkit/utils/useViewport';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import TravelDetailsForm from 'src/domain/Wizard/model/TravelDetailsForm';

interface ComponentProps {
  register: UseFormRegister<TravelDetailsForm>;
  errors: FieldErrors<TravelDetailsForm>;
}

const AgreementsBlock = ({ register, errors }: ComponentProps) => {
  const { themeLink } = useThemeProvider();
  const { t } = useTranslation();
  const { isDesktop } = useViewport();

  return (
    <>
      <Checkbox
        name={'terms.termsAndConditionsAgreed'}
        register={register}
        errors={errors.terms?.termsAndConditionsAgreed?.message}
      >
        <Paragraph large={isDesktop}>
          <Trans
            i18nKey="form_field.agreement.terms_and_conditions_to.text"
            components={{
              termsConditions: (
                <Link blank href={t(themeLink.termsConditionsTo)}>
                  Link
                </Link>
              ),
            }}
          />
        </Paragraph>
      </Checkbox>

      <Checkbox
        name={'terms.termsAndConditionsIntermediaryAgreed'}
        register={register}
        errors={errors.terms?.termsAndConditionsIntermediaryAgreed?.message}
      >
        <Paragraph large={isDesktop}>
          <Trans
            i18nKey="form_field.agreement.terms_and_conditions_intermediary.text"
            components={{
              privacyPolicy: (
                <Link blank href={t(themeLink.privacyPolicy)}>
                  Link
                </Link>
              ),
              termsConditions: (
                <Link blank href={t(themeLink.termsConditionsIntermediary)}>
                  Link
                </Link>
              ),
            }}
          />
        </Paragraph>
      </Checkbox>
    </>
  );
};

export default AgreementsBlock;
