import React, { ReactNode } from 'react';
import Header from 'src/presentation/components/ui/Header/Header';
import Input from 'src/presentation/components/FormFields/Input';
import Separator from 'src/presentation/components/ui/Separator/Separator';
import TextArea from 'src/presentation/components/FormFields/TextArea';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import TravelDetailsForm from 'src/domain/Wizard/model/TravelDetailsForm';
import Select from 'src/presentation/components/FormFields/Select';
import RadioButton from 'src/presentation/components/FormFields/RadioButton';
import { useTranslation } from 'react-i18next';
import useFetchNationalities from 'src/integration/api/useFetchNationalities';
import breakpoints from 'src/integration/styles/breakpoints';
import useViewport from 'src/toolkit/utils/useViewport';
import PreexistingMedicalConditions from 'src/presentation/components/CheckoutForm/elements/PreexistingMedicalConditions';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import Symbol from 'src/domain/FormFields/enum/Symbol';

interface ComponentProps extends Component {
  register: UseFormRegister<TravelDetailsForm>;
  errors: FieldErrors<TravelDetailsForm>;
  index: number;
  note?: ReactNode;
}

const Root = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
  `};
`;

const TravellerWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 8px;

    ${breakpoints.mobile`
      border-radius: 10px;
      border: 1px solid ${colors.neutral90};  
      padding: 16px;
    `}
  `};
`;

const SelectWrapper = styled.div`
  ${() => css`
    width: 100%;
    max-width: 130px;

    ${breakpoints.desktop`
      max-width: 145px;  
    `}
  `}
`;

const StyledHeader = styled(Header)`
  ${() => css`
    margin-bottom: 16px;
  `}
`;

const Wrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
  `};
`;

const OneRowBlock = styled.div`
  ${() => css`
    display: flex;
    align-items: flex-start;
    grid-gap: 16px;
  `}
`;

const TravellerInfo = ({ register, errors, index, className, dataTestId, note }: ComponentProps) => {
  const { data: dataNationalitiesList, isLoading: isLoadingNationalities } = useFetchNationalities();
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const titleTypesList = [
    { id: 'Mr', name: t('form_field.title.options.mr') },
    { id: 'Ms', name: t('form_field.title.options.ms') },
  ];

  const options = [
    {
      label: t('form_field.gender.option.female'),
      value: 'female',
    },
    {
      label: t('form_field.gender.option.male'),
      value: 'male',
    },
    {
      label: t('form_field.gender.option.other'),
      value: 'unknown',
    },
  ];

  return (
    <Root className={className} data-testid={dataTestId}>
      {note}
      <TravellerWrapper>
        <StyledHeader level={isMobile ? 'h6' : 'h5'}>
          {t('form.traveler_info.title')} {index + 1}
        </StyledHeader>

        <Wrapper>
          <RadioButton
            label={t('form_field.gender.label')}
            options={options}
            isVertical={isMobile}
            name={`travellers.${index}.gender`}
            register={register}
            errors={errors?.travellers?.[index]?.gender?.message ?? undefined}
          />
          <OneRowBlock>
            <SelectWrapper>
              <Select
                name={`travellers.${index}.title`}
                label={t('form_field.title.label')}
                placeholder={t('form_field.title.placeholder')}
                errors={errors?.travellers?.[index]?.title?.message ?? undefined}
                register={register}
                list={titleTypesList}
                defaultValue={''}
                required
              />
            </SelectWrapper>
            <Input
              type="text"
              name={`travellers.${index}.firstName`}
              label={t('form_field.first_name.label')}
              placeholder={t('form_field.first_name.placeholder')}
              register={register}
              errors={errors?.travellers?.[index]?.firstName?.message ?? undefined}
              required
            />
          </OneRowBlock>
          <Input
            type="text"
            name={`travellers.${index}.middleName`}
            label={t('form_field.middle_name.label')}
            placeholder={t('form_field.middle_name.placeholder')}
            disclaimer={
              <Paragraph>
                {Symbol.Asterisk}
                {t('form_field.middle_name.disclaimer')}
              </Paragraph>
            }
            errors={errors?.travellers?.[index]?.middleName?.message ?? undefined}
            register={register}
          />
          <Input
            type="text"
            name={`travellers.${index}.lastName`}
            label={t('form_field.last_name.label')}
            placeholder={t('form_field.last_name.placeholder')}
            errors={errors?.travellers?.[index]?.lastName?.message ?? undefined}
            register={register}
            required
          />
          <Input
            type="text"
            name={`travellers.${index}.dateOfBirth`}
            label={t('form_field.date_of_birth.label')}
            placeholder={t('form_field.date_of_birth.placeholder')}
            register={register}
            errors={errors?.travellers?.[index]?.dateOfBirth?.message ?? undefined}
            required
          />

          <Select
            name={`travellers.${index}.nationality`}
            label={t('form_field.nationality.label')}
            placeholder={t('form_field.nationality.placeholder')}
            register={register}
            errors={errors?.travellers?.[index]?.nationality?.message ?? undefined}
            list={dataNationalitiesList || []}
            isLoading={isLoadingNationalities}
            defaultValue={''}
            required
          />
        </Wrapper>

        <Separator space={16} />
        <Header level={'h7'}>{t('form_field.passport_number.title')}</Header>

        <Wrapper>
          <Input
            type="text"
            name={`travellers.${index}.passportNumber`}
            label={t('form_field.passport_number.label')}
            placeholder={t('form_field.passport_number.placeholder')}
            errors={errors?.travellers?.[index]?.passportNumber?.message ?? undefined}
            register={register}
            required
          />
          <Input
            type="text"
            name={`travellers.${index}.passportExpirationDate`}
            label={t('form_field.expiration_date.label')}
            placeholder={t('form_field.expiration_date.placeholder')}
            register={register}
            errors={errors?.travellers?.[index]?.passportExpirationDate?.message ?? undefined}
            required
          />
        </Wrapper>

        <Separator space={16} />

        <PreexistingMedicalConditions />

        <TextArea
          name={`travellers.${index}.medicalConditionsDetails`}
          label={t('form_field.preexisting_medical_conditions.label')}
          placeholder={t('form_field.preexisting_medical_conditions.placeholder')}
          register={register}
          errors={errors?.travellers?.[index]?.medicalConditionsDetails?.message ?? undefined}
          required
        />
      </TravellerWrapper>
    </Root>
  );
};

export default TravellerInfo;
