import React from 'react';
import styled, { css } from 'styled-components';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import { imageConfirmationDesktop, imageConfirmationMobile } from 'src/integration/assets/AssetsProvider';
import Layout from 'src/presentation/layouts/Layout';
import breakpoints from 'src/integration/styles/breakpoints';
import useViewport from 'src/toolkit/utils/useViewport';
import ConfirmationDetails from 'src/presentation/components/CofirmationPageDetails/ConfirmationDetails';
import useDateTimeFormatter from 'src/toolkit/utils/useDateTimeFormatter';
import ConfirmationLinks from 'src/presentation/components/CofirmationPageDetails/ConfirmationLinks';
import useFetchCheckoutSummary from 'src/integration/api/useFetchCheckoutSummary';
import ErrorPage from 'src/presentation/pages/ErrorPage';
import routerPaths from 'src/integration/config/routerPaths';
import { useTranslation } from 'react-i18next';
import LoadingSkeletonConfirmation from 'src/presentation/components/LoadingSkeletons/LoadingSkeletonConfirmation';
import useConfirmationPageHook from 'src/toolkit/hooks/useConfirmationPageHook';

const Root = styled.div`
  ${() => css`
    width: 100%;
    min-height: 100vh;
  `}
`;

const IconWrapper = styled.div`
  ${() => css`
    width: 100%;
    height: 164px;

    ${breakpoints.desktop`
      height: unset;      
    `}
  `}
`;

const StyledIcon = styled(Icon)`
  ${() => css`
    width: 100%;
    height: 100%;
  `}
`;

const StyledDetailsLayout = styled(Layout)`
  ${() => css`
    padding: 24px 16px 16px;

    ${breakpoints.mobile`
      margin-bottom: 16px  
    `}

    ${breakpoints.desktop`
      padding: 32px 0 0;
    `}
  `}
`;

const StyledLinkLayout = styled(Layout)`
  ${() => css`
    padding: 0 0 30px;

    ${breakpoints.desktop`
      padding: 32px 0 32px;
    `}
  `}
`;

const Wrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${breakpoints.desktop`
      flex-direction: row;
      gap: 24px;        
    `}
  `}
`;

const ConfirmationPage = () => {
  const { isMobile, isDesktop } = useViewport();
  const { formatDate } = useDateTimeFormatter();
  const { t } = useTranslation();
  const { data, isError, isLoading } = useFetchCheckoutSummary();

  useConfirmationPageHook();

  if (isLoading) {
    return <LoadingSkeletonConfirmation />;
  }

  if (isError) return <ErrorPage buttonText={t('error.page.button.pdp')} buttonLink={routerPaths.home()} />;

  return (
    <Root>
      {isMobile && (
        <IconWrapper>
          <StyledIcon icon={imageConfirmationMobile} />
        </IconWrapper>
      )}
      <StyledDetailsLayout type={'column'}>
        <Wrapper>
          {isDesktop && (
            <IconWrapper>
              <StyledIcon icon={imageConfirmationDesktop} />
            </IconWrapper>
          )}
          <ConfirmationDetails
            bookingId={data?.bookingId}
            title={data?.title}
            startDate={formatDate(data?.startDate)}
            endDate={formatDate(data?.endDate)}
          />
        </Wrapper>
      </StyledDetailsLayout>
      <StyledLinkLayout type={'column'}>
        <ConfirmationLinks />
      </StyledLinkLayout>
    </Root>
  );
};

export default ConfirmationPage;
