import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enEN from 'src/toolkit/i18n/translations/en-EN.json';
import deDE from 'src/toolkit/i18n/translations/de-DE.json';
import Languages from 'src/domain/Settings/enum/Languages';

const resources = {
  en: {
    translation: enEN,
  },
  de: {
    translation: deDE,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: Languages.de,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
