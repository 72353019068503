import React from 'react';
import styled, { css } from 'styled-components';
import RadioOption from 'src/domain/Styles/model/RadioOption';
import Header from 'src/presentation/components/ui/Header/Header';
import ErrorText from 'src/presentation/components/FormFields/ErrorText';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import breakpoints from 'src/integration/styles/breakpoints';
import colors from 'src/integration/styles/colors';

interface ComponentProps<T extends FieldValues> {
  label: string;
  options: RadioOption[];
  isVertical?: boolean;
  name: Path<T>;
  register: UseFormRegister<T>;
  errors?: string;
  disabled?: boolean;
  required?: boolean;
}

const Root = styled.div`
  ${() => css``}
`;

const StyledLabel = styled(Header)`
  ${() => css`
    padding-bottom: 8px;
  `}
`;

const Options = styled.div<{ $isVertical?: boolean }>`
  ${({ $isVertical }) => css`
    display: flex;
    flex-direction: ${$isVertical ? 'column' : 'row'};
    grid-gap: 16px;

    ${breakpoints.desktop`
      grid-gap: 24px;   
    `}
  `}
`;

const Value = styled.span`
  ${({ theme }) => css`
    padding-left: 6px;
    font-family: ${theme.fonts.fontFamily};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `}
`;

const Item = styled.label`
  ${() => css`
    display: flex;
    align-items: center;
  `}
`;

const RadioInput = styled.input<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    flex-shrink: 0;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 20px;
    height: 20px;
    border: 2px solid ${disabled ? colors.neutral80 : colors.neutral50};
    border-radius: 10px;
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 5px;
      transform: translate(-50%, -50%) scale(0);
      background: ${disabled ? colors.neutral80 : theme.colors.primary};
    }

    &:checked::before {
      transform: translate(-50%, -50%) scale(1);
    }

    &:checked {
      border: 2px solid ${disabled ? colors.neutral80 : theme.colors.primary};
    }
  `}
`;

const RadioButton = <T extends FieldValues>({
  options,
  isVertical,
  register,
  name,
  label,
  errors,
  disabled,
}: ComponentProps<T>) => {
  return (
    <Root>
      <StyledLabel level={'h7'}>{label}</StyledLabel>
      <Options $isVertical={isVertical}>
        {options.map((option) => (
          <Item key={option.value}>
            <RadioInput {...register(name)} type="radio" value={option.value} disabled={disabled} />
            <Value>{option.label}</Value>
          </Item>
        ))}
      </Options>
      {errors && <ErrorText error={errors} />}
    </Root>
  );
};

export default RadioButton;
