import React from 'react';
import Layout from 'src/presentation/layouts/Layout';
import useViewport from 'src/toolkit/utils/useViewport';
import SkeletonText from 'src/presentation/components/LoadingSkeletons/elements/SkeletonText';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import SkeletonBasketCheckout from 'src/presentation/components/LoadingSkeletons/elements/SkeletonBasketCheckout';
import SkeletonWhiteBox from 'src/presentation/components/LoadingSkeletons/elements/SkeletonWhiteBox';
import breakpoints from 'src/integration/styles/breakpoints';

const Root = styled.div`
  ${() => css`
    background: ${colors.neutral96};
  `}
`;

const OneRowBlock = styled.div`
  ${() => css`
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    gap: 24px;
  `}
`;

const StyledOneRowBlock = styled(OneRowBlock)`
  ${() => css`
    padding: 24px 16px;

    ${breakpoints.desktop`
      padding: 24px 0;
      margin: 0 auto;        
    `}
  `}
`;

const StyledLayout = styled(Layout)`
  ${() => css`
    padding-bottom: 24px;
  `}
`;

const StyledSkeletonWhiteBox = styled(SkeletonWhiteBox)`
  ${() => css`
    margin-bottom: 24px;
  `}
`;

const LoadingSkeletonCheckout = () => {
  const { isMobile, isDesktop } = useViewport();
  return (
    <Root>
      {isMobile && (
        <>
          <StyledOneRowBlock>
            <SkeletonText width={142} height={32} />
            <SkeletonText width={163} height={32} />
          </StyledOneRowBlock>
          <SkeletonBasketCheckout />
        </>
      )}

      {isDesktop && (
        <>
          <Layout type={'row'}>
            <StyledOneRowBlock>
              <SkeletonText width={48} height={48} />
              <SkeletonText width={156} height={32} />
              <SkeletonText width={180} height={32} />
            </StyledOneRowBlock>
          </Layout>

          <StyledLayout type={'column'} sidebar={<SkeletonBasketCheckout />}>
            <StyledSkeletonWhiteBox hPaddingDesktop={24}>
              <SkeletonText width={259} height={32} marginBottom={16} />
              <SkeletonText width={178} height={24} marginBottom={40} />

              <SkeletonText width={257} height={24} marginBottom={8} />
              <SkeletonText height={16} marginBottom={8} />
              <SkeletonText height={16} marginBottom={20} />

              <SkeletonText width={248} height={24} marginBottom={8} />
              <SkeletonText height={16} marginBottom={8} />
              <SkeletonText height={16} marginBottom={8} />
              <SkeletonText height={16} />
            </StyledSkeletonWhiteBox>

            <SkeletonWhiteBox hPaddingDesktop={24}>
              <SkeletonText width={267} height={32} marginBottom={24} />

              <StyledSkeletonWhiteBox hPaddingDesktop={24} showBorder>
                <SkeletonText width={257} height={24} marginBottom={16} />
                <SkeletonText height={16} marginBottom={16} />
                <SkeletonText height={16} marginBottom={16} />
                <SkeletonText height={16} marginBottom={16} />
                <SkeletonText height={16} marginBottom={16} />
                <SkeletonText height={16} marginBottom={16} />
              </StyledSkeletonWhiteBox>

              <SkeletonWhiteBox hPaddingDesktop={24} showBorder>
                <SkeletonText width={257} height={24} marginBottom={16} />
                <SkeletonText height={16} marginBottom={16} />
                <SkeletonText height={16} marginBottom={16} />
                <SkeletonText height={16} marginBottom={16} />
                <SkeletonText height={16} marginBottom={16} />
                <SkeletonText height={16} marginBottom={16} />
              </SkeletonWhiteBox>
            </SkeletonWhiteBox>
          </StyledLayout>
        </>
      )}
    </Root>
  );
};

export default LoadingSkeletonCheckout;
