import { action, computed, makeObservable, observable } from 'mobx';
import AvailabilityForm from 'src/domain/Package/model/AvailabilityForm';
import SelectFieldType from 'src/domain/Styles/model/SelectFieldType';
import useDateTimeFormatter from 'src/toolkit/utils/useDateTimeFormatter';
const { getFirstDayOfMonth, getLastDayOfMonth, getDefaultDateInAdvanceTo, getDefaultDateInAdvanceFrom } = useDateTimeFormatter();

class PackageStore {
  DEFAULT_ADULTS = 2;
  DEFAULT_CHILDREN = 0;
  MIN_ADULTS = 0;
  MIN_CHILDREN = 0;
  DEFAULT_DATE_FROM = getDefaultDateInAdvanceFrom(2);
  DEFAULT_DATE_TO = getDefaultDateInAdvanceTo(2);

  @observable availabilityForm: AvailabilityForm = {
    adults: this.DEFAULT_ADULTS,
    children: this.DEFAULT_CHILDREN,
    dateFrom: this.DEFAULT_DATE_FROM,
    dateTo: this.DEFAULT_DATE_TO,
  };

  @action setAdultsCount = (amount: number) => {
    this.availabilityForm.adults = amount;
  };

  @action setChildrenCount = (amount: number) => {
    this.availabilityForm.children = amount;
  };

  @action setSelectedDate = (month: SelectFieldType) => {
    this.availabilityForm.dateFrom = new Date(getFirstDayOfMonth(month.id));
    this.availabilityForm.dateTo = new Date(getLastDayOfMonth(month.id));
  };

  @computed
  get amountOfTravellers() {
    return this.availabilityForm.adults + this.availabilityForm.children;
  }

  constructor() {
    makeObservable(this);
  }
}

export default PackageStore;
