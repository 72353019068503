import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import SectionName from 'src/presentation/components/Itinerary/elements/SectionName';
import DetailsWhiteBox from 'src/presentation/components/Itinerary/elements/DetailsWhiteBox';
import { Accommodation } from 'src/domain/Schema/model/apiTypes';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  accommodations?: Accommodation[];
}

const Root = styled.div`
  ${({ theme }) => css`
    color: ${colors.neutral50};
    font-family: ${theme.fonts.fontFamily};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  `}
`;

const StyledSectionName = styled(SectionName)`
  ${() => css`
    margin-bottom: 8px;
  `}
`;

const Content = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
  `}
`;

const Accommodations = ({ accommodations }: ComponentProps) => {
  const { t } = useTranslation();

  return (
    <Root>
      <StyledSectionName>{t('itinerary.accommodation.title')}</StyledSectionName>
      <Content>
        {accommodations?.map((item, index) => (
          <DetailsWhiteBox key={`activities-${index}`} title={item.name}>
            {!!item.mealsIncluded?.length && (
              <>
                {t('itinerary.accommodation.included_meals')} {item.mealsIncluded.join(', ')}
              </>
            )}
          </DetailsWhiteBox>
        ))}
      </Content>
    </Root>
  );
};

export default Accommodations;
