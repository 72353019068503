import ThemeStyle from 'src/domain/Settings/model/ThemeStyle';
import ThemeNames from 'src/domain/Settings/enum/ThemeNames';

const defaultTheme: ThemeStyle = {
  colors: {
    primary: 'rgba(13, 110, 253, 1)',
    primaryContainerOn: 'rgba(5, 44, 101, 1)',
    secondary: 'rgba(86, 103, 118, 1)',
    secondaryContainerOn: 'rgba(32, 39, 44, 1)',
    secondaryContainer: 'rgba(211, 217, 223, 1)',
  },
  fonts: {
    fontFamily: 'Inter, sans-serif',
  },
  footer: {
    desktopHeight: 0,
    mobileHeight: 0,
  },
  header: {
    height: 0
  }
};

const explorerTheme: ThemeStyle = {
  colors: {
    primary: '#008560',
    primaryContainerOn: '#024F39',
    secondary: '#D24701',
    secondaryContainerOn: '#652201',
    secondaryContainer: '#FFDDCC',
  },
  fonts: {
    fontFamily: 'Nunito, sans-serif',
  },
  footer: {
    desktopHeight: 212,
    mobileHeight: 300,
  },
  header: {
    height: 90
  }
};

const staTravelTheme: ThemeStyle = {
  colors: {
    primary: '#0065BD',
    primaryContainerOn: '#173E6D',
    secondary: '#0065BD',
    secondaryContainerOn: '#173E6D',
    secondaryContainer: '#D5E5F4',
  },
  fonts: {
    fontFamily: 'Titillium Web, sans-serif',
  },
  footer: {
    desktopHeight: 117,
    mobileHeight: 158,
  },
  header: {
    height: 90
  }
};

export const themeStyleMapping: Record<ThemeNames, ThemeStyle> = {
  [ThemeNames.themeExplorer]: explorerTheme,
  [ThemeNames.themeStaTravel]: staTravelTheme,
  [ThemeNames.defaultTheme]: defaultTheme,
};
