import PreviewAndPaymentStep from 'src/presentation/wizard/steps/PreviewAndPaymentStep';
import TravelDetailsStep from 'src/presentation/wizard/steps/TravellerDetailsStep';
import StepMap from 'src/domain/Wizard/enum/stepMap';
import WizardSteps from 'src/domain/Wizard/model/WizardSteps';
import StepName from 'src/domain/Wizard/enum/StepName';

const wizardSteps: WizardSteps[] = [
  {
    id: StepMap.TRAVELLER_INFO_STEP,
    stepName: StepName.travelerDetails,
    title: 'wizard.navigation.step.traveler_details',
    component: TravelDetailsStep,
    transitionForward: () => null,
    temporarySaving: false,
    previous: null,
    next: StepMap.PREVIEW_AND_PAYMENT_STEP,
  },
  {
    id: StepMap.PREVIEW_AND_PAYMENT_STEP,
    stepName: StepName.payment,
    title: 'wizard.navigation.step.review_payment',
    component: PreviewAndPaymentStep,
    transitionForward: () => null,
    temporarySaving: false,
    previous: StepMap.TRAVELLER_INFO_STEP,
    next: null,
  },
];

export default wizardSteps;
