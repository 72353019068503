import React from 'react';
import styled, { css } from 'styled-components';
import { iconArrowDown } from 'src/integration/assets/AssetsProvider';
import Icon from 'src/presentation/components/ui/Icon/Icon';

interface ComponentProps extends Component {
  size: number;
  isOpen: boolean;
}

const Root = styled(Icon)<{ $isOpen: boolean }>`
  ${({ $isOpen }) => css`
    transform: scaleY(1);

    ${$isOpen &&
    css`
      transform: scaleY(-1);
    `}
  `}
`;

const IconArrowDown = ({ className, size, isOpen }: ComponentProps) => (
  <Root $isOpen={isOpen} icon={iconArrowDown} size={size} className={className} />
);

export default IconArrowDown;
