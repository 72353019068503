import {
  APP_CONTAINER_NAME,
  APP_DATA_ATTR_INQUIRY_BRAND,
  APP_DATA_ATTR_INQUIRY_CATEGORY,
  APP_DATA_ATTR_INQUIRY_ID,
  APP_DATA_ATTR_INQUIRY_NAME,
  APP_DATA_ATTR_INQUIRY_PRICE,
  APP_DATA_ATTR_INQUIRY_PROVIDERS,
  APP_DATA_ATTR_INQUIRY_THUMBNAIL,
  APP_DATA_ATTR_THEME,
  APP_TRIP_PACKAGE_ID,
} from 'src/domain/Settings/variables/variables';
import useQueryParams from 'src/toolkit/hooks/useQueryParams';
import useEnvData from 'src/toolkit/utils/useEnvData';

const useAppVariablesProvider = () => {
  const { isTestingEnv } = useEnvData();

  const rootElement = document.getElementById(APP_CONTAINER_NAME);
  const packageId = rootElement?.getAttribute(APP_TRIP_PACKAGE_ID) || '';
  const themeName = rootElement?.getAttribute(APP_DATA_ATTR_THEME) || '';

  const inquiryId = rootElement?.getAttribute(APP_DATA_ATTR_INQUIRY_ID);
  const inquiryCategory = rootElement?.getAttribute(APP_DATA_ATTR_INQUIRY_CATEGORY);
  const inquiryName = rootElement?.getAttribute(APP_DATA_ATTR_INQUIRY_NAME);
  const inquiryBrand = rootElement?.getAttribute(APP_DATA_ATTR_INQUIRY_BRAND);
  const inquiryPrice = rootElement?.getAttribute(APP_DATA_ATTR_INQUIRY_PRICE);
  const inquiryThumbnail = rootElement?.getAttribute(APP_DATA_ATTR_INQUIRY_THUMBNAIL);
  const inquiryProviders = rootElement?.getAttribute(APP_DATA_ATTR_INQUIRY_PROVIDERS);

  const getPackageId = () => {
    if (isTestingEnv) {
      const { packageId: packageIdFromQuery } = useQueryParams();

      return packageIdFromQuery || packageId || '24212';
    }

    return packageId;
  };

  return {
    packageId: getPackageId(),
    themeName,
    inquiryId,
    inquiryCategory,
    inquiryName,
    inquiryBrand,
    inquiryThumbnail,
    inquiryProviders,
    inquiryPrice,
  };
};

export default useAppVariablesProvider;
