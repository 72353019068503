import React from 'react';
import styled, { css } from 'styled-components';
import Button from 'src/presentation/components/ui/Button/Button';
import ContactDetailsBlock from 'src/presentation/components/CheckoutForm/ContactDetailsBlock';
import useTravellerDetailsTransition from 'src/presentation/wizard/transitions/useTravellerDetailsTransition';
import TravellersInformationBlock from 'src/presentation/components/CheckoutForm/TravellersInformationBlock';
import breakpoints from 'src/integration/styles/breakpoints';
import useQueryParams from 'src/toolkit/hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import GeneralWizardErrorsSection from 'src/presentation/components/widgets/GeneralWizardErrorsSection';
import AgreementsBlock from 'src/presentation/components/CheckoutForm/AgreementsBlock';
import useEnvData from 'src/toolkit/utils/useEnvData';

const Root = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
  `}
`;

const Form = styled.form`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
  `}
`;

const StyledButton = styled(Button)`
  ${() => css`
    ${breakpoints.mobile`
      margin: 0 16px;  
    `}
  `}
`;

const Wrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${breakpoints.mobile`
      padding: 16px 16px 0;  
    `}

    ${breakpoints.desktop`
      gap: 24px;  
    `}
  `}
`;

const StyledPrefillButton = styled(Button)`
  ${() => css`
    align-self: end;
  `}
`;

const TravelDetailsStep = () => {
  const { transition, prefillWithRandomData, register, errors, isFetching } = useTravellerDetailsTransition();
  const { travelers } = useQueryParams();
  const { isTestingEnv } = useEnvData();
  const { t } = useTranslation();

  return (
    <Root>
      <GeneralWizardErrorsSection />

      {isTestingEnv && (
        <StyledPrefillButton styleType={'link'} onClick={() => prefillWithRandomData(parseInt(travelers))}>
          Test button: Prefill with random Data
        </StyledPrefillButton>
      )}

      <Form>
        <ContactDetailsBlock register={register} errors={errors} />
        <TravellersInformationBlock register={register} errors={errors} travellerCount={parseInt(travelers)} />
        <Wrapper>
          <AgreementsBlock register={register} errors={errors} />
          <Button styleType={'primary'} onClick={() => transition(true)} disabled={isFetching} isLoading={isFetching}>
            {t('form.button.continue_to_review_payment.text')}
          </Button>
        </Wrapper>

        {isTestingEnv && (
          <StyledButton styleType={'link'} onClick={() => transition(false)} disabled={isFetching}>
            Test button: skip validation
          </StyledButton>
        )}
      </Form>
    </Root>
  );
};

export default observer(TravelDetailsStep);
