const usePercentFormatter = () => {
  const formatPercent = (number?: number, isNegative?: boolean) => {
    if (isNegative) {
      return `-${number}%`;
    }
    return `${number}%`;
  };

  return {
    formatPercent,
  };
};

export default usePercentFormatter;
