import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import useExtendableContent from 'src/toolkit/hooks/useExtendableContent';
import IconArrowDown from 'src/presentation/components/ui/Icon/IconArrowDown';
import { useTranslation } from 'react-i18next';

const AdditionalInfo = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${colors.neutral90};
    padding: 16px;
  `}
`;

const ExpandableContent = styled.ul<{ $isOpen: boolean }>`
  ${({ $isOpen }) => css`
    list-style-type: inherit;
    padding-left: 20px;
    max-height: 0;
    overflow: hidden;

    ${$isOpen &&
    css`
      max-height: 500px;
      padding-top: 10px;
    `}
  `}
`;

const ListItem = styled.li`
  ${() => css``}
`;

const MainContent = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
    grid-gap: 16px;
    cursor: pointer;
  `}
`;

const Column = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  `}
`;

const AdditionalInfoBlock = () => {
  const { isOpen, onClick } = useExtendableContent();
  const { t } = useTranslation();

  return (
    <AdditionalInfo>
      <MainContent onClick={onClick}>
        <Column>
          <Paragraph large semiBold>
            {t('disclaimers.title')}
          </Paragraph>
          <Paragraph large color={'neutral30'}>
            {t('disclaimers.subtitle')}
          </Paragraph>
        </Column>
        <IconArrowDown size={36} isOpen={isOpen} />
      </MainContent>

      <ExpandableContent $isOpen={isOpen}>
        <ListItem>
          <Paragraph>{t('disclaimers.item_1')}</Paragraph>
        </ListItem>
        <ListItem>
          <Paragraph>{t('disclaimers.item_2')}</Paragraph>
        </ListItem>
        <ListItem>
          <Paragraph>{t('disclaimers.item_3')}</Paragraph>
        </ListItem>
      </ExpandableContent>
    </AdditionalInfo>
  );
};

export default AdditionalInfoBlock;
