import React from 'react';
import styled, { css } from 'styled-components';
import { ItineraryDay } from 'src/domain/Schema/model/apiTypes';
import colors from 'src/integration/styles/colors';
import Header from 'src/presentation/components/ui/Header/Header';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import Activities from 'src/presentation/components/Itinerary/elements/Activities';
import Accommodations from 'src/presentation/components/Itinerary/elements/Accommodations';
import TransportSection from 'src/presentation/components/Itinerary/elements/TransportSection';
import breakpoints from 'src/integration/styles/breakpoints';
import useViewport from 'src/toolkit/utils/useViewport';
import IconArrowDown from 'src/presentation/components/ui/Icon/IconArrowDown';
import { useTranslation } from 'react-i18next';
import useExtendableContent from 'src/toolkit/hooks/useExtendableContent';

interface ComponentProps {
  itineraryDay: ItineraryDay;
}

const Root = styled.div`
  width: 100%;
`;

const Day = styled.div<{ $isOpen: boolean }>`
  ${({ theme, $isOpen }) => css`
    box-sizing: border-box;
    border-radius: 10px;
    border-left: 1px solid ${colors.neutral80};
    background: ${colors.white};
    padding: 8px;
    width: 100%;

    ${breakpoints.desktop`
      padding: 8px 16px; 
    `}

    ${$isOpen &&
    css`
      border-left: 1px solid ${theme.colors.primary};
    `}
  `}
`;

const TopRow = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const DetailsBlock = styled.div<{ $isOpen: boolean }>`
  ${({ $isOpen }) => css`
    max-height: 0;
    overflow: hidden;

    ${$isOpen &&
    css`
      display: flex;
      flex-direction: column;
      grid-gap: 16px;
      max-height: fit-content;
      padding-top: calc(8px + 16px);

      ${breakpoints.desktop`
        grid-gap: 24px;
      `}
    `}
  `}
`;

const MainBlock = styled.div<{ $isExpandable: boolean }>`
  ${({ $isExpandable }) => css`
    width: 100%;
    cursor: ${$isExpandable ? 'pointer' : 'default'};
  `}
`;

const StyledParagraph = styled(Paragraph)<{ isOpen: boolean }>`
  ${({ isOpen }) => css`
    ${!isOpen &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      ${breakpoints.desktop`
        max-width: 583px;
      `}
    `}
  `}
`;

const ItineraryItem = ({ itineraryDay }: ComponentProps) => {
  const { isOpen, onClick } = useExtendableContent();
  const { isMobile } = useViewport();
  const { t } = useTranslation();

  const isExpandable =
    !!itineraryDay.activities?.length || !!itineraryDay.accommodations?.length || !!itineraryDay.transports?.length;

  return (
    <Root>
      <Day $isOpen={isOpen && isExpandable}>
        <MainBlock onClick={onClick} $isExpandable={isExpandable}>
          <TopRow>
            <Header level={isMobile ? 'h7' : 'h5'}>
              {t('itinerary.day.title', { dayNumber: itineraryDay.dayNumber, location: itineraryDay.locationText })}
            </Header>
            {isExpandable && <IconArrowDown size={36} isOpen={isOpen} />}
          </TopRow>
          <StyledParagraph large isOpen={isOpen}>
            {itineraryDay.daySummary}
          </StyledParagraph>
        </MainBlock>

        {isExpandable && (
          <DetailsBlock $isOpen={isOpen}>
            {!!itineraryDay.activities?.length && <Activities activities={itineraryDay?.activities} />}
            {!!itineraryDay.accommodations?.length && <Accommodations accommodations={itineraryDay.accommodations} />}
            {!!itineraryDay.transports?.length && <TransportSection transports={itineraryDay.transports} />}
          </DetailsBlock>
        )}
      </Day>
    </Root>
  );
};

export default ItineraryItem;
