import useReactQuery from 'src/integration/api/useReactQuery';
import apiConfig from 'src/integration/config/apiConfig';
import { Country } from 'src/domain/Schema/model/apiTypes';
import ErrorType from 'src/domain/Schema/model/ErrorType';

const useFetchCountries = () => {
  return useReactQuery<null, ErrorType, Country[]>({
    endpoint: apiConfig.api.endpoints.countries,
    queryKey: 'countries',
    method: 'GET',
    opts: {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });
};

export default useFetchCountries;
