import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  children: ReactNode;
  optional?: boolean;
  showAsterisk?: boolean;
}

const Root = styled.label`
  ${({ theme }) => css`
    position: absolute;
    top: -10px;
    left: 28px;
    color: ${colors.neutral10};
    font-family: ${theme.fonts.fontFamily};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding: 0 2px;
    background: ${colors.white};
  `}
`;

const Optional = styled.span`
  ${() => css`
    color: ${colors.neutral50};
  `}
`;

const Label = ({ children, optional, showAsterisk }: ComponentProps) => {
  const { t } = useTranslation();

  return (
    <Root>
      {children}
      {optional && (
        <Optional>
          &nbsp;
          {showAsterisk ? t('form_field.general.optional_with_asterisk.text') : t('form_field.general.optional.text')}
        </Optional>
      )}
    </Root>
  );
};

export default Label;
