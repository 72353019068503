import React from 'react';
import * as Sentry from '@sentry/react';
import GlobalStyle from 'src/integration/styles/globalStyle';
import { RouterProvider } from 'react-router-dom';
import router from 'src/integration/config/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import queryClientConfig from 'src/integration/config/queryClientConfig';
import { ThemeProvider } from 'styled-components';
import useThemeProvider from 'src/toolkit/hooks/useThemeProvider';
import LanguageSwitcher from 'src/presentation/components/widgets/LanguageSwitcher';
import useEnvData from 'src/toolkit/utils/useEnvData';
import useInitializeEventLogger from 'src/toolkit/hooks/useInitializeEventLogger';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  allowUrls: [/localhost/],
  denyUrls: [],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_VERSION,
});

function App() {
  const { getActiveTheme, themeLink } = useThemeProvider();
  const { isTestingEnv } = useEnvData();
  const queryClient = new QueryClient(queryClientConfig);

  useInitializeEventLogger(themeLink.loggerTagId);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={getActiveTheme()}>
        <GlobalStyle />
        {isTestingEnv && <LanguageSwitcher />}

        <RouterProvider router={router} />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
