import React from 'react';
import styled, { css } from 'styled-components';
import WhiteBox from 'src/presentation/components/ui/WhiteBox/WhiteBox';
import { ItineraryDay } from 'src/domain/Schema/model/apiTypes';
import ItineraryItem from 'src/presentation/components/Itinerary/elements/ItineraryItem';
import breakpoints from 'src/integration/styles/breakpoints';
import Map from 'src/presentation/components/Map/Map';
import useViewport from 'src/toolkit/utils/useViewport';
import { useTranslation } from 'react-i18next';

interface ComponentProps extends Component {
  itineraryDays?: ItineraryDay[];
  mapMobileUrl?: string;
}

const Content = styled.div<{ $isMapVisible: boolean }>`
  ${({ $isMapVisible }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    ${$isMapVisible &&
    css`
      ${breakpoints.mobile`
          margin-top: 16px;
      `}
    `}

    ${breakpoints.desktop`
      gap: 24px;
    `}
  `};
`;

const Itinerary = ({ itineraryDays, id, mapMobileUrl }: ComponentProps) => {
  const { isMobile, isDesktop } = useViewport();
  const { t } = useTranslation();

  return (
    <WhiteBox
      id={id}
      title={t('form.itinerary.title')}
      headerLevel={isMobile ? 'h6' : 'h3'}
      bHeaderMargin={isDesktop ? 24 : 16}
    >
      {mapMobileUrl && isMobile && <Map imageUrl={mapMobileUrl} />}
      <Content $isMapVisible={!!mapMobileUrl}>
        {itineraryDays?.map((day) => <ItineraryItem key={`day-${day.dayNumber}`} itineraryDay={day} />)}
      </Content>
    </WhiteBox>
  );
};

export default Itinerary;
