import React from 'react';
import styled, { css } from 'styled-components';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import Counter from 'src/presentation/components/ui/Counter/Counter';
interface ComponentProps {
  label: string;
  description: string;
  disabled?: boolean;
  max: number;
  min?: number;
  defaultValue?: number;
  onAmountChange: (value: number) => void;
}

const Root = styled.div`
  ${() => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  `}
`;

const DescriptionBlock = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
  `}
`;

const TravelerAmountCounter = ({ label, description, max, min, defaultValue, onAmountChange }: ComponentProps) => {
  return (
    <Root>
      <DescriptionBlock>
        <Paragraph large semiBold>
          {label}
        </Paragraph>
        <Paragraph>{description}</Paragraph>
      </DescriptionBlock>
      <Counter max={max} min={min} defaultValue={defaultValue} onCountChange={onAmountChange} />
    </Root>
  );
};

export default TravelerAmountCounter;
