import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';

interface ComponentProps extends Component {
  children: string;
}

const Root = styled.h5`
  ${({ theme }) => css`
    color: ${colors.neutral50};
    font-family: ${theme.fonts.fontFamily};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  `}
`;

const SectionName = ({ children, className }: ComponentProps) => {
  return <Root className={className}>{children}</Root>;
};

export default SectionName;
