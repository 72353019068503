import { useEffect } from 'react';
import { useStore } from 'src/integration/store/store';
import useFetchPackageDetails from 'src/integration/api/useFetchPackageDetails';
import useFetchCheckoutInit from 'src/integration/api/useFetchCheckoutInit';
import useFetchCheckoutDetails from 'src/integration/api/useFetchCheckoutDetails';
import useFetchCountries from 'src/integration/api/useFetchCountries';
import useFetchNationalities from 'src/integration/api/useFetchNationalities';
import useWizardHelper from 'src/toolkit/hooks/useWizardHelper';
import useWizardSessionStorage from 'src/toolkit/hooks/useWizardSessionStorage';

const useCheckoutPageHook = () => {
  const { wizardStore } = useStore();
  const { resetValidationErrors, resetStep, prefillFormData } = wizardStore;
  const { travelerDataFromStorageData, cardIdFromStorage } = useWizardSessionStorage();
  const { convertToTravellerDetails } = useWizardHelper();

  const { data: dataPackageDetails, isLoading: isLoadingDetails, isError: isErrorDetails } = useFetchPackageDetails();
  const { isLoading: isLoadingInit, isError: isErrorInit } = useFetchCheckoutInit();
  const { data: dataCheckoutDetails, isError: isErrorCheckoutDetails } = useFetchCheckoutDetails();
  const { isLoading: isLoadingNationalities, isError: isErrorCountries } = useFetchCountries();
  const { isLoading: isLoadingCountries, isError: isErrorNationalities } = useFetchNationalities();

  useEffect(() => {
    resetValidationErrors();
    resetStep();
  }, []);

  useEffect(() => {
    if (!travelerDataFromStorageData) return;

    prefillFormData(convertToTravellerDetails(travelerDataFromStorageData));
  }, [travelerDataFromStorageData, cardIdFromStorage]);

  return {
    //TODO: Update isLoadingCheckoutDetails
    isLoading: isLoadingDetails || isLoadingInit || isLoadingNationalities || isLoadingCountries,
    isError: isErrorInit || isErrorDetails || isErrorCountries || isErrorNationalities || isErrorCheckoutDetails,
    dataPackageDetails,
    dataCheckoutDetails,
  };
};

export default useCheckoutPageHook;
