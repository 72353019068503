import React, { createElement, FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

interface ComponentProps extends Component {
  icon: FunctionComponent<SimpleObject>;
  size?: number;
}

const Root = styled(({ className, icon, size }: ComponentProps) => createElement(icon, { className, size }))`
  ${({ size }) => css`
    flex-shrink: 0;
    width: ${size ? size : '20'}px;
    min-width: ${size ? size : '20'}px;
    height: ${size ? size : '20'}px;
  `}
`;

const Icon = ({ icon, className, size }: ComponentProps) => <Root icon={icon} size={size} className={className} />;

export default Icon;
