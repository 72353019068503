import React from 'react';
import styled, { css } from 'styled-components';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import { iconArrowBack } from 'src/integration/assets/AssetsProvider';

const Root = styled(Icon)`
  ${() => css`
    height: 48px;
    width: 48px;
  `}
`;

const ArrowBack = () => {
  return <Root icon={iconArrowBack} />;
};

export default ArrowBack;
