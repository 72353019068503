import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import { iconCheck } from 'src/integration/assets/AssetsProvider';

interface ComponentProps {
  number: number;
  disabled: boolean;
  approved: boolean;
}

const Root = styled.div<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    font-family: ${theme.fonts.fontFamily};
    background: ${theme.colors.primary};
    color: ${colors.white};
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    flex-shrink: 0;

    ${disabled &&
    css`
      background: ${colors.neutral80};
      color: ${colors.neutral30};
    `}
  `}
`;

const NumberCircle = ({ number, disabled, approved }: ComponentProps) => {
  return <Root disabled={disabled}>{approved ? <Icon icon={iconCheck} /> : number}</Root>;
};

export default NumberCircle;
