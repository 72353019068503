import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';

interface ComponentProps extends Component {
  children: ReactNode;
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7';
}

const Root = styled.h1`
  ${({ theme }) => css`
    color: ${colors.neutral10};
    font-family: ${theme.fonts.fontFamily};
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
  `}
`;

const Header7 = styled.p`
  ${({ theme }) => css`
    color: ${colors.neutral10};
    font-family: ${theme.fonts.fontFamily};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  `}
`;

const Header6 = styled.h6`
  ${({ theme }) => css`
    color: ${colors.neutral10};
    font-family: ${theme.fonts.fontFamily};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
  `}
`;

const Header5 = styled.h5`
  ${({ theme }) => css`
    color: ${colors.neutral10};
    font-family: ${theme.fonts.fontFamily};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    letter-spacing: 0.15px;
  `}
`;

const Header4 = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily};
    color: ${colors.neutral10};
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  `}
`;

const Header3 = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily};
    color: ${colors.neutral10};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  `}
`;

const Header2 = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily};
    color: ${colors.neutral10};
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.28px;
  `}
`;

const Header = ({ children, level, className }: ComponentProps) => {
  if (level === 'h7') {
    return <Header7 className={className}>{children}</Header7>;
  }

  if (level === 'h6') {
    return <Header6 className={className}>{children}</Header6>;
  }

  if (level === 'h5') {
    return <Header5 className={className}>{children}</Header5>;
  }

  if (level === 'h4') {
    return <Header4 className={className}>{children}</Header4>;
  }

  if (level === 'h3') {
    return <Header3 className={className}>{children}</Header3>;
  }

  if (level === 'h2') {
    return <Header2 className={className}>{children}</Header2>;
  }

  return <Root className={className}>{children}</Root>;
};

export default Header;
