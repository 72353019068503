import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import Button from 'src/presentation/components/ui/Button/Button';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import AdditionalInfoBlock from 'src/presentation/components/AvailabilityComponent/elements/AdditionalInfoBlock';
import useDateTimeFormatter from 'src/toolkit/utils/useDateTimeFormatter';
import breakpoints from 'src/integration/styles/breakpoints';
import useViewport from 'src/toolkit/utils/useViewport';
import { useTranslation } from 'react-i18next';
import PlaceCount from 'src/presentation/components/AvailabilityComponent/elements/PlaceCount';
import usePackageAvailability from 'src/toolkit/hooks/usePackageAvailability';
import DiscountPrice from 'src/presentation/components/AvailabilityComponent/elements/DiscountPrice';

interface ComponentProps {
  isAvailable?: boolean;
  isDiscountAvailable?: boolean;
  isAdditionalInfoEnabled?: boolean;
  placeCount: number;
  startDate?: string;
  endDate?: string;
  price?: string;
  onClick: () => void;
  discountPrice?: string;
  discount?: string;
}

const Root = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid ${colors.neutral90};

    ${disabled &&
    css`
      background: ${colors.neutral96};
    `}
  `}
`;

const Package = styled.div<{ disabled?: boolean }>`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
    align-self: stretch;
    justify-content: space-between;
    padding: 16px;

    ${breakpoints.desktop`
        grid-gap: 24px;
    `}
  `}
`;

const Column = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
  `}
`;

const ColumnCenter = styled(Column)`
  ${() => css`
    align-items: center;
  `}
`;

const StyledPlaceCount = styled(PlaceCount)`
  ${() => css`
    margin-top: 10px;
  `}
`;

const ParagraphWrapper = styled.div``;

const StyledParagraph = styled(Paragraph)<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    ${breakpoints.mobile`
      max-width: 90px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}

    ${disabled &&
    css`
      color: ${colors.neutral50};
    `}
  `}
`;

const CustomStyledParagraph = styled(StyledParagraph)`
  ${() => css`
    line-height: 16px;

    ${breakpoints.desktop`
      line-height: 18px;
    `}
  `}
`;

const StyledButton = styled(Button)`
  ${() => css`
    ${breakpoints.mobile`
      width: 100%;
    `}
  `}
`;

const Row = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`;

const PriceWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 6px;
  `}
`;

const Price = styled.p<{ disabled?: boolean; $isDiscountAvailable?: boolean }>`
  ${({ theme, disabled, $isDiscountAvailable }) => css`
    font-family: ${theme.fonts.fontFamily};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;

    ${$isDiscountAvailable &&
    css`
      color: ${colors.error};
    `}

    ${disabled &&
    css`
      color: ${colors.neutral50};
    `}

    ${breakpoints.desktop`
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.28px;
    `}
  `}
`;

const PackageAvailability = ({
  isAvailable = false,
  isAdditionalInfoEnabled,
  isDiscountAvailable,
  startDate,
  endDate,
  price,
  onClick,
  placeCount,
  discountPrice,
  discount,
}: ComponentProps) => {
  const { getWeekday, formatDate, formatDateCompact } = useDateTimeFormatter();
  const { isMobile, isDesktop } = useViewport();
  const { t } = useTranslation();
  const { getColorByPlaceCount } = usePackageAvailability();

  const renderButton = () => {
    return (
      <StyledButton small={isMobile} disabled={!isAvailable} styleType={'primary'} onClick={onClick}>
        {isAvailable ? t('availability_form.button.choose') : t('availability_form.button.sold_out')}
      </StyledButton>
    );
  };

  return (
    <Root disabled={!isAvailable}>
      <Package>
        <Row>
          <ParagraphWrapper>
            <StyledParagraph small={isMobile} disabled={!isAvailable}>
              {getWeekday(startDate)}
            </StyledParagraph>
            <CustomStyledParagraph large={isDesktop} semiBold disabled={!isAvailable}>
              {isMobile ? formatDateCompact(startDate) : formatDate(startDate)}
            </CustomStyledParagraph>

            <StyledParagraph small={isMobile} disabled={!isAvailable}>
              {getWeekday(endDate)}
            </StyledParagraph>
            <CustomStyledParagraph large={isDesktop} semiBold disabled={!isAvailable}>
              {isMobile ? formatDateCompact(endDate) : formatDate(endDate)}
            </CustomStyledParagraph>
          </ParagraphWrapper>

          {isMobile && isAvailable && (
            <StyledPlaceCount count={placeCount} errorColor={getColorByPlaceCount(placeCount)} />
          )}

          <Column>
            {isDiscountAvailable && <DiscountPrice price={discountPrice} discount={discount} />}

            <PriceWrapper>
              <StyledParagraph small={isMobile} disabled={!isAvailable}>
                {t('availability_form.price.from')}
              </StyledParagraph>
              <Price disabled={!isAvailable} $isDiscountAvailable={isDiscountAvailable}>
                {price}
              </Price>
            </PriceWrapper>

            <StyledParagraph small={isMobile} disabled={!isAvailable}>
              {t('availability_form.price.per_person')}
            </StyledParagraph>
          </Column>
          {isDesktop && (
            <ColumnCenter>
              {renderButton()}
              {isAvailable && <StyledPlaceCount count={placeCount} errorColor={getColorByPlaceCount(placeCount)} />}
            </ColumnCenter>
          )}
        </Row>
        {isMobile && renderButton()}
      </Package>

      {isAdditionalInfoEnabled && <AdditionalInfoBlock />}
    </Root>
  );
};

export default PackageAvailability;
