import { action, computed, makeObservable, observable } from 'mobx';
import TravelDetailsForm from 'src/domain/Wizard/model/TravelDetailsForm';
import TravellerFormValues from 'src/domain/Wizard/model/TravellerFormValues';
import ViolationsError from 'src/domain/Wizard/model/ViolationsError';
import {
  generateRandomEmail,
  generateRandomNumberString,
  generateRandomString,
} from 'src/toolkit/utils/useFakerLibrary';

class WizardStore {
  INITIAL_STEP = 0;

  @observable step = this.INITIAL_STEP;

  @observable submitted = false;

  @observable validationErrors: ViolationsError[] = [];

  @observable isValidationPass = false;

  @observable travelDetails: TravelDetailsForm = {
    address: '',
    city: '',
    country: '',
    email: '',
    phoneNumber: '',
    zipCode: '',
    travellers: [],
    terms: {
      termsAndConditionsAgreed: false,
      termsAndConditionsIntermediaryAgreed: false,
    },
  };

  @computed
  get isValidationError() {
    return this.validationErrors?.length > 0;
  }

  @action
  prefillFormData = (details: TravelDetailsForm) => {
    this.travelDetails = details;
  };

  @action
  updateCheckoutData = (data: Partial<TravelDetailsForm>) => {
    this.travelDetails = { ...this.travelDetails, ...data };
  };

  @action
  setStep = (step: number) => {
    this.step = step;
  };

  @action
  resetStep = () => {
    this.step = this.INITIAL_STEP;
  };

  @action
  setSubmitted = (submitted: boolean) => {
    this.submitted = submitted;
  };

  @action
  setValidationPass = (isValidationPass: boolean) => {
    this.isValidationPass = isValidationPass;
  };

  @action
  setValidationErrors = (errors: ViolationsError[]) => {
    this.validationErrors = errors;
  };

  @action
  resetValidationErrors = () => {
    this.validationErrors = [];
    this.isValidationPass = false;
  };

  @action
  prefillWithRandomData = (travelerCount: number): TravelDetailsForm => {
    const travellers: TravellerFormValues[] = Array.from({ length: travelerCount }, () => ({
      gender: 'female',
      title: 'Mr',
      firstName: generateRandomString(8),
      middleName: generateRandomString(8),
      lastName: generateRandomString(10),
      dateOfBirth: '12.12.2000',
      nationality: '65',
      passportNumber: '123',
      passportExpirationDate: '12.12.2030',
      medicalConditionsDetails: '',
    }));

    return (this.travelDetails = {
      address: 'Gedimino pr.',
      city: 'Vilnius',
      country: 'DE',
      email: generateRandomEmail(),
      phoneNumber: generateRandomNumberString(8),
      zipCode: '123',
      travellers: travellers,
      terms: {
        termsAndConditionsAgreed: true,
        termsAndConditionsIntermediaryAgreed: true,
      },
    });
  };

  constructor() {
    makeObservable(this);
  }
}

export default WizardStore;
