import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import React, { ReactNode } from 'react';

interface ComponentProps extends Component {
  children: ReactNode;
}

const Root = styled.span`
  ${({ theme }) => css`
    padding: 4px 16px;
    border-radius: 26px;
    background: ${colors.warning};
    font-family: ${theme.fonts.fontFamily};
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
  `}
`;

const Badge = ({ children, className }: ComponentProps) => {
  return <Root className={className}>{children}</Root>;
};

export default Badge;
