import { useMemo } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import TravelDetailsForm from 'src/domain/Wizard/model/TravelDetailsForm';
import { ObjectSchema } from 'yup';
import { Gender, TravelerTitle } from 'src/domain/Schema/model/apiTypes';
import AvailabilityForm from 'src/domain/Package/model/AvailabilityForm';

const useConstraints = () => {
  const { t } = useTranslation();

  const customTitleValidation = (value?: TravelerTitle): boolean => {
    if (value === undefined) return false;
    if (value.length === 0) return false;

    return true;
  };

  const travellersSchema = useMemo(() => {
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}/;
    const passportRegex = /^[A-Za-z\d]+$/;
    const stringRegex = /^[\p{L}\u0100-\u024F]*$/u;

    return yup.object().shape({
      gender: yup.mixed<Gender>().required(t('form_field.validation.field_can_not_be_empty')),
      title: yup
        .mixed<TravelerTitle>()
        .test('is-valid-traveler-title', t('form_field.validation.field_can_not_be_empty'), customTitleValidation)
        .required(),
      firstName: yup
        .string()
        .required(t('form_field.validation.field_can_not_be_empty'))
        .matches(stringRegex, t('form_field.validation.must_contain_letters_only')),
      middleName: yup
        .string()
        .matches(stringRegex, t('form_field.validation.must_contain_letters_only')),
      lastName: yup
        .string()
        .required(t('form_field.validation.field_can_not_be_empty'))
        .matches(stringRegex, t('form_field.validation.must_contain_letters_only')),
      dateOfBirth: yup
        .string()
        .required(t('form_field.validation.field_can_not_be_empty'))
        .matches(dateRegex, t('form_field.validation.invalid_date_format')),
      nationality: yup.string().required(t('form_field.validation.field_can_not_be_empty')),
      passportNumber: yup
        .string()
        .required(t('form_field.validation.field_can_not_be_empty'))
        .matches(passportRegex, t('form_field.validation.must_contain_letters_or_numbers_only')),
      passportExpirationDate: yup
        .string()
        .required(t('form_field.validation.field_can_not_be_empty'))
        .matches(dateRegex, t('form_field.validation.invalid_date_format')),
      medicalConditionsDetails: yup.string(),
    });
  }, [t]);

  const wizardSchema: ObjectSchema<TravelDetailsForm> = useMemo(() => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,}$/;

    return yup.object({
      travellers: yup.array().of(travellersSchema).required(),
      address: yup.string().required(t('form_field.validation.field_can_not_be_empty')),
      city: yup.string().required(t('form_field.validation.field_can_not_be_empty')),
      country: yup.string().required(t('form_field.validation.field_can_not_be_empty')),
      email: yup
        .string()
        .required(t('form_field.validation.field_can_not_be_empty'))
        .matches(emailRegex, t('form_field.validation.email_not_valid')),
      phoneNumber: yup.string().nullable().required(t('form_field.validation.field_can_not_be_empty')),
      zipCode: yup.string().required(t('form_field.validation.field_can_not_be_empty')),
      terms: yup
        .object()
        .shape({
          termsAndConditionsAgreed: yup
            .boolean()
            .required()
            .oneOf([true], t('form_field.validation.accept_terms_and_conditions')),
          termsAndConditionsIntermediaryAgreed: yup
            .boolean()
            .required()
            .oneOf([true], t('form_field.validation.accept_terms_and_conditions')),
        })
        .required(),
    });
  }, [t]);

  const availabilityFormSchema: ObjectSchema<Partial<AvailabilityForm>> = useMemo(() => {
    const schema = yup.object().shape({
      adults: yup.number().min(1, t('form.availability_form.select_an_adult')).required(),
      dateFrom: yup.date().nullable().required(t('form.availability_form.select_an_option')),
    });

    return schema as ObjectSchema<Partial<AvailabilityForm>>;
  }, [t]);

  return {
    wizardSchema,
    availabilityFormSchema,
  };
};

export default useConstraints;
