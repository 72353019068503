import React from 'react';
import SkeletonWhiteBox from 'src/presentation/components/LoadingSkeletons/elements/SkeletonWhiteBox';
import styled, { css } from 'styled-components';
import useViewport from 'src/toolkit/utils/useViewport';
import SkeletonText from 'src/presentation/components/LoadingSkeletons/elements/SkeletonText';
import breakpoints from 'src/integration/styles/breakpoints';

const OneRowBlock = styled.div`
  ${() => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 42px;

    ${breakpoints.mobile`
      align-items: center;   
    `}
  `}
`;

const StyledOneRowBlock = styled(OneRowBlock)`
  ${() => css`
    gap: 16px;
    justify-content: center;
  `}
`;

const Wrapper = styled.div`
  ${() => css`
    width: 100%;
  `}
`;

const SkeletonBasketPDP = () => {
  const { isMobile, isDesktop } = useViewport();

  return (
    <SkeletonWhiteBox vPadding={24} hPaddingDesktop={24} showBorder>
      <SkeletonText width={281} height={isMobile ? 56 : 42} marginBottom={isMobile ? 24 : 46} />

      {isMobile && (
        <>
          <SkeletonText height={37} marginBottom={24} />

          <SkeletonText marginBottom={8} />
          <SkeletonText marginBottom={8} />
          <SkeletonText marginBottom={8} />
          <SkeletonText marginBottom={8} />
          <SkeletonText marginBottom={8} />
          <SkeletonText marginBottom={8} />
          <SkeletonText width={154} marginBottom={29} />

          <OneRowBlock>
            <SkeletonText width={140} height={38} marginBottom={37} alignRight />
            <Wrapper>
              <SkeletonText width={140} height={24} marginBottom={6} alignRight />
              <SkeletonText width={105} height={24} marginBottom={37} alignRight />
            </Wrapper>
          </OneRowBlock>

          <StyledOneRowBlock>
            <SkeletonText width={53} height={38} marginBottom={24} />
            <SkeletonText width={53} height={38} marginBottom={24} />
            <SkeletonText width={53} height={38} marginBottom={24} />
            <SkeletonText width={53} height={38} marginBottom={24} />
          </StyledOneRowBlock>
        </>
      )}

      {isDesktop && (
        <>
          <SkeletonText marginBottom={12} />
          <SkeletonText marginBottom={12} />
          <SkeletonText height={24} marginBottom={12} />
          <SkeletonText marginBottom={12} />
          <SkeletonText height={24} marginBottom={12} />
          <SkeletonText height={24} marginBottom={12} />
          <SkeletonText height={24} marginBottom={61} />

          <OneRowBlock>
            <SkeletonText width={140} height={38} marginBottom={32} alignRight />
            <Wrapper>
              <SkeletonText width={226} height={24} marginBottom={6} alignRight />
              <SkeletonText width={154} height={24} marginBottom={32} alignRight />
            </Wrapper>
          </OneRowBlock>

          <SkeletonText height={42} marginBottom={49} alignRight />
        </>
      )}

      <SkeletonText width={isMobile ? 182 : 226} height={24} marginBottom={6} alignRight />
      <SkeletonText width={isMobile ? 261 : 154} height={24} marginBottom={isMobile ? 0 : 14} alignRight />
    </SkeletonWhiteBox>
  );
};

export default SkeletonBasketPDP;
