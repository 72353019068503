import { useTranslation } from 'react-i18next';

const useErrorMessagesMapper = () => {
  const { t } = useTranslation();

  const propertyPathMapper = (value: string) => {
    const customersRegex = /^customers\[(\d+)\]\.(.+)$/;
    const matchCustomers = value.match(customersRegex);

    if (matchCustomers) {
      const travelerNumber = parseInt(matchCustomers[1]) + 1;
      const propertyName = matchCustomers[2];

      return getTravelerPropertyLabel(travelerNumber, propertyName);
    }

    if (value.startsWith('contactDetails.')) {
      const property = value.substring('contactDetails.'.length);
      return getContactDetailsLabel(property);
    }

    return value;
  };

  const getTravelerPropertyLabel = (travelerNumber: number, propertyName: string) => {
    switch (propertyName) {
      case 'firstName':
        return `Traveler ${travelerNumber} ${t('form_field.first_name.label')}`;
      case 'lastName':
        return `Traveler ${travelerNumber} ${t('form_field.last_name.label')}`;
      case 'middleName':
        return `Traveler ${travelerNumber} ${t('form_field.middle_name.label')}`;
      case 'title':
        return `Traveler ${travelerNumber} ${t('form_field.title.label')}`;
      case 'dateOfBirth':
        return `Traveler ${travelerNumber} ${t('form_field.date_of_birth.label')}`;
      case 'nationality':
        return `Traveler ${travelerNumber} ${t('form_field.nationality.label')}`;
      case 'gender':
        return `Traveler ${travelerNumber} ${t('form_field.gender.label')}`;
      case 'passportNumber':
        return `Traveler ${travelerNumber} ${t('form_field.passport_number.label')}`;
      case 'passportExpirationDate':
        return `Traveler ${travelerNumber} ${t('form_field.expiration_date.label')}`;
      default:
        return propertyName;
    }
  };

  const getContactDetailsLabel = (property: string) => {
    switch (property) {
      case 'email':
        return t('form_field.email.label');
      case 'phoneNumber':
        return t('form_field.phone_number.label');
      case 'address.street':
        return t('form_field.address.label');
      case 'address.zipCode':
        return t('form_field.zip_code.label');
      case 'address.city':
        return t('form_field.city.label');
      case 'address.country':
        return t('form_field.country.label');

      default:
        return property;
    }
  };


  return {
    propertyPathMapper
  }
}

export default useErrorMessagesMapper;