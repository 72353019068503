import React from 'react';
import styled, { css } from 'styled-components';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import Header from 'src/presentation/components/ui/Header/Header';
import Button from 'src/presentation/components/ui/Button/Button';
import Includes from 'src/presentation/components/Includes/Includes';
import useScrollToElement from 'src/toolkit/utils/useScrollToElement';
import ElementId from 'src/integration/config/ElementId';
import breakpoints from 'src/integration/styles/breakpoints';
import useViewport from 'src/toolkit/utils/useViewport';
import { useTranslation } from 'react-i18next';
import Link from 'src/presentation/components/ui/Link/Link';
import useThemeProvider from 'src/toolkit/hooks/useThemeProvider';

interface ComponentProps extends Component {
  description?: string;
  tripIncludes?: string[];
}

const Root = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 16px;

    ${breakpoints.desktop`
      grid-gap: 24px;
    `}
  `}
`;

const StyledSubheader = styled(Header)`
  ${() => css`
    margin-bottom: 8px;

    ${breakpoints.desktop`
       margin-bottom: 18px; 
    `}
  `}
`;

const IncludedBlock = styled.div``;

const ButtonArea = styled.div`
  ${() => css`
    text-align: right;
  `}
`;

const StyledButton = styled(Button)`
  ${() => css`
    margin-top: 16px;
    align-self: flex-end;
  `}
`;

const Wrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 2px;

    ${breakpoints.desktop`
      gap: 4px;
    `}
  `}
`;

const StyledHeader = styled(Header)`
  ${() => css`
    ${breakpoints.mobile`
      margin-bottom: 2px;
    `}
  `}
`;

const Overview = ({ description, tripIncludes, className, id }: ComponentProps) => {
  const { themeLink } = useThemeProvider();
  const { scrollToElement } = useScrollToElement();
  const { isMobile, isDesktop } = useViewport();
  const { t } = useTranslation();

  const MAX_ITEM_IN_LIST = 4;

  return (
    <Root id={id} className={className}>
      <Paragraph large={isDesktop}>{description}</Paragraph>

      <IncludedBlock>
        <StyledSubheader level={'h6'}>{t('included_and_excluded.title')}</StyledSubheader>

        <Includes tripIncludes={tripIncludes} limit={MAX_ITEM_IN_LIST} />

        <ButtonArea>
          <StyledButton styleType={'link'} onClick={() => scrollToElement(ElementId.includes)}>
            {t('included_and_excluded.button.title')}
          </StyledButton>
        </ButtonArea>
      </IncludedBlock>

      <Wrapper>
        <StyledHeader level={isMobile ? 'h6' : 'h3'}>{t('trip_is_executed_by.title')}</StyledHeader>
        <Header level={isMobile ? 'h6' : 'h3'}>{t('tour_operator.title')}</Header>
        <Paragraph large={isDesktop}>
          <Link href={t(themeLink.termsConditionsTo)} blank>
            {t('terms_and_conditions.title')}
          </Link>
        </Paragraph>
      </Wrapper>
    </Root>
  );
};

export default Overview;
