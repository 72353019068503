import { useEffect } from 'react';
import StepMap from 'src/domain/Wizard/enum/stepMap';
import QueryParams from 'src/domain/Settings/model/QueryParams';
import { useStore } from 'src/integration/store/store';
import useQueryParams from 'src/toolkit/hooks/useQueryParams';
import useWizardHelper from 'src/toolkit/hooks/useWizardHelper';

const useWizardSteps = () => {
  const { wizardStore } = useStore();
  const { updateUrlParam, step } = useQueryParams();
  const { getCurrentStepId, getStepId } = useWizardHelper();

  useEffect(() => {
    const matchingStep = getStepId(step);

    if (matchingStep) {
      return wizardStore.setStep(matchingStep.id);
    }
    wizardStore.setStep(StepMap.TRAVELLER_INFO_STEP);
  }, []);

  useEffect(() => {
    const currentStep = getCurrentStepId();

    if (!currentStep) return;
    updateUrlParam(QueryParams.step, currentStep.stepName);
  }, [wizardStore.step]);
};

export default useWizardSteps;
