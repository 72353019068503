import React from 'react';
import { TripPrice } from 'src/domain/Schema/model/apiTypes';
import useCurrencyFormatter from 'src/toolkit/utils/useCurrencyFormatter';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import PaymentMethod from 'src/domain/Payments/enum/PaymentMethod';

interface ComponentProps {
  id: PaymentMethod;
  amount: TripPrice;
  title: string;
  buttonGroupName?: string;
  selected?: boolean;
  onChange: (id: PaymentMethod) => void;
}

const Root = styled.label`
  ${() => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 15px;
    border: 1px solid var(--stroke-for-blocks, #f0efef);
    box-shadow: 0px 3px 20px 0px rgba(9, 10, 10, 0.12);
    background: ${colors.white};
    padding: 24px;
    cursor: pointer;
  `}
`;

const Label = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  `}
`;

const RadioButton = styled.input`
  ${() => css`
    margin-right: 12px;
    cursor: pointer;
  `}
`;

const PaymentMethodButton = ({ id, title, amount, selected, onChange, buttonGroupName }: ComponentProps) => {
  const { formatCurrency } = useCurrencyFormatter();

  return (
    <Root onChange={() => onChange(id)}>
      <RadioButton type="radio" defaultChecked={selected} name={buttonGroupName} />
      <Label>
        {title} {formatCurrency(amount.amount, amount.currency, true)}
      </Label>
    </Root>
  );
};

export default PaymentMethodButton;
