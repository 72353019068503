import React, { FunctionComponent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'src/presentation/components/ui/LoadingSpinner/LoadingSpinner';

export type StyleType = 'primary' | 'primaryOutlined' | 'secondary' | 'link' | undefined;
type ButtonType = 'button' | 'reset' | 'submit' | undefined;

interface ComponentProps extends Component {
  children: ReactNode;
  styleType?: StyleType;
  small?: boolean;
  large?: boolean;
  onClick?: () => void;
  type?: ButtonType;
  disabled?: boolean;
  leftIcon?: FunctionComponent;
  rightIcon?: FunctionComponent;
  isLoading?: boolean;
}

const Root = styled.button<{
  $styleType: StyleType;
  $small?: boolean;
  $large?: boolean;
  disabled?: boolean;
  $iconLeft?: boolean;
  $iconRight?: boolean;
}>`
  ${({ theme, $styleType = 'primary', disabled, $small, $large, $iconLeft, $iconRight }) => css`
    font-family: ${theme.fonts.fontFamily};
    display: inline-flex;
    grid-gap: ${$styleType === 'link' ? '4px' : '8px'};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    line-height: 100%;
    text-decoration: none;

    ${$styleType === 'primary' &&
    css`
      border: 2px solid ${theme.colors.primary};
      color: ${colors.white};
      background: ${theme.colors.primary};
      &:hover {
        border: 2px solid ${theme.colors.primaryContainerOn};
        background: ${theme.colors.primaryContainerOn};
        color: ${colors.white};
      }

      &:active {
        background: ${theme.colors.primaryContainerOn};
        box-shadow: 0 4px 8px 2px #000 inset;
      }
    `}

    ${$styleType === 'primaryOutlined' &&
    css`
      border: 2px solid ${theme.colors.primary};
      color: ${theme.colors.primary};
      background: ${colors.white};

      &:hover,
      &:active {
        border: 2px solid ${theme.colors.primaryContainerOn};
        color: ${theme.colors.primaryContainerOn};
        background: ${colors.white};
      }
    `}

    ${$styleType === 'secondary' &&
    css`
      border: 2px solid ${theme.colors.secondary};
      color: ${theme.colors.secondary};
      background: ${colors.white};

      &:hover,
      &:active {
        border: 2px solid ${theme.colors.secondaryContainerOn};
        color: ${theme.colors.secondaryContainerOn};
        background: ${colors.white};
      }
    `}
    
    ${($styleType === 'primary' || $styleType === 'primaryOutlined' || $styleType === 'secondary') &&
    css`
      border-radius: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      font-size: ${$small ? '14px' : '16px'};
      padding: ${$small ? '11px 16px' : '13px 24px'};

      ${$iconLeft &&
      css`
        padding-left: ${$small ? '16px' : '20px'};
      `}

      ${$iconRight &&
      css`
        padding-right: ${$small ? '16px' : '20px'};
      `}
      
      ${disabled &&
      css`
        border: 2px solid ${colors.neutral90};
        background: ${colors.neutral90};
        color: ${colors.neutral50};
        cursor: not-allowed;

        &:hover {
          border: 2px solid ${colors.neutral90};
          background: ${colors.neutral90};
          color: ${colors.neutral50};
          box-shadow: none;
        }
      `}
    `}
    
    ${$styleType === 'link' &&
    css`
      font-style: normal;
      font-size: ${$small ? '12px' : $large ? '16px' : '14px'};
      line-height: 20px;
      background: transparent;
      border: none;
      border-radius: unset;
      padding: 0 0 2px;
      font-weight: 400;
      color: ${colors.neutral30};
        
      &:hover,
      &:active {
        color: ${theme.colors.primary};
      }

      ${disabled &&
      css`
        color: ${colors.neutral50};
        cursor: not-allowed;

        &:hover {
          color: ${colors.neutral50};
        }
      `}
    `}
    
    &[disabled], &:disabled {
      cursor: not-allowed;
    }
  `}
`;

const StyledIcon = styled(Icon)<{ $small?: boolean }>`
  ${({ $small }) => css`
    height: 24px;
    width: 24px;

    ${$small &&
    css`
      height: 20px;
      width: 20px;
    `}
  `}
`;

const ButtonText = styled.span<{ $styleType?: StyleType; disabled?: boolean }>`
  ${({ $styleType, disabled }) => css`
    ${$styleType === 'link' &&
    css`
      border-bottom: 1px solid ${colors.neutral30};

      &:hover,
      &:active {
        border-bottom: ${disabled ? `1px solid ${colors.neutral30}` : '1px solid transparent'};
      }
    `};
  `}
`;

const LoadingText = styled.span`
  ${() => css`
    display: flex;
    align-items: center;
  `}
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  ${() => css`
    margin-right: 6px;
  `}
`;

const Button = ({
  children,
  styleType = 'primary',
  small,
  large,
  onClick,
  disabled,
  type = 'button',
  className,
  leftIcon,
  rightIcon,
  isLoading,
  dataTestId,
}: ComponentProps) => {
  const { t } = useTranslation();

  const renderIcon = (icon: FunctionComponent) => {
    return <StyledIcon icon={icon} $small={small} />;
  };

  const renderButtonContent = () => {
    if (isLoading)
      return (
        <LoadingText>
          <StyledLoadingSpinner />
          {t('button.is_loading_text')}
        </LoadingText>
      );

    return children;
  };

  return (
    <Root
      className={className}
      type={type}
      $styleType={styleType}
      $small={small}
      $large={large}
      disabled={disabled}
      onClick={onClick}
      $iconLeft={!!leftIcon}
      $iconRight={!!rightIcon}
      data-testid={dataTestId}
    >
      {leftIcon && renderIcon(leftIcon)}
      <ButtonText $styleType={styleType} disabled={disabled}>
        {renderButtonContent()}
      </ButtonText>
      {rightIcon && renderIcon(rightIcon)}
    </Root>
  );
};

export default Button;
