import React from 'react';
import styled, { css } from 'styled-components';
import { TripMedia } from 'src/domain/Schema/model/apiTypes';

interface ComponentProps {
  images?: TripMedia[];
}
const Root = styled.div`
  ${() => css`
    display: flex;
    grid-gap: 24px;
  `}
`;

const Image = styled.img`
  ${() => css`
    width: 100%;
    min-width: 262px;
    height: 224px;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid var(--neutral-90, #e6e1e6);
  `}
`;

const Gallery = ({ images }: ComponentProps) => {
  const MAX_COUNT_OF_IMAGES = 4;

  return (
    <Root data-testid={'gallery'}>
      {images
        ?.slice(0, MAX_COUNT_OF_IMAGES)
        .map((item, index) => <Image data-testid={'gallery-image'} key={`gallery-image-${index}`} src={item.url} />)}
    </Root>
  );
};

export default Gallery;
