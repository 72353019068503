import React, { ChangeEvent, ReactNode, useMemo } from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import ErrorText from 'src/presentation/components/FormFields/ErrorText';
import Label from 'src/presentation/components/FormFields/Label';
import { iconArrowDown } from 'src/integration/assets/AssetsProvider';
import SelectFieldType from 'src/domain/Styles/model/SelectFieldType';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import { renderToStaticMarkup } from 'react-dom/server';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';

interface ComponentProps<T extends FieldValues> {
  label?: string;
  name: Path<T>;
  placeholder: string;
  register: UseFormRegister<T>;
  required?: boolean;
  disabled?: boolean;
  errors?: string;
  list: SelectFieldType[];
  prefixIcon?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  isLoading?: boolean;
  isError?: boolean;
  defaultValue?: string;
}

const Root = styled.div<{ $label?: string }>`
  ${({ $label }) => css`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin-top: ${$label ? '8px' : '0'};
  `}
`;

const Wrapper = styled.div``;

const SelectField = styled.select<{ $error: boolean; $icon: boolean }>`
  ${({ theme, $error, $icon }) => css`
      box-sizing: border-box;
      display: grid;
      border-radius: 10px;
      border: 2px solid ${$error ? colors.error : colors.neutral80};
      padding: ${$icon ? '14px 14px 14px 42px' : '14px 14px 14px 26px'};
      width: 100%;

      font-family: ${theme.fonts.fontFamily};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      background-image: url("data:image/svg+xml;utf8,${encodeURIComponent(
        renderToStaticMarkup(<Icon icon={iconArrowDown} />),
      )}");
      background-size: 24px;
      background-position: calc(100% - 12px) center;
      background-repeat: no-repeat;
  
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  `}
`;

const PrefixIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
`;

const Select = <T extends FieldValues>({
  name,
  register,
  label,
  placeholder,
  list,
  prefixIcon,
  errors,
  required,
  disabled,
  isLoading,
  ...rest
}: ComponentProps<T>) => {
  const renderOptions = useMemo(() => {
    if (isLoading) return <>Is Loading...</>;

    return (
      <>
        {list.map((item, key) => {
          return (
            <option key={`select-${key}-${item.id}`} value={item.id}>
              {item.name}
            </option>
          );
        })}
      </>
    );
  }, [isLoading, list]);

  return (
    <Root $label={label}>
      {label && <Label optional={!required}>{label}</Label>}
      <Wrapper>
        {prefixIcon && <PrefixIconWrapper>{prefixIcon}</PrefixIconWrapper>}
        <SelectField id={name} {...register(name)} $icon={!!prefixIcon} disabled={disabled} $error={!!errors} {...rest}>
          <option value="" disabled>
            {placeholder}
          </option>
          {renderOptions}
        </SelectField>
      </Wrapper>
      {errors && <ErrorText error={errors} />}
    </Root>
  );
};

export default Select;
