import * as React from 'react';
import { useStore } from 'src/integration/store/store';
import { observer } from 'mobx-react-lite';
import useWizardHelper from 'src/toolkit/hooks/useWizardHelper';
import useWizardSteps from 'src/toolkit/hooks/useWizardSteps';

const Wizard = observer(() => {
  const { wizardStore } = useStore();
  const { getCurrentStepId } = useWizardHelper();
  const currentStepDefinition = getCurrentStepId();

  useWizardSteps();

  if (!currentStepDefinition) {
    throw new Error(`Step with id ${wizardStore.step} is not defined`);
  }

  const Component = currentStepDefinition.component;

  return <Component stepId={wizardStore.step} submitted={wizardStore.submitted} />;
});

export default Wizard;
