import React from 'react';
import styled, { css } from 'styled-components';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import useDateTimeFormatter from 'src/toolkit/utils/useDateTimeFormatter';
import { useTranslation } from 'react-i18next';

interface ComponentProps extends Component {
  startDate: string;
  endDate: string;
}

const Root = styled.div`
  ${() => css`
    display: flex;
    grid-gap: 24px;
  `}
`;

const Column = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
  `}
`;

const DateText = styled(Paragraph)`
  ${() => css`
    line-height: 18px;
  `}
`;

const TravelDateBlock = ({ startDate, endDate, className }: ComponentProps) => {
  const { formatDate } = useDateTimeFormatter();
  const { t } = useTranslation();

  return (
    <Root className={className}>
      <Column>
        <DateText>{t('summary_form.start_date')}</DateText>
        <Paragraph semiBold large>
          {formatDate(startDate)}
        </Paragraph>
      </Column>

      <Column>
        <DateText>{t('summary_form.end_date')}</DateText>
        <Paragraph semiBold large>
          {formatDate(endDate)}
        </Paragraph>
      </Column>
    </Root>
  );
};

export default TravelDateBlock;
