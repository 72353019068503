import { createContext, useContext } from 'react';
import WizardStore from 'src/integration/store/WizardStore/WizardStore';
import PackageStore from 'src/integration/store/PackageStore/PackageStore';
import PaymentStore from 'src/integration/store/PaymentStore/PaymentStore';

export interface Store {
  wizardStore: WizardStore;
  packageStore: PackageStore;
  paymentStore: PaymentStore;
}

export const store: Store = {
  wizardStore: new WizardStore(),
  packageStore: new PackageStore(),
  paymentStore: new PaymentStore(),
};

export const StoreContext = createContext(store);

export const useStore = () => {
  return useContext(StoreContext);
};
