import Header from 'src/presentation/components/ui/Header/Header';
import Button from 'src/presentation/components/ui/Button/Button';
import React from 'react';
import styled, { css } from 'styled-components';
import breakpoints from 'src/integration/styles/breakpoints';
import { useTranslation } from 'react-i18next';
import useViewport from 'src/toolkit/utils/useViewport';
import { iconCalendar, iconUser } from 'src/integration/assets/AssetsProvider';
import TravelerAmountCounter from 'src/presentation/components/AvailabilityComponent/elements/TravelerAmountCounter';
import Separator from 'src/presentation/components/ui/Separator/Separator';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import colors from 'src/integration/styles/colors';
import { useStore } from 'src/integration/store/store';
import { observer } from 'mobx-react-lite';
import useDateTimeFormatter from 'src/toolkit/utils/useDateTimeFormatter';
import useAvailabilityForm from 'src/toolkit/hooks/useAvailabilityForm';
import Dropdown from 'src/presentation/components/ui/Dropdown/Dropdown';

interface ComponentProps {
  onClick: () => void;
  isLoading: boolean;
}

const Form = styled.form`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
  `}
`;

const Wrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 12px;

    ${breakpoints.desktop`
      flex-direction: row;
      grid-gap: 16px;        
    `}
  `}
`;

const StyledSeparator = styled(Separator)`
  ${() => css`
    margin: 6px 0 9px;
  `}
`;

const MonthList = styled.ul`
  ${() => css``}
`;

const MonthListItem = styled.div<{ $selected: boolean }>`
  ${({ $selected }) => css`
    padding: 8px;
    margin-right: 16px;

    &:hover {
      background: ${colors.neutral96};
    }

    ${$selected &&
    css`
      background: ${colors.neutral96};
    `}
  `}
`;

const AvailabilityForm = ({ onClick, isLoading }: ComponentProps) => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const { convertDateToSelectField } = useDateTimeFormatter();
  const { packageStore } = useStore();
  const { handleFormSubmission, errors, monthList, calculateSharedMaximum } = useAvailabilityForm(onClick);
  const { MIN_ADULTS, MIN_CHILDREN, availabilityForm, setAdultsCount, setChildrenCount, setSelectedDate } =
    packageStore;
  const { adults, children, dateFrom } = availabilityForm;

  return (
    <Form>
      <Header level={isMobile ? 'h7' : 'h4'}>{t('availability_form.title')}</Header>

      <Wrapper>
        <Dropdown
          icon={iconUser}
          text={t('availability_form.placeholder.traveler', { count: children + adults })}
          disableOptionClick
          disabled={isLoading}
          errors={errors.adults?.message}
        >
          <TravelerAmountCounter
            label={t('availability_form.counter.adults.label')}
            description={t('availability_form.counter.adults.description')}
            min={MIN_ADULTS}
            defaultValue={adults}
            max={calculateSharedMaximum(children)}
            onAmountChange={setAdultsCount}
          />
          <StyledSeparator />
          <TravelerAmountCounter
            label={t('availability_form.counter.children.label')}
            description={t('availability_form.counter.children.description')}
            min={MIN_CHILDREN}
            defaultValue={children}
            max={calculateSharedMaximum(adults)}
            onAmountChange={setChildrenCount}
          />
        </Dropdown>

        <Dropdown
          icon={iconCalendar}
          text={convertDateToSelectField(dateFrom).name || t('availability_form.placeholder.month')}
          maxHeight={180}
          disabled={isLoading}
          errors={errors.dateFrom?.message}
        >
          <MonthList>
            {monthList.map((month) => (
              <MonthListItem
                onClick={() => setSelectedDate(month)}
                key={month.id}
                $selected={convertDateToSelectField(dateFrom).name === month.name}
              >
                <Paragraph large>{month.name}</Paragraph>
              </MonthListItem>
            ))}
          </MonthList>
        </Dropdown>
      </Wrapper>

      <Button
        dataTestId={'check-availability'}
        small={isMobile}
        styleType={'primary'}
        onClick={handleFormSubmission}
        isLoading={isLoading}
        disabled={isLoading}
      >
        {t('availability_form.button.check_availability')}
      </Button>
    </Form>
  );
};

export default observer(AvailabilityForm);
