import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import SectionName from 'src/presentation/components/Itinerary/elements/SectionName';
import DetailsWhiteBox from 'src/presentation/components/Itinerary/elements/DetailsWhiteBox';
import { Activity } from 'src/domain/Schema/model/apiTypes';
import Badge from 'src/presentation/components/ui/Badge/Badge';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  activities?: Activity[];
}

const Root = styled.div`
  ${({ theme }) => css`
    color: ${colors.neutral50};
    font-family: ${theme.fonts.fontFamily};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  `}
`;

const StyledSectionName = styled(SectionName)`
  ${() => css`
    margin-bottom: 8px;
  `}
`;

const Content = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
  `}
`;

const StyledBadge = styled(Badge)`
  ${() => css`
    position: absolute;
    top: -12px;
    right: 16px;
  `}
`;

const Activities = ({ activities }: ComponentProps) => {
  const { t } = useTranslation();

  return (
    <Root>
      <StyledSectionName>{t('itinerary.activities.title')}</StyledSectionName>
      <Content>
        {activities?.map((item, index) => (
          <DetailsWhiteBox key={`activities-${index}`} title={item.name}>
            {item.physicalGrading?.label && (
              <StyledBadge>
                {t('itinerary.activities.badge.title')} {item.physicalGrading?.label}
              </StyledBadge>
            )}
            {item.summary}
          </DetailsWhiteBox>
        ))}
      </Content>
    </Root>
  );
};

export default Activities;
