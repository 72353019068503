import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import ScrollToTop from 'src/presentation/components/ui/ScrollToTop/ScrollToTop';
import breakpoints from 'src/integration/styles/breakpoints';

interface ComponentProps extends Component {
  children: ReactNode;
  sidebar?: ReactNode;
  type: 'column' | 'row';
}

const Root = styled.div<{ $sidebar?: boolean }>`
  ${({ $sidebar }) => css`
    max-width: 1128px;
    margin: auto;

    ${$sidebar &&
    css`
      display: flex;
      flex-direction: column-reverse;
      gap: 24px;

      ${breakpoints.desktop`
        display: grid;
        grid-template-columns: auto 456px;
      `}
    `}
  `}
`;

const Sidebar = styled.div``;
const Content = styled.div``;

const Layout = ({ children, sidebar, className }: ComponentProps) => {
  return (
    <Root className={className} $sidebar={!!sidebar}>
      <ScrollToTop />
      <Content>{children}</Content>
      {sidebar && <Sidebar>{sidebar}</Sidebar>}
    </Root>
  );
};

export default Layout;
