import { useEffect, useRef, useState } from 'react';
import useClickOutside from 'src/toolkit/utils/useClickOutside';

const useDropdown = (disableOptionClick = false) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const contentRef = useRef<Nullable<HTMLDivElement>>(null);

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useClickOutside(dropdownRef, closeDropdown);

  useEffect(() => {
    if (disableOptionClick) return;

    contentRef.current?.addEventListener('click', closeDropdown);

    return () => {
      contentRef.current?.removeEventListener('click', closeDropdown);
    };
  }, [disableOptionClick]);

  return { isOpen, dropdownRef, contentRef, toggleDropdown };
};

export default useDropdown;
