import React from 'react';
import styled, { css } from 'styled-components';

interface ComponentProps extends Component {
  space?: number;
}

const Root = styled.div<{ $margin: number }>`
  ${({ $margin }) => css`
    display: flex;
    height: 1px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: var(--neutral-80, #cac4cf);
    margin-top: ${$margin}px;
    margin-bottom: ${$margin}px;
  `}
`;

const Separator = ({ space = 0, className }: ComponentProps) => {
  return <Root $margin={space} className={className} />;
};

export default Separator;
