import styled, { css } from 'styled-components';
import { TotalPricingInformation } from 'src/domain/Schema/model/apiTypes';
import React from 'react';
import TotalPrice from 'src/presentation/components/Basket/elements/TotalPrice';
import useCurrencyFormatter from 'src/toolkit/utils/useCurrencyFormatter';
import Header from 'src/presentation/components/ui/Header/Header';
import Separator from 'src/presentation/components/ui/Separator/Separator';
import { Trans, useTranslation } from 'react-i18next';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import { iconMastercard, iconVisa } from 'src/integration/assets/AssetsProvider';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import Link from 'src/presentation/components/ui/Link/Link';
import ButtonLink from 'src/presentation/components/ui/ButtonLink/ButtonLink';
import useThemeProvider from 'src/toolkit/hooks/useThemeProvider';

interface ComponentProps {
  price?: TotalPricingInformation;
  priceDetailsDocumentUrl?: string;
}

const PaymentMethods = styled.div`
  ${() => css`
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  `}
`;

const StyledPrice = styled(TotalPrice)`
  ${() => css`
    width: 100%;
    text-align: right;
    display: flex;
    align-items: flex-end;
  `}
`;

const TotalPriceLine = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    align-content: space-between;
  `}
`;

const TotalWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex-shrink: 0;
  `}
`;

const StyledIcon = styled(Icon)`
  ${() => css`
    width: 66px;
    height: 45px;
  `}
`;

const BasketCheckoutFooter = ({ price, priceDetailsDocumentUrl }: ComponentProps) => {
  const { themeLink } = useThemeProvider();
  const { formatCurrency } = useCurrencyFormatter();
  const { t } = useTranslation();

  return (
    <>
      <TotalPriceLine>
        <TotalWrapper>
          <Header level={'h2'}>{t('summary_form.total')}</Header>
          {priceDetailsDocumentUrl && (
            <ButtonLink href={priceDetailsDocumentUrl} target={'_blank'}>
              {t('summary_form.price.price_includes')}
            </ButtonLink>
          )}
        </TotalWrapper>
        <StyledPrice
          price={formatCurrency(price?.originalTotal, price?.currency, true)}
          isDiscountAvailable={!!price?.discountPercentage}
          discountPrice={formatCurrency(price?.total, price?.currency, true)}
        />
      </TotalPriceLine>

      <PaymentMethods>
        <StyledIcon icon={iconVisa} />
        <StyledIcon icon={iconMastercard} />
      </PaymentMethods>

      <Separator space={24} />

      <Paragraph>
        <Trans
          i18nKey={'summary_form.disclaimer.information'}
          components={{
            termsConditions: (
              <Link href={t(themeLink.termsConditionsTo)} blank>
                Link
              </Link>
            ),
          }}
        ></Trans>
      </Paragraph>
    </>
  );
};

export default BasketCheckoutFooter;
