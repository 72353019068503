export const APP_CONTAINER_NAME = 'esa-container';
export const APP_DATA_ATTR_THEME = 'data-theme';
export const APP_TRIP_PACKAGE_ID = 'data-trip-package-id';
export const APP_DATA_ATTR_INQUIRY_ID = 'data-inquiry-id';
export const APP_DATA_ATTR_INQUIRY_CATEGORY = 'data-inquiry-category';
export const APP_DATA_ATTR_INQUIRY_NAME = 'data-inquiry-name';
export const APP_DATA_ATTR_INQUIRY_BRAND = 'data-inquiry-brand';
export const APP_DATA_ATTR_INQUIRY_PRICE = 'data-inquiry-price';
export const APP_DATA_ATTR_INQUIRY_THUMBNAIL = 'data-inquiry-thumbnail';
export const APP_DATA_ATTR_INQUIRY_PROVIDERS = 'data-inquiry-providers';
