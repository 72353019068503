
  export const generateRandomString = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  export const generateRandomNumberString = (length: number): string => {
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomDigit = Math.floor(Math.random() * 10);
      result += randomDigit.toString();
    }

    return result;
  }

  export const generateRandomEmail = (): string => {
    const usernameLength = 8;
    const domain = 'example.com';

    const username = generateRandomString(usernameLength);
    return `${username}@${domain}`;
  }
