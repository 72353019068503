import { useEffect, useState } from 'react';

const useSessionStorage = <T,>(key: string) => {
  const [storageData, setStorageData] = useState<T>();

  useEffect(() => {
    if (!storageData) return;

    sessionStorage.setItem(key, JSON.stringify(storageData));
  }, [storageData]);

  useEffect(() => {
    const items = sessionStorage.getItem(key);

    if (!items) return;
    setStorageData(JSON.parse(items));
  }, []);

  const clearStorage = () => {
    sessionStorage.clear();
  };

  const removeStorageItem = (key: string) => {
    sessionStorage.removeItem(key);
  };

  return {
    storageData,
    setStorageData,
    clearStorage,
    removeStorageItem,
  };
};

export default useSessionStorage;
