import Icon from 'src/presentation/components/ui/Icon/Icon';
import { iconClock } from 'src/integration/assets/AssetsProvider';
import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import usePercentFormatter from 'src/toolkit/utils/useDiscountFormatter';

interface ComponentProps {
  discountPercent?: number;
}

const Root = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const Column = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
  `}
`;

const StyledIcon = styled(Icon)`
  ${() => css`
    padding-right: 8px;
    color: ${colors.white};
  `}
`;

const DiscountHeaderNote = ({ discountPercent }: ComponentProps) => {
  const { t } = useTranslation();
  const { formatPercent } = usePercentFormatter();

  return (
    <Root>
      <Column>
        <StyledIcon icon={iconClock} />
        <Paragraph semiBold color={colors.white} large>
          {t('summary_form.discount.info_text')}
        </Paragraph>
      </Column>
      <Paragraph large semiBold color={colors.white}>
        {t('summary_form.discount.until')} {formatPercent(discountPercent, true)}
      </Paragraph>
    </Root>
  );
};

export default DiscountHeaderNote;
