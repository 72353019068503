import React from 'react';
import PackageAvailability from 'src/presentation/components/AvailabilityComponent/elements/PackageAvailability';
import { Departure } from 'src/domain/Schema/model/apiTypes';
import usePackageAvailability from 'src/toolkit/hooks/usePackageAvailability';
import useCurrencyFormatter from 'src/toolkit/utils/useCurrencyFormatter';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import styled, { css } from 'styled-components';
import useViewport from 'src/toolkit/utils/useViewport';
import { useTranslation } from 'react-i18next';
import breakpoints from 'src/integration/styles/breakpoints';
import usePercentFormatter from 'src/toolkit/utils/useDiscountFormatter';

interface ComponentProps {
  packagesList?: Departure[];
  isLoading: boolean;
  isError: boolean;
}

const ListOfPackages = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 20px;

    ${breakpoints.desktop`
      grid-gap: 16px;
    `}
  `}
`;

const StyledParagraph = styled(Paragraph)`
  ${() => css`
    margin: auto;
  `}
`;

const PackageAvailabilityList = ({ packagesList, isLoading, isError }: ComponentProps) => {
  const { onSelectPackage } = usePackageAvailability();
  const { formatCurrency } = useCurrencyFormatter();
  const { isDesktop } = useViewport();
  const { formatPercent } = usePercentFormatter();
  const { t } = useTranslation();

  if (isLoading) return <span>{t('availability_form.loading')}</span>;
  if (isError) return <StyledParagraph>{t('availability_form.error.something_is_wrong')}</StyledParagraph>;
  if (!packagesList) return <></>;

  if (!packagesList.length)
    return <StyledParagraph large={isDesktop}>{t('availability_form.error.no_results')}</StyledParagraph>;

  return (
    <ListOfPackages>
      {packagesList.map((item, index) => (
        <PackageAvailability
          key={`departure-${item.id}-${index}`}
          isAvailable={item.isAvailable}
          isAdditionalInfoEnabled={item.isDisclaimerNeeded}
          startDate={item.startDate}
          endDate={item.endDate}
          price={formatCurrency(item.startingPrice?.amount, item.startingPrice?.currency)}
          onClick={() => onSelectPackage(item.id)}
          placeCount={item.availabilityCount}
          isDiscountAvailable={!!item.pricing.discountPercentage && item.isAvailable}
          discountPrice={formatCurrency(item.pricing.originalPrice, item.pricing.currency)}
          discount={formatPercent(item.pricing.discountPercentage)}
        />
      ))}
    </ListOfPackages>
  );
};

export default PackageAvailabilityList;
