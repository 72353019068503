import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';

interface ComponentProps {
  error: string;
}

// TODO: update design, it doesnt follow figma
const Root = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily};
    color: ${colors.error};
    font-size: 14px;
    padding-top: 4px;
  `}
`;

const ErrorText = ({ error }: ComponentProps) => {
  return <Root>{error}</Root>;
};

export default ErrorText;
