import useFetchPaymentInit from 'src/integration/api/useFetchPaymentInit';
import usePaymentsHook from 'src/toolkit/hooks/usePaymentsHook';
import { useEffect } from 'react';
import { useStore } from 'src/integration/store/store';
import useWizardHelper from 'src/toolkit/hooks/useWizardHelper';
import stepMap from 'src/domain/Wizard/enum/stepMap';
import useScrollToElement from 'src/toolkit/utils/useScrollToElement';
import useSuccessPaymentEventLogger from 'src/toolkit/hooks/useSuccessPaymentEventLogger';

const usePaymentTransition = () => {
  const { isFetching, makePayment, isError: isPaymentInitError, isLoading, data } = useFetchPaymentInit();
  const { onCancelled, onError, setShowLightbox, showLightbox, transactionId } = usePaymentsHook();
  const { wizardStore, paymentStore } = useStore();
  const { setValidationPass, step } = wizardStore;
  const { getCurrentStepId } = useWizardHelper();
  const { scrollToTop } = useScrollToElement();
  const { initSuccessPaymentEvent } = useSuccessPaymentEventLogger;
  const { cartId } = paymentStore;

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    const currentStep = getCurrentStepId();

    if (currentStep?.id !== stepMap.PREVIEW_AND_PAYMENT_STEP) {
      return;
    }

    setValidationPass(false);
  }, [step]);

  useEffect(() => {
    if (!data) return;

    if (isPaymentInitError) {
      setShowLightbox(false);
    }

    setShowLightbox(true);
  }, [isLoading, data]);

  const handlePayment = () => {
    makePayment();
    initSuccessPaymentEvent(cartId);
  };

  const isProdPaymentsEnabled = () => {
    return process.env.REACT_APP_PRODUCTION_PAYMENTS_ENABLED === 'true';
  };

  return {
    isPaymentInitError,
    handlePayment,
    isFetching: isFetching,
    onCancelled,
    onError,
    setShowLightbox,
    showLightbox,
    transactionId,
    isProdPaymentsEnabled,
  };
};

export default usePaymentTransition;
