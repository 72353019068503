import useReactQuery from 'src/integration/api/useReactQuery';
import apiConfig from 'src/integration/config/apiConfig';
import { DeparturesQueryParams, DeparturesResponseType } from 'src/domain/Schema/model/apiTypes';
import { useState } from 'react';
import useQueryKey from 'src/toolkit/utils/useQueryKey';
import ErrorType from 'src/domain/Schema/model/ErrorType';
import useWizardSessionStorage from 'src/toolkit/hooks/useWizardSessionStorage';
import usePackageAvailability from 'src/toolkit/hooks/usePackageAvailability';
import { useStore } from 'src/integration/store/store';
import useAppVariablesProvider from 'src/toolkit/hooks/useAppVariablesProvider';
import useEventLogger from 'src/toolkit/hooks/useEventLogger';

const useFetchDepartures = () => {
  const { packageId } = useAppVariablesProvider();
  const [enabled, setEnabled] = useState(true);
  const { mappedDeparturesQueryParams } = usePackageAvailability();
  const { packageStore } = useStore();
  const { removeCardId } = useWizardSessionStorage();
  const { availabilityForm, amountOfTravellers } = packageStore;
  const { dateTo, dateFrom } = availabilityForm;
  const { logAvailabilityNotFoundError } = useEventLogger();

  const response = useReactQuery<DeparturesQueryParams, ErrorType, DeparturesResponseType>({
    endpoint: apiConfig.api.endpoints.departures,
    queryKey: useQueryKey('departure', [packageId]),
    queryParams: mappedDeparturesQueryParams(amountOfTravellers, dateFrom, dateTo),
    queryPathId: { id: packageId },
    opts: {
      enabled: enabled,
      retry: 0,
      onSuccess: (data: DeparturesResponseType[]) => {
        if (!data.length) {
          logAvailabilityNotFoundError();
        }

        setEnabled(false);
        removeCardId();
      },
      onError: () => {
        setEnabled(false);
      },
    },
  });

  const fetchData = () => {
    setEnabled(true);
  };

  return {
    ...response,
    fetchData,
  };
};

export default useFetchDepartures;
