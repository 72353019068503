import React from 'react';
import styled, { css } from 'styled-components';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import { iconMinus, iconPlus } from 'src/integration/assets/AssetsProvider';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import colors from 'src/integration/styles/colors';
import useCounter from 'src/toolkit/hooks/useCounter';

interface ComponentProps extends Component {
  max?: number;
  min?: number;
  defaultValue?: number;
  onCountChange: (count: number) => void;
}

const Root = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 4px;
  `}
`;

const Count = styled.div`
  ${() => css`
    box-sizing: content-box;
    width: 2ch;
    padding: 11px 20px;
    display: flex;
    justify-content: center;
  `}
`;

const CountButton = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${colors.neutral90};
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 3px 20px rgba(9, 10, 10, 0.12);

    &:hover {
      box-shadow: unset;
    }

    ${disabled &&
    css`
      background: ${colors.neutral90};
      cursor: not-allowed;
      box-shadow: unset;
      color: ${colors.neutral50};
    `}
  `}
`;
const Counter = ({ min = 0, max = 8, defaultValue = 0, onCountChange }: ComponentProps) => {
  const { count, increaseCount, decreaseCount } = useCounter(onCountChange, min, max, defaultValue);

  return (
    <Root>
      <CountButton data-testid={'counter-minus'} disabled={count <= min} onClick={decreaseCount}>
        <Icon icon={iconMinus} size={18}></Icon>
      </CountButton>

      <Count data-testid={'counter-value'}>
        <Paragraph large>{count}</Paragraph>
      </Count>

      <CountButton data-testid={'counter-plus'} disabled={count >= max} onClick={increaseCount}>
        <Icon icon={iconPlus} size={18}></Icon>
      </CountButton>
    </Root>
  );
};

export default Counter;
