import useAppVariablesProvider from 'src/toolkit/hooks/useAppVariablesProvider';
import useUrlBuilder from 'src/toolkit/utils/useUrlBuilder';
import { useMemo } from 'react';
import useThemeProvider from 'src/toolkit/hooks/useThemeProvider';
import { useTranslation } from 'react-i18next';

const useInquiryLinkCreator = () => {
  const { inquiryId, inquiryCategory, inquiryName, inquiryBrand, inquiryThumbnail, inquiryProviders, inquiryPrice } =
    useAppVariablesProvider();
  const { buildUrlParams, getCurrentUrl } = useUrlBuilder();
  const { themeLink } = useThemeProvider();
  const { t } = useTranslation();

  const inquiryLink = useMemo(() => {
    return `${t(themeLink.domainUrl)}?${buildUrlParams({
      previousUrl: getCurrentUrl(),
      productId: inquiryId,
      destination: inquiryCategory,
      itinerary: inquiryName,
      region: inquiryBrand,
      price: inquiryPrice,
      thumbnailUrl: inquiryThumbnail,
      providers: inquiryProviders,
    })}`;
  }, [inquiryId, inquiryCategory, inquiryName, inquiryBrand, inquiryThumbnail, inquiryProviders, inquiryPrice]);

  return { inquiryLink };
};

export default useInquiryLinkCreator;
