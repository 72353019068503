import WhiteBox from 'src/presentation/components/ui/WhiteBox/WhiteBox';
import Input from 'src/presentation/components/FormFields/Input';
import Separator from 'src/presentation/components/ui/Separator/Separator';
import React from 'react';
import styled, { css } from 'styled-components';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import Header from 'src/presentation/components/ui/Header/Header';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import TravelDetailsForm from 'src/domain/Wizard/model/TravelDetailsForm';
import Select from 'src/presentation/components/FormFields/Select';
import useViewport from 'src/toolkit/utils/useViewport';
import { useTranslation } from 'react-i18next';
import useFetchCountries from 'src/integration/api/useFetchCountries';
import breakpoints from 'src/integration/styles/breakpoints';

interface ComponentProps {
  register: UseFormRegister<TravelDetailsForm>;
  errors: FieldErrors<TravelDetailsForm>;
}

const ContactDetailsForm = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    margin-top: 8px;
  `};
`;

const StyledAddressHeader = styled(Header)`
  ${() => css``}
`;

const InputWrapper = styled.div`
  ${() => css`
    margin-top: 8px;
  `}
`;

const OneRowBlock = styled.div`
  ${() => css`
    display: flex;
    align-items: flex-start;
    grid-gap: 16px;

    ${breakpoints.mobile`
      flex-direction: column;  
    `}
  `}
`;

const StyledOneRowBlock = styled(OneRowBlock)`
  ${() => css`
    margin: 8px 0;
  `}
`;

const ContactDetailsBlock = ({ register, errors }: ComponentProps) => {
  const { isMobile, isDesktop } = useViewport();
  const { t } = useTranslation();
  const { data: countriesList, isLoading: isLoadingCountries } = useFetchCountries();

  return (
    <WhiteBox title={t('form.contact_details.title')} headerLevel={isMobile ? 'h5' : 'h3'} hPaddingDesktop={24}>
      <Paragraph large={isDesktop} align={'right'}>
        {t('form.note.fields_are_required')}
      </Paragraph>

      <ContactDetailsForm>
        <Input
          type="email"
          name="email"
          label={t('form_field.email.label')}
          placeholder={t('form_field.email.placeholder')}
          register={register}
          errors={errors.email?.message}
          required
        />

        <InputWrapper>
          <Input
            type="text"
            name="phoneNumber"
            label={t('form_field.phone_number.label')}
            placeholder={t('form_field.phone_number.placeholder')}
            register={register}
            errors={errors.phoneNumber?.message}
            required
          />
        </InputWrapper>

        <Separator space={16} />

        <StyledAddressHeader level={'h7'}>{t('form_field.address.title')}</StyledAddressHeader>

        <Input
          type="text"
          name="address"
          label={t('form_field.address.label')}
          placeholder={t('form_field.address.placeholder')}
          register={register}
          errors={errors.address?.message}
          required
        />

        <StyledOneRowBlock>
          <Input
            type="text"
            name="zipCode"
            label={t('form_field.zip_code.label')}
            placeholder={t('form_field.zip_code.placeholder')}
            register={register}
            errors={errors.zipCode?.message}
            required
          />

          <Input
            type="text"
            name="city"
            label={t('form_field.city.label')}
            placeholder={t('form_field.city.placeholder')}
            register={register}
            errors={errors.city?.message}
            required
          />
        </StyledOneRowBlock>

        <Select
          name="country"
          label={t('form_field.country.label')}
          placeholder={t('form_field.country.placeholder')}
          register={register}
          errors={errors.country?.message}
          list={countriesList || []}
          isLoading={isLoadingCountries}
          required
        />
      </ContactDetailsForm>
    </WhiteBox>
  );
};

export default ContactDetailsBlock;
