import useSessionStorage from 'src/toolkit/utils/useSessionStorage';
import useQueryParams from 'src/toolkit/hooks/useQueryParams';
import TravelDetailsForm from 'src/domain/Wizard/model/TravelDetailsForm';
import { useMemo } from 'react';
import useAppVariablesProvider from 'src/toolkit/hooks/useAppVariablesProvider';

const useWizardSessionStorage = () => {
  const STORAGE_KEY = 'esaTravelerData';
  const STORAGE_CARD_ID = 'esaCardId';
  const { storageData, setStorageData } = useSessionStorage<SimpleObject[]>(STORAGE_KEY);
  const {
    storageData: cardIdFromStorage,
    setStorageData: setCardIdToStorage,
    removeStorageItem: removeCardIdFromStorage,
  } = useSessionStorage<Nullable<string>>(STORAGE_CARD_ID);
  const { departureId } = useQueryParams();
  const { packageId } = useAppVariablesProvider();
  const currentStorageDataKey = `${cardIdFromStorage}_${packageId}_${departureId}`;

  const removeCardId = () => {
    removeCardIdFromStorage(STORAGE_CARD_ID);
  };

  const setValuesToStorageData = (data: TravelDetailsForm) => {
    if (!storageData?.length) return setStorageData([{ key: currentStorageDataKey, value: data }]);

    const itemIndex = storageData.findIndex((item) => item.key === currentStorageDataKey);

    if (itemIndex !== -1) {
      const updatedStorageData = [...storageData];
      updatedStorageData[itemIndex] = { key: currentStorageDataKey, value: data };
      setStorageData(updatedStorageData);
      return;
    }

    setStorageData([...storageData, { key: currentStorageDataKey, value: data }]);
  };

  const findObjectByKey = (array: SimpleObject[] = [], key: string) => {
    return array.find((obj) => obj.key === key)?.value;
  };

  const travelerDataFromStorageData = useMemo(
    () => findObjectByKey(storageData, currentStorageDataKey),
    [cardIdFromStorage, storageData],
  );

  return {
    setValuesToStorageData,
    travelerDataFromStorageData,
    setCardIdToStorage,
    removeCardIdFromStorage,
    cardIdFromStorage,
    removeCardId,
  };
};

export default useWizardSessionStorage;
