import useEventLogger from 'src/toolkit/hooks/useEventLogger';

const useSuccessPaymentEventLogger = () => {
  const KEY = 'successPaymentEvent';

  const initSuccessPaymentEvent = (id: Nullable<string>) => {
    if (!id) return;

    localStorage.setItem(KEY, id);
  };

  const removeSuccessPaymentEvent = () => {
    localStorage.removeItem(KEY);
  };

  const sendSuccessPaymentEvent = (cartId?: string) => {
    const id = localStorage.getItem(KEY);
    const { logConfirmationPageBooking } = useEventLogger();

    if (!id) return;
    if (cartId !== id) return;

    logConfirmationPageBooking();
    removeSuccessPaymentEvent();
  };

  return {
    initSuccessPaymentEvent,
    removeSuccessPaymentEvent,
    sendSuccessPaymentEvent,
  };
};

export default useSuccessPaymentEventLogger();
