import React from 'react';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import styled, { css } from 'styled-components';
import Header from 'src/presentation/components/ui/Header/Header';
import breakpoints from 'src/integration/styles/breakpoints';
import Note from 'src/presentation/components/ui/Note/Note';
import useViewport from 'src/toolkit/utils/useViewport';
import { useTranslation } from 'react-i18next';

const StyledHeader = styled(Header)`
  ${() => css`
    margin-bottom: 24px;

    ${breakpoints.mobile`
      padding: 16px 16px 0;        
    `}
  `}
`;

const StyledNote = styled(Note)`
  ${() => css`
    margin-bottom: 24px;
  `}
`;
const ConditionalContentNote = () => {
  const { isMobile, isDesktop } = useViewport();
  const { t } = useTranslation();

  return (
    <>
      <StyledHeader level={isMobile ? 'h5' : 'h3'}>{t('form.traveler_information.title')}</StyledHeader>
      <StyledNote title={t('note.info.title')} isIconVisible>
        <Paragraph large={isDesktop}>{t('form.note.traveller_details_should_match_passport_info')}</Paragraph>
      </StyledNote>
    </>
  );
};

export default ConditionalContentNote;
