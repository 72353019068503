import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import breakpoints from 'src/integration/styles/breakpoints';

interface ComponentProps extends Component {
  children: ReactNode;
  align?: 'left' | 'right';
  color?: string;
  space?: number;
  small?: boolean;
  large?: boolean;
  italic?: boolean;
  semiBold?: boolean;
}

const Root = styled.p<{
  $align: string;
  $space: number;
  $small?: boolean;
  $large?: boolean;
  $semiBold?: boolean;
  $italic?: boolean;
  $color: string;
}>`
  ${({ theme, $align, $space, $color, $small, $large, $semiBold, $italic }) => css`
    color: ${$color ? $color : colors.neutral10};
    font-family: ${theme.fonts.fontFamily};
    font-size: 12px;
    font-style: normal;
    font-weight: ${$semiBold ? '600' : '400'};
    line-height: 16px;
    text-align: ${$align};
    padding-top: ${$space || 0}px;
    padding-bottom: ${$space || 0}px;

    ${breakpoints.desktop`
        font-size: 14px;
        line-height: 20px;
    `}

    ${$italic &&
    css`
      font-style: italic;
    `}

    ${$small &&
    css`
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.012px;
    `}

    ${$large &&
    css`
      font-size: 16px;
      line-height: 20px;

      ${breakpoints.desktop`
        font-size: 16px;
        line-height: 20px;
      `}
    `}
  `}
`;

const Paragraph = ({
  children,
  small,
  large,
  semiBold,
  italic,
  align = 'left',
  color = colors.neutral10,
  space = 0,
  className,
}: ComponentProps) => {
  return (
    <Root
      className={className}
      $align={align}
      $space={space}
      $small={small}
      $large={large}
      $semiBold={semiBold}
      $italic={italic}
      $color={color}
    >
      {children}
    </Root>
  );
};

export default Paragraph;
