import React from 'react';
import Layout from 'src/presentation/layouts/Layout';
import SkeletonBasketPDP from 'src/presentation/components/LoadingSkeletons/elements/SkeletonBasketPDP';
import useViewport from 'src/toolkit/utils/useViewport';
import Skeleton from 'src/presentation/components/LoadingSkeletons/elements/Skeleton';
import SkeletonText from 'src/presentation/components/LoadingSkeletons/elements/SkeletonText';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';

const Root = styled.div`
  ${() => css`
    background: ${colors.neutral96};
  `}
`;

const OneRowBlock = styled.div`
  ${() => css`
    width: 100%;
    display: flex;
    gap: 24px;
  `}
`;

const StyledLayout = styled(Layout)`
  ${() => css`
    padding-bottom: 24px;
  `}
`;

const Wrapper = styled.div`
  ${() => css`
    padding-right: 24px;
  `}
`;

const LoadingSkeletonPDP = () => {
  const { isMobile, isDesktop } = useViewport();
  return (
    <Root>
      <Skeleton height={isMobile ? 165 : 423} marginBottom={isMobile ? 0 : 42} />

      {isMobile && <SkeletonBasketPDP />}

      {isDesktop && (
        <StyledLayout type={'column'} sidebar={<SkeletonBasketPDP />}>
          <Wrapper>
            <SkeletonText width={328} height={54} marginBottom={26} />

            <OneRowBlock>
              <SkeletonText width={124} height={40} marginBottom={26} />
              <SkeletonText width={124} height={40} marginBottom={26} />
              <SkeletonText width={124} height={40} marginBottom={26} />
            </OneRowBlock>

            <SkeletonText height={24} marginBottom={8} />
            <SkeletonText height={24} marginBottom={48} />

            <SkeletonText width={300} height={32} marginBottom={18} />

            <SkeletonText height={24} marginBottom={8} />
            <SkeletonText height={24} marginBottom={8} />
            <SkeletonText height={24} marginBottom={8} />
            <SkeletonText height={24} marginBottom={16} />
            <SkeletonText width={85} height={24} alignRight />

            <SkeletonText width={346} height={40} marginBottom={45} />
          </Wrapper>
        </StyledLayout>
      )}
    </Root>
  );
};

export default LoadingSkeletonPDP;
