import { action, makeObservable, observable } from 'mobx';
import { PaymentOption } from 'src/domain/Schema/model/apiTypes';
import PaymentMethod from 'src/domain/Payments/enum/PaymentMethod';

class PaymentStore {
  @observable paymentOptions: PaymentOption[] = [];

  @observable cartId: Nullable<string> = null;

  @observable transactionId: Nullable<string> = null;

  @observable selectedPaymentMethod: PaymentMethod = PaymentMethod.full;

  @action
  sectCartId = (cartId: Nullable<string>) => {
    this.cartId = cartId;
  };

  @action
  setTransactionId = (transactionId: Nullable<string>) => {
    this.transactionId = transactionId;
  };

  @action
  setDefaultPaymentOptions = (paymentOptions: PaymentOption[]) => {
    this.paymentOptions = paymentOptions;
  };

  @action
  setSelectedPaymentMethod = (paymentMethod: PaymentMethod) => {
    this.selectedPaymentMethod = paymentMethod;
  };

  constructor() {
    makeObservable(this);
  }
}

export default PaymentStore;
