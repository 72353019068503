import useReactQuery from 'src/integration/api/useReactQuery';
import apiConfig from 'src/integration/config/apiConfig';
import { CustomerData, CustomerDataResponse } from 'src/domain/Schema/model/apiTypes';
import useQueryKey from 'src/toolkit/utils/useQueryKey';
import { useState } from 'react';
import { useStore } from 'src/integration/store/store';
import CustomerDataValidationError from 'src/domain/Wizard/model/CustomerDataValidationError';
import useWizardHelper from 'src/toolkit/hooks/useWizardHelper';

const useFetchCustomersValidate = () => {
  const [enabled, setEnabled] = useState(false);
  const { wizardStore, paymentStore } = useStore();
  const { travelDetails, resetValidationErrors, setValidationErrors, setValidationPass } = wizardStore;
  const { cartId } = paymentStore;
  const { mappedTravellerDetails } = useWizardHelper();

  const response = useReactQuery<CustomerData, CustomerDataValidationError, CustomerDataResponse>({
    endpoint: apiConfig.api.endpoints.customersValidate,
    queryKey: useQueryKey('customersValidate', [cartId]),
    body: mappedTravellerDetails(cartId || '', travelDetails),
    method: 'POST',
    opts: {
      retry: 0,
      enabled: enabled,
      onSuccess: () => {
        setEnabled(false);
        setValidationPass(true);
      },
      onError: (error: SimpleObject) => {
        setEnabled(false);
        setValidationPass(false);
        setValidationErrors(error?.violations || []);
      },
    },
  });

  const validateData = () => {
    resetValidationErrors();
    setEnabled(true);
  };

  return {
    ...response,
    validateData,
  };
};

export default useFetchCustomersValidate;
