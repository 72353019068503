import useGenericTransition from 'src/presentation/wizard/transitions/useGenericTransition';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TravelDetailsForm from 'src/domain/Wizard/model/TravelDetailsForm';
import { useStore } from 'src/integration/store/store';
import { useEffect } from 'react';
import useFetchCustomersValidate from 'src/integration/api/useFetchCustomersValidate';
import useConstraints from 'src/toolkit/hooks/useConstraints';
import stepMap from 'src/domain/Wizard/enum/stepMap';
import useQueryParams from 'src/toolkit/hooks/useQueryParams';
import QueryParams from 'src/domain/Settings/model/QueryParams';
import useWizardSessionStorage from 'src/toolkit/hooks/useWizardSessionStorage';
import useWizardHelper from 'src/toolkit/hooks/useWizardHelper';
import useEventLogger from 'src/toolkit/hooks/useEventLogger';

const useTravellerDetailsTransition = () => {
  const { openStep } = useGenericTransition();
  const { wizardSchema } = useConstraints();
  const { isLoading, isFetching, validateData, isError } = useFetchCustomersValidate();
  const { wizardStore } = useStore();
  const { setValuesToStorageData } = useWizardSessionStorage();
  const { getCurrentStepId } = useWizardHelper();
  const {
    prefillWithRandomData,
    travelDetails,
    step,
    updateCheckoutData,
    validationErrors,
    isValidationError,
    isValidationPass,
  } = wizardStore;
  const { removeUrlParam } = useQueryParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isSubmitted },
  } = useForm<TravelDetailsForm>({ resolver: yupResolver(wizardSchema) });
  const { logFrontendFormValidateError } = useEventLogger();

  const handleFormSubmission = handleSubmit((data) => {
    if (!isValid) {
      return openStep(stepMap.TRAVELLER_INFO_STEP);
    }

    updateCheckoutData(data);
    setValuesToStorageData(data);
    validateData();
  });

  const transition = (isValidationEnabled: boolean) => {
    removeUrlParam(QueryParams.payment);

    if (!isValidationEnabled) {
      return openStep(stepMap.PREVIEW_AND_PAYMENT_STEP);
    }

    return handleFormSubmission();
  };

  useEffect(() => {
    reset(travelDetails);
  }, [travelDetails, step, reset]);

  useEffect(() => {
    if (!isSubmitted) return;
    if (isValid) return;

    logFrontendFormValidateError();
  }, [errors, isSubmitted]);

  useEffect(() => {
    const currentStep = getCurrentStepId();

    if (currentStep?.id !== stepMap.TRAVELLER_INFO_STEP) {
      return;
    }

    if (isLoading) {
      return;
    }

    if (isValidationPass) {
      return openStep(stepMap.PREVIEW_AND_PAYMENT_STEP);
    }

    openStep(stepMap.TRAVELLER_INFO_STEP);
  }, [isLoading, isFetching, isValidationError]);

  return {
    prefillWithRandomData,
    transition,
    register,
    errors,
    isError,
    isValidationError,
    isLoading,
    isFetching,
    validationErrors,
  };
};

export default useTravellerDetailsTransition;
