import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/de';
import Languages from 'src/domain/Settings/enum/Languages';
import SelectFieldType from 'src/domain/Styles/model/SelectFieldType';

const useDateTimeFormatter = () => {
  const DEFAULT_LANGUAGE = Languages.de;
  const DEFAULT_ISO_FORMAT = 'YYYY-MM-DD';
  const DEFAULT_DE_FORMAT = 'DD.MM.YYYY';
  const DEFAULT_MONTH_LIST_LENGTH = 12;

  const formatDate = (date?: string) => {
    return dayjs(date).locale(DEFAULT_LANGUAGE).format('D. MMMM YYYY');
  };

  const formatDateCompact = (date?: string) => {
    return dayjs(date).locale(DEFAULT_LANGUAGE).format(DEFAULT_DE_FORMAT);
  };

  const formatDateISO = (date?: string) => {
    return dayjs(date).locale(DEFAULT_LANGUAGE).format(DEFAULT_ISO_FORMAT);
  };

  const formatDateISOFromDefault = (date?: string) => {
    dayjs.extend(customParseFormat);
    return dayjs(date, DEFAULT_DE_FORMAT).locale(DEFAULT_LANGUAGE).format(DEFAULT_ISO_FORMAT);
  };

  const getWeekday = (date?: string) => {
    return dayjs(date).locale(DEFAULT_LANGUAGE).format('dddd');
  };

  const getMonthName = (date?: string) => {
    return dayjs(date).locale(DEFAULT_LANGUAGE).format('MMMM YYYY');
  };

  const getToday = () => {
    return dayjs().locale(DEFAULT_LANGUAGE).format(DEFAULT_ISO_FORMAT);
  };

  const getFirstDayOfMonth = (date?: string) => {
    return dayjs(date).startOf('month').locale(DEFAULT_LANGUAGE).format(DEFAULT_ISO_FORMAT);
  };

  const getLastDayOfMonth = (date?: string) => {
    return dayjs(date).endOf('month').locale(DEFAULT_LANGUAGE).format(DEFAULT_ISO_FORMAT);
  };

  const generateMonthList = (): SelectFieldType[] => {
    const monthList = [];

    for (let i = 0; i < DEFAULT_MONTH_LIST_LENGTH; i++) {
      const month = dayjs().add(i, 'month');
      const monthId = month.format('YYYY-MM-01');
      const monthName = getMonthName(monthId);

      monthList.push({ id: monthId, name: monthName });
    }
    return monthList;
  };

  const getMonthInAdvance = (monthsInAdvance: number) => {
    return dayjs().add(monthsInAdvance, 'month').format('YYYY-MM-01');
  };

  const getDefaultDateInAdvanceFrom = (monthsInAdvance: number) => {
    return new Date(getFirstDayOfMonth(getMonthInAdvance(monthsInAdvance)));
  };

  const getDefaultDateInAdvanceTo = (monthsInAdvance: number) => {
    return new Date(getLastDayOfMonth(getMonthInAdvance(monthsInAdvance)));
  };

  const convertDateToSelectField = (date: Date | null): SelectFieldType => {
    if (!date) return { id: '', name: '' };

    const monthId = formatDateISO(date.toDateString());
    const monthName = getMonthName(monthId);

    return { id: monthId, name: monthName };
  };

  return {
    formatDate,
    formatDateCompact,
    getWeekday,
    formatDateISO,
    formatDateISOFromDefault,
    getToday,
    getFirstDayOfMonth,
    getLastDayOfMonth,
    getMonthName,
    generateMonthList,
    convertDateToSelectField,
    getMonthInAdvance,
    getDefaultDateInAdvanceFrom,
    getDefaultDateInAdvanceTo,
  };
};

export default useDateTimeFormatter;
