import styled, { css } from 'styled-components';
import React, { ReactNode } from 'react';
import colors from 'src/integration/styles/colors';
import WhiteBox from 'src/presentation/components/ui/WhiteBox/WhiteBox';

interface ComponentProps extends Component {
  children: ReactNode;
  vPadding?: number;
  vPaddingDesktop?: number;
  hPadding?: number;
  hPaddingDesktop?: number;
  showBorder?: boolean;
}

const StyledWhiteBox = styled(WhiteBox)<{ $showBorder?: boolean }>`
  ${({ $showBorder }) => css`
    ${$showBorder &&
    css`
      border: 1px solid ${colors.neutral90};
    `}
  `}
`;

const SkeletonWhiteBox = ({
  children,
  vPadding,
  vPaddingDesktop,
  hPadding,
  hPaddingDesktop,
  showBorder,
  className,
}: ComponentProps) => {
  return (
    <StyledWhiteBox
      className={className}
      vPadding={vPadding}
      vPaddingDesktop={vPaddingDesktop}
      hPadding={hPadding}
      hPaddingDesktop={hPaddingDesktop}
      $showBorder={showBorder}
    >
      {children}
    </StyledWhiteBox>
  );
};

export default SkeletonWhiteBox;
