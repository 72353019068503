import ThemeNames from 'src/domain/Settings/enum/ThemeNames';
import ThemeVariables from 'src/domain/Settings/model/ThemeVariables';

const defaultThemeVariables: ThemeVariables = {
  apiKey: process.env.REACT_APP_API_KEY_EXPLORER || '',
};

const staTravelThemeVariables: ThemeVariables = {
  apiKey: process.env.REACT_APP_API_KEY_STATRAVEL || '',
};

const explorerThemeVariables: ThemeVariables = {
  apiKey: process.env.REACT_APP_API_KEY_EXPLORER || '',
};

export const themeVariablesMapping: Record<ThemeNames, ThemeVariables> = {
  [ThemeNames.themeExplorer]: explorerThemeVariables,
  [ThemeNames.themeStaTravel]: staTravelThemeVariables,
  [ThemeNames.defaultTheme]: defaultThemeVariables,
};
