import { useState } from 'react';
import ElementId from 'src/integration/config/ElementId';
import useScrollToElement from 'src/toolkit/utils/useScrollToElement';

const useNavigationTabs = () => {
  const [activeTab, setActiveTab] = useState<ElementId>(ElementId.overview);
  const { scrollToElement } = useScrollToElement();

  const handleTabClick = (id: ElementId) => {
    scrollToElement(id);
    setActiveTab(id);
  };

  return { activeTab, handleTabClick };
};

export default useNavigationTabs;
