const useEnvData = () => {
  return {
    isDevelopmentEnv: process.env.REACT_APP_ENV === 'development',
    isStagingEnv: process.env.REACT_APP_ENV === 'staging',
    isTestingEnv: process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging',
    isProductionEnv: process.env.REACT_APP_ENV === 'production',
  };
};

export default useEnvData;
