import useReactQuery from 'src/integration/api/useReactQuery';
import apiConfig from 'src/integration/config/apiConfig';
import useFetchCheckoutInit from 'src/integration/api/useFetchCheckoutInit';
import { CheckoutDetails } from 'src/domain/Schema/model/apiTypes';
import useQueryKey from 'src/toolkit/utils/useQueryKey';
import { useStore } from 'src/integration/store/store';
import ErrorType from 'src/domain/Schema/model/ErrorType';

const useFetchCheckoutDetails = () => {
  const { paymentStore } = useStore();
  const { setDefaultPaymentOptions } = paymentStore;
  const { data } = useFetchCheckoutInit();
  const cartId = data?.cartId;

  const response = useReactQuery<null, ErrorType, CheckoutDetails>({
    endpoint: apiConfig.api.endpoints.checkoutDetails,
    queryKey: useQueryKey('checkoutDetails', [cartId]),
    method: 'GET',
    queryPathId: {
      cartId: cartId,
    },
    opts: {
      enabled: !!cartId,
      refetchOnWindowFocus: false,
      onSuccess: (data: CheckoutDetails) => {
        setDefaultPaymentOptions(data.paymentOptions);
      },
    },
  });

  return {
    ...response,
  };
};

export default useFetchCheckoutDetails;
