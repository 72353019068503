import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';

const useInitializeEventLogger = (tagId: string) => {
  const { t } = useTranslation();
  const isEventLoggerInitialized = useRef(false);

  useEffect(() => {
    initializeEventLogger(tagId);
  }, []);

  const initializeEventLogger = (id: string) => {
    if (!tagId) return;
    if (isEventLoggerInitialized.current) return;

    const tagManagerArgs: TagManagerArgs = {
      gtmId: t(id),
    };

    TagManager.initialize(tagManagerArgs);
    isEventLoggerInitialized.current = true;
  };
};

export default useInitializeEventLogger;
