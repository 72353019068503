import useReactQuery from 'src/integration/api/useReactQuery';
import { PackageDetailsResponseType } from 'src/domain/Schema/model/apiTypes';
import apiConfig from 'src/integration/config/apiConfig';
import useQueryKey from 'src/toolkit/utils/useQueryKey';
import ErrorType from 'src/domain/Schema/model/ErrorType';
import useAppVariablesProvider from 'src/toolkit/hooks/useAppVariablesProvider';

const useFetchPackageDetails = () => {
  const { packageId } = useAppVariablesProvider();

  const response = useReactQuery<null, ErrorType, PackageDetailsResponseType>({
    endpoint: apiConfig.api.endpoints.packages,
    queryKey: useQueryKey('pdp', [packageId]),
    queryPathId: { id: packageId },
    opts: {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  });

  return {
    ...response,
  };
};

export default useFetchPackageDetails;
