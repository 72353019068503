import { useTranslation } from 'react-i18next';

const useTextBuilders = () => {
  const { t } = useTranslation();

  const buildDirectionText = (start?: Nullable<string>, stop?: Nullable<string>) => {
    return `${t('summary_form.location.from')} ${start} ${t('summary_form.location.to')} ${stop}`;
  };

  const buildDirectionWithCountryText = (
    start?: Nullable<string>,
    startCountry?: Nullable<string>,
    stop?: Nullable<string>,
    stopCountry?: Nullable<string>,
  ) => {
    return `${t('summary_form.location.from')} ${start} (${startCountry}) ${t(
      'summary_form.location.to',
    )} ${stop} (${stopCountry})`;
  };

  return { buildDirectionText, buildDirectionWithCountryText };
};

export default useTextBuilders;
