import React from 'react';
import styled, { css } from 'styled-components';
import Header from 'src/presentation/components/ui/Header/Header';
import Layout from 'src/presentation/layouts/Layout';
import Overview from 'src/presentation/components/Overview/Overview';
import Gallery from 'src/presentation/components/Gallery/Gallery';
import Itinerary from 'src/presentation/components/Itinerary/Itinerary';
import WhiteBox from 'src/presentation/components/ui/WhiteBox/WhiteBox';
import Map from 'src/presentation/components/Map/Map';
import Basket from 'src/presentation/components/Basket/Basket';
import AvailabilityComponent from 'src/presentation/components/AvailabilityComponent/AvailabilityComponent';
import useFetchPackageDetails from 'src/integration/api/useFetchPackageDetails';
import CustomIncludes from 'src/presentation/components/Includes/CustomIncludes';
import NavigationTabs from 'src/presentation/components/NavigationTabs/NavigationTabs';
import ErrorPage from 'src/presentation/pages/ErrorPage';
import ElementId from 'src/integration/config/ElementId';
import RequirementsAndTips from 'src/presentation/components/RequirementsAndTips/RequirementsAndTips';
import breakpoints from 'src/integration/styles/breakpoints';
import useViewport from 'src/toolkit/utils/useViewport';
import { useTranslation } from 'react-i18next';
import BasketPdpFooter from 'src/presentation/components/Basket/BasketPdpFooter';
import LoadingSkeletonPDP from 'src/presentation/components/LoadingSkeletons/LoadingSkeletonPDP';

const Root = styled.div`
  ${() => css``}
`;

const CoverImage = styled.div<{ $imageMobile?: Nullable<string>; $imageDesktop?: Nullable<string> }>`
  ${({ $imageMobile, $imageDesktop }) => css`
    height: 165px;
    width: 100%;
    background-image: url(${$imageMobile});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    ${breakpoints.desktop`
      height: 423px;
      background-image: url(${$imageDesktop});
    `}
  `}
`;

const StyledHeader = styled(Header)`
  ${() => css`
    margin-bottom: 24px;
  `}
`;

const StyledOverviewHeader = styled(Header)`
  ${() => css`
    margin-bottom: 16px;
  `}
`;

const StyledOverviewLayout = styled(Layout)`
  ${() => css`
    ${breakpoints.desktop`
      padding: 42px 0 0;        
    `}
  `}
`;

const StyledGalleryLayout = styled(Layout)`
  ${() => css`
    padding: 24px 0 16px;
  `}
`;

const StyledItineraryLayout = styled(Layout)`
  ${() => css`
    padding: 24px 0;

    ${breakpoints.desktop`
      padding: 24px 0 42px;        
    `}
  `}
`;

const Column = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  `}
`;

const StyledNavigationTabs = styled(NavigationTabs)`
  ${() => css`
    bottom: 16px;
  `}
`;

const StyledOverview = styled(Overview)`
  ${() => css`
    margin-top: 16px;
    margin-bottom: 16px;
  `}
`;

const OverviewColumn = styled.div`
  ${() => css`
    ${breakpoints.mobile`
      padding: 0 16px;
    `}
  `}
`;

const ScrollWrapper = styled.div`
  ${({ theme }) => css`
    scroll-margin-top: ${theme.header?.height}px;
  `}
`;

const PackageDetailsPage = () => {
  const { data, isLoading, isError } = useFetchPackageDetails();
  const { isMobile, isDesktop } = useViewport();
  const { t } = useTranslation();
  const isGalleryVisible = data?.media?.length && data.media.length > 1;

  if (isLoading) {
    return <LoadingSkeletonPDP />;
  }

  if (isError) return <ErrorPage />;

  return (
    <Root>
      <CoverImage $imageDesktop={data?.bannerDesktop} $imageMobile={data?.bannerMobile} />

      <StyledOverviewLayout
        type={'column'}
        sidebar={
          <Basket
            details={data}
            discountPercentage={data?.pricing.discountPercentage}
            footer={<BasketPdpFooter pricing={data?.pricing} />}
          />
        }
      >
        <OverviewColumn>
          {isDesktop && <StyledOverviewHeader level={'h1'}>{data?.title}</StyledOverviewHeader>}
          {isDesktop && <StyledNavigationTabs />}
          <ScrollWrapper id={ElementId.overview}>
            <StyledOverview description={data?.description} tripIncludes={data?.tripIncludes} />
          </ScrollWrapper>
        </OverviewColumn>
      </StyledOverviewLayout>

      {isDesktop && isGalleryVisible && (
        <StyledGalleryLayout type={'row'}>
          <StyledHeader level={'h3'}>{t('gallery_carousel.title')}</StyledHeader>
          <Gallery images={data.media} />
        </StyledGalleryLayout>
      )}

      <StyledItineraryLayout
        type={'column'}
        sidebar={isDesktop && <Map imageUrl={data?.mapImageUrl} enableBoxShadow />}
      >
        <Column>
          <ScrollWrapper id={ElementId.itinerary}>
            <Itinerary itineraryDays={data?.itinerary?.days} mapMobileUrl={data?.mapImageUrl} />
          </ScrollWrapper>

          <ScrollWrapper id={ElementId.includes}>
            <WhiteBox title={t('included_and_excluded.title')} headerLevel={isMobile ? 'h6' : 'h3'}>
              <CustomIncludes tripIncludes={data?.tripIncludes} />
            </WhiteBox>
          </ScrollWrapper>

          <RequirementsAndTips pdfLink={data.pdfDocumentUrl} />

          <ScrollWrapper id={ElementId.availabilityList}>
            <AvailabilityComponent />
          </ScrollWrapper>
        </Column>
      </StyledItineraryLayout>
    </Root>
  );
};

export default PackageDetailsPage;
