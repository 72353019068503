import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Button, { StyleType } from 'src/presentation/components/ui/Button/Button';

interface ComponentProps extends Component {
  href: string;
  target?: string;
  children: ReactNode;
  large?: boolean;
  small?: boolean;
  styleType?: StyleType;
}

const Root = styled.a`
  display: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const Content = styled(Button)`
  as: 'span';
  display: inherit;
  text-decoration: none;
`;

const ButtonLink = ({
  href,
  children,
  target = '_blank',
  large,
  styleType = 'link',
  small,
  className,
}: ComponentProps) => {
  return (
    <Root href={href} target={target} className={className}>
      <Content styleType={styleType} large={large} small={small}>
        {children}
      </Content>
    </Root>
  );
};

export default ButtonLink;
