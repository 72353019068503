import Note from 'src/presentation/components/ui/Note/Note';
import ErrorListNote from 'src/presentation/components/CustomNotes/ErrorListNote';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useGeneralWizardErrorHook from 'src/toolkit/hooks/useGeneralWizardErrorHook';
import styled, { css } from 'styled-components';

const ScrollWrapper = styled.div`
  ${({theme}) => css`
      scroll-margin-top: ${theme.header?.height}px;
  `}
`;

const GeneralWizardErrorsSection = () => {
  const { t } = useTranslation();
  const { isGeneralError, isValidationError, isPaymentInitError, isPaymentFailedError, errorId, validationErrorList } =
    useGeneralWizardErrorHook();

  if (isPaymentInitError) {
    return (
      <ScrollWrapper id={errorId}>
        <Note title={t('error.message.payment_error')} type={'errorDark'} isIconVisible>
          {t('error.message.unexpected_payment_error')}
        </Note>
      </ScrollWrapper>
    );
  }

  if (isPaymentFailedError) {
    return (
      <ScrollWrapper id={errorId}>
        <Note  title={t('error.message.payment_error')} type={'errorDark'} isIconVisible>
          {t('error.message.payment_processing_error')}
        </Note>
      </ScrollWrapper>
    );
  }

  if (isGeneralError)
    return (
      <ScrollWrapper id={errorId}>
        <Note title={t('error.message.unexpected_error')} type={'errorDark'} isIconVisible>
          {t('error.message.something_went_wrong')}
        </Note>
      </ScrollWrapper>
    );

  if (isValidationError) {
    return (
      <ScrollWrapper id={errorId}>
        <ErrorListNote id={errorId} title={t('error.message.validation_error')} errorList={validationErrorList} />
      </ScrollWrapper>
    );
  }

  return <></>;
};

export default GeneralWizardErrorsSection;
