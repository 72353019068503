import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';

interface ComponentProps extends Component {
  width?: number;
  height?: number;
  marginBottom?: number;
  alignRight?: boolean;
}

const Root = styled.div<{
  $width?: number;
  $height?: number;
  $marginBottom?: number;
  $alignRight?: boolean;
}>`
  ${({ $width, $height, $marginBottom, $alignRight }) => css`
    width: 100%;
    max-width: ${$width ? `${$width}px` : '100%'};
    height: ${$height}px;
    background: ${colors.neutral90};
    margin-bottom: ${$marginBottom || 0}px;

    ${$alignRight &&
    css`
      margin-left: auto;
    `}
  `}
`;

const Skeleton = ({ width, height = 20, marginBottom, alignRight, className }: ComponentProps) => {
  return (
    <Root className={className} $width={width} $height={height} $marginBottom={marginBottom} $alignRight={alignRight} />
  );
};

export default Skeleton;
