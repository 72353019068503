import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import NumberCircle from 'src/presentation/components/WizzardNavigator/elements/NumberCircle';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';

interface ComponentProps {
  number: number;
  text: string;
  disabled: boolean;
  approved: boolean;
  onClick: () => void;
}

const Root = styled.div<{ disabled: boolean }>`
  ${({ disabled }) => css`
    display: flex;
    align-items: center;
    grid-gap: 8px;

    ${!disabled &&
    css`
      cursor: pointer;
    `}
  `}
`;

const StyledParagraph = styled(Paragraph)<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    ${disabled &&
    css`
      color: ${colors.neutral50};
    `}
  `}
`;

const NavigatorItem = ({ number, text, disabled, approved, onClick }: ComponentProps) => {
  return (
    <Root onClick={!disabled ? onClick : () => null} disabled={disabled}>
      <NumberCircle number={number} disabled={disabled} approved={approved} />
      <StyledParagraph disabled={disabled}>{text}</StyledParagraph>
    </Root>
  );
};

export default NavigatorItem;
