const colors = {
  error: 'rgba(186, 26, 26, 1)',
  warning: 'rgba(254, 244, 226, 1)',
  white: 'rgba(255, 255, 255, 1)',
  success: 'rgba(19, 157, 7, 1)',
  background: 'rgba(247, 242, 247, 1)',
  neutral10: 'rgba(28, 27, 30, 1)',
  neutral30: 'rgba(73, 69, 78, 1)',
  neutral50: 'rgba(118, 114, 121, 1)',
  neutral80: 'rgba(202, 196, 207, 1)',
  neutral90: 'rgba(230, 225, 230, 1)',
  neutral96: 'rgba(247, 242, 247, 1)',
  neutral100: 'rgba(255, 255, 255, 1)',
  infoContainer: 'rgba(235, 246, 254, 1)',
  successContainer: 'rgba(237, 254, 235, 1)',
  errorContainer: 'rgba(255, 242, 240, 1)',
  infoContainerNeg: 'rgba(3, 59, 99, 1)',
  infoBase: 'rgba(5, 126, 215, 1)',
};

export default colors;
