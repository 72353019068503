import { createGlobalStyle, css } from 'styled-components';
import resetCSS from 'src/integration/styles/reset';
import colors from 'src/integration/styles/colors';

const GlobalStyle = createGlobalStyle`
  ${resetCSS}
  
  ${({ theme }) => css`
    html {
      background: ${colors.background};
      scroll-behavior: smooth;

      @-moz-document url-prefix() {
        scroll-behavior: auto;
      }
    }

    body {
      font-family: ${theme.fonts.fontFamily};
      background: ${colors.background};
    }

    all: initial;
    height: 100%;
    width: 100%;
    color: ${colors.neutral10};
    font-weight: normal;
    font-family: ${theme.fonts.fontFamily};
  `}
`;

export default GlobalStyle;
