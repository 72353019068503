import React from 'react';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import { iconSuccess } from 'src/integration/assets/AssetsProvider';
import Header from 'src/presentation/components/ui/Header/Header';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import Note from 'src/presentation/components/ui/Note/Note';
import useViewport from 'src/toolkit/utils/useViewport';
import styled, { css } from 'styled-components';
import breakpoints from 'src/integration/styles/breakpoints';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  bookingId: string;
  title: string;
  startDate: string;
  endDate: string;
}

const Root = styled.div`
  ${() => css`
    width: 100%;
  `}
`;

const TextWrapper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 6px;

    ${breakpoints.desktop`
      flex-direction: row;
      align-items: flex-end;
    `}
  `}
`;

const StyledIcon = styled(Icon)`
  ${() => css`
    margin-bottom: 16px;
  `}
`;

const StyledHeader = styled(Header)`
  ${() => css`
    margin-bottom: 12px;

    ${breakpoints.desktop`
      margin-bottom: 16px;
    `}
  `}
`;

const StyledParagraph = styled(Paragraph)`
  ${() => css`
    line-height: 18px;
  `}
`;

const TripTitle = styled(Header)`
  ${() => css`
    margin-bottom: 8px;

    ${breakpoints.desktop`
      margin-bottom: 24px;
    `}
  `}
`;

const DetailsBlock = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;

    ${breakpoints.desktop`
      margin-bottom: 24px;
    `}
  `}
`;

const DateBlock = styled.div`
  ${() => css`
    display: flex;
    gap: 24px;
    width: 100%;
    margin-bottom: 16px;

    ${breakpoints.desktop`
      margin-bottom: 24px;   
    `}
  `}
`;

const Date = styled.div``;

const ConfirmationDetails = ({ bookingId, title, startDate, endDate }: ComponentProps) => {
  const { isMobile, isDesktop } = useViewport();
  const { t } = useTranslation();

  return (
    <Root>
      <DetailsBlock>
        <StyledIcon icon={iconSuccess} size={isMobile ? 36 : 40} />
        <StyledHeader level={isMobile ? 'h5' : 'h2'}>{t('confirmation.title')}</StyledHeader>
        {isMobile && (
          <TextWrapper>
            <Paragraph large>{t('confirmation.number')}</Paragraph>
            <Paragraph semiBold>{bookingId}</Paragraph>
          </TextWrapper>
        )}
        {isDesktop && (
          <TextWrapper>
            <Header level={'h6'}>{t('confirmation.number')}</Header>
            <Header level={'h5'}>{bookingId}</Header>
          </TextWrapper>
        )}
      </DetailsBlock>
      <TripTitle level={isMobile ? 'h5' : 'h3'}>{title}</TripTitle>
      <DateBlock>
        <Date>
          <Paragraph>{t('summary_form.start_date')}</Paragraph>
          <StyledParagraph large semiBold>
            {startDate}
          </StyledParagraph>
        </Date>
        <Date>
          <Paragraph>{t('summary_form.end_date')}</Paragraph>
          <StyledParagraph large semiBold>
            {endDate}
          </StyledParagraph>
        </Date>
      </DateBlock>
      <Note title={t('note.info.title')} isIconVisible>
        <Paragraph large>{t('confirmation.note.details_are_sent')}</Paragraph>
      </Note>
    </Root>
  );
};

export default ConfirmationDetails;
