import { useState } from 'react';

const useCounter = (onCountChange: (value: number) => void, min: number, max: number, defaultValue: number) => {
  const STEP = 1;
  const [count, setCount] = useState(defaultValue);

  const handleChange = (value: number) => {
    setCount(count + value);
    onCountChange(count + value);
  };

  const increaseCount = () => {
    if (count >= max) return;
    handleChange(STEP);
  };

  const decreaseCount = () => {
    if (count <= min) return;
    handleChange(STEP * -1);
  };

  return { count, increaseCount, decreaseCount };
};
export default useCounter;
