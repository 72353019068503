import WhiteBox from 'src/presentation/components/ui/WhiteBox/WhiteBox';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import React from 'react';
import styled, { css } from 'styled-components';
import Button from 'src/presentation/components/ui/Button/Button';
import useViewport from 'src/toolkit/utils/useViewport';
import breakpoints from 'src/integration/styles/breakpoints';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  pdfLink?: string;
}

const StyledButton = styled(Button)`
  ${() => css`
    margin-top: 16px;

    ${breakpoints.mobile`
      width: 100%;        
    `}
  `}
`;

const RequirementsAndTips = ({ pdfLink }: ComponentProps) => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();

  const onClick = () => {
    window.open(pdfLink, '_blank');
  };

  return (
    <WhiteBox title={t('requirements_tips.title')} headerLevel={isMobile ? 'h6' : 'h3'}>
      <Paragraph large>{t('requirements_tips.paragraph.text')}</Paragraph>

      <StyledButton small={isMobile} styleType={'secondary'} onClick={onClick}>
        {t('requirements_tips.button.text')}
      </StyledButton>
    </WhiteBox>
  );
};

export default RequirementsAndTips;
